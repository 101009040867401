import { useEffect, useState, useCallback } from 'react';

import { useNavigate, useParams, Link } from 'react-router-dom';

import { useForm, Controller } from 'react-hook-form';

import { useDispatch, useSelector } from 'react-redux';

import { DatePicker } from 'root/components/DatePicker';
import moment from 'moment';
import ReactMoment from 'react-moment';
import { setListIndex } from 'utils';

import { API_URL } from 'shared/constants/constants';

import { getPlaceDetails } from 'redux/root/slices/place';
import { getDefectList } from 'redux/root/slices/defect';
import { deleteDefect } from 'api/defect';

import { SortNumOptions, SortOrderOptions } from 'shared/root/optionData';

import ConfirmModal from 'root/components/Modals/ConfirmModal';
import ToastMessage from 'root/components/Modals/ToastMessage';
import { openModal } from 'redux/common/slices/modals';
import DefectImageModal from 'root/components/Modals/DefectImageModal';

import PageLayout from 'root/components/PageLayout';
import TitleContainer from 'root/components/TitleContainer';
import ContentsContainer from 'root/components/ContentsContainer';
import TextInput from 'root/components/Form/TextInput';

import NoResult from 'root/components/NoResult';
import BasicPagination from 'root/components/Pagination';

import styled from '@emotion/styled';

// icon image
import IconExcel from 'root/assets/imgs/icon-excel.svg';
import IconDownload from 'root/assets/imgs/icon-download.svg';

import {
  CustomSelect,
  styleDefault,
  styleBox,
} from 'shared/root/styles/common/Select';
import { WorkStatusData, expandDefectTypeData } from 'shared/root/defectData';

import CommonStyledComponents from 'shared/root/styles/CommonStyledComponents';
import { isDisabled } from '@testing-library/user-event/dist/utils';
import { WriteForm } from 'shared/root/styles/common/WriteForm';
const { Card, Table, Input, SearchCard, Button } = CommonStyledComponents;

export default function DefectsList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const currentIdx = params.placeId;
  const newDate = new Date();

  const [shootingDayMte, setShootingDayMte] = useState(); //촬영일_시작
  const [shootingDayLte, setShootingDayLte] = useState(); //촬영일_종료
  const [shootingDay, setShootingDay] = useState(false); //촬영일_선택여부
  const [shootingDayRange, setSelectDateRange] = useState(false); //촬영일_날짜범위

  const placeDetails = useSelector(state => state.place.placeDetails);
  const tableLists = useSelector(state => state.rootDefect.defectList);
  const dfpList = placeDetails.dfp;

  const [sort1, setSort1] = useState('');
  const [sort2, setSort2] = useState('');
  const [sort3, setSort3] = useState('');
  const [searchData, setSearchData] = useState('');

  // 페이지네이션
  const takeNum = 10; // 한 페이지 게시물수
  const [totalNum, setTotalNum] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [sortingNum, setSortingNum] = useState(takeNum);

  //옵션
  const [sortNumValue, setSortNumValue] = useState(SortNumOptions[0]); //개수정렬
  const [sortOrderValue, setSortOrderValue] = useState(SortOrderOptions[0]); //순서정렬

  const initialValues = {
    id: '',
    sort1: '',
    sort2: '',
    sort3: '',
    status: [],
    type: '',
    checkedLists: [],
  };

  const {
    register,
    setValue,
    watch,
    reset,
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initialValues,
  });

  const sort1Arr =
    dfpList &&
    Object.keys(dfpList).map((val, idx) => {
      return (val = `${val}`);
    });

  const dfpArr_sort1 =
    dfpList &&
    sort1 &&
    watch('sort1') &&
    Object.values(dfpList[watch('sort1')]);

  const sort2Arr =
    dfpList &&
    watch('sort1') &&
    dfpArr_sort1.map((val, idx) => {
      return val.sort2;
    });

  const sort3Arr =
    dfpList &&
    watch('sort1') &&
    sort1 &&
    sort2 &&
    watch('sort2') &&
    dfpArr_sort1[
      dfpArr_sort1.findIndex(elem => {
        return elem.sort2 === sort2.value;
      })
    ].sort3;

  ////////////////////////////////////////////////////////
  const setTableLists = useCallback(
    (take, page, sort, search) => {
      dispatch(
        getDefectList({
          placeIdx: currentIdx,
          take: take,
          page: page,
          order: sort.order,
          sort: sort.sort,
          search: search ? search : '',
        }),
      )
        // .then(res => {
        // })
        .catch(error => {
          navigate('/root/notfound', { replace: true });
        });
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(getPlaceDetails(currentIdx));
    setTableLists(sortingNum, activePage, sortOrderValue, searchData);
  }, [
    dispatch,
    setTableLists,
    activePage,
    sortOrderValue,
    sortingNum,
    currentIdx,
    searchData,
  ]);

  function onSubmitSearch(data) {
    data.type = data.type && data.type.value;
    const { checkedLists, ...restData } = data;
    setSearchData({
      ...restData,
      shooting_day_mte: shootingDayMte
        ? moment(shootingDayMte).format('YYYY-MM-DD')
        : '',
      shooting_day_lte: shootingDayLte
        ? moment(shootingDayLte).format('YYYY-MM-DD')
        : '',
    });
    setActivePage(1);
    setTableLists(sortingNum, activePage, sortOrderValue, searchData);
  }

  function onError() {}

  function handleClickReset() {
    reset(initialValues);
    setShootingDayMte(false);
    setShootingDayLte(false);
    setShootingDay(false);
    setSelectDateRange(false);
    setValue('checkedLists', []);
    setValue('sort1', '');
    setValue('sort2', '');
    setValue('sort3', '');

    setSearchData('');
    setActivePage(1);
    setSort1('');
    setSort2('');
    setSort3('');

    setTableLists(sortingNum, activePage, sortOrderValue, searchData);
  }

  function changeSort1(e) {
    setSort1(e);
    setValue('sort1', e.value);

    setSort2('');
    setValue('sort2', '');
    setSort3('');
    setValue('sort3', '');
  }

  function changeSort2(e) {
    setSort2(e);
    setValue('sort2', e.value);
    setSort3('');
    setValue('sort3', '');
  }

  function changeSort3(e) {
    setSort3(e);
    setValue('sort3', e.value);
  }

  //날짜범위선택
  function dateRangePicker(rangeType, range = 1) {
    const year = newDate.getFullYear(); // 년
    const month = newDate.getMonth(); // 월
    const day = newDate.getDate(); // 일
    let rangeDate;

    if (rangeType === 'week') {
      rangeDate = new Date(year, month, day - range * 7);
      setSelectDateRange(rangeType);
    } else if (rangeType === 'month') {
      rangeDate = new Date(year, month - range, day);
      setSelectDateRange(rangeType);
    } else if (rangeType === 'year') {
      rangeDate = new Date(year - range, month, day);
      setSelectDateRange(rangeType);
    } else {
      rangeDate = newDate; //.toLocaleDateString()
      setSelectDateRange(rangeType);
    }
    setShootingDayMte(rangeDate);
    if (shootingDayLte !== newDate) {
      setShootingDayLte(newDate);
    }
    setSelectDateRange({
      type: rangeType,
      range: range,
      startDate: rangeDate,
      endDate: newDate,
    });
  }

  // 게시글 총 개수 셋팅
  useEffect(() => {
    setTotalNum(tableLists.total);
  }, [tableLists.total]);

  // 개수정렬
  const handleSortingNum = e => {
    setSortingNum(e.value);
    setSortNumValue(e);
    setTableLists(sortingNum, activePage, sortOrderValue, searchData);
  };
  // 순서정렬
  const handleSortingOrder = e => {
    setSortOrderValue(e);
    setTableLists(sortingNum, activePage, sortOrderValue, searchData);
  };

  // 페이지네이션 전환
  const handleClickPage = changePageNum => {
    setActivePage(changePageNum);
    setTableLists(sortingNum, activePage, sortOrderValue, searchData);
  };

  useEffect(() => {
    if (
      Object.keys(shootingDayRange).length &&
      (shootingDayMte !== shootingDayRange.startDate ||
        shootingDayLte !== shootingDayRange.endDate)
    ) {
      //날짜범위선택 리셋
      setSelectDateRange({});
    }
  }, [
    shootingDayRange.endDate,
    shootingDayRange.startDate,
    shootingDayLte,
    shootingDayMte,
  ]);

  useEffect(() => {
    if (
      Object.keys(shootingDayRange).length === 0 &&
      shootingDayMte &&
      shootingDayLte
    ) {
      setShootingDay(true);
    } else {
      setShootingDay(false);
    }
  }, [shootingDayLte, shootingDayMte]);

  // 체크박스 전체 선택
  const handleAllCheckedList = checked => {
    if (checked) {
      const arr = [];
      tableLists.results.forEach(el => arr.push(String(el.idx)));
      setValue('checkedLists', arr);
    } else {
      setValue('checkedLists', []);
    }
  };

  // 삭제
  function handleClickDelete() {
    dispatch(
      openModal({
        Component: ConfirmModal,
        props: {
          message: '선택하신 항목을 삭제하시겠습니까?',
          handleModalSubmit: () => {
            deleteDefect(watch().checkedLists)
              .then(response => {
                setTableLists(
                  sortingNum,
                  activePage,
                  sortOrderValue,
                  searchData,
                );
                setValue('checkedLists', []);
                dispatch(
                  openModal({
                    Component: ToastMessage,
                    props: {
                      iconImg: 'complete',
                      message: '삭제되었습니다',
                    },
                  }),
                );
              })
              .catch(error => {
                console.log(error);
                setValue('checkedLists', []);
                dispatch(
                  openModal({
                    Component: ToastMessage,
                    props: {
                      iconImg: 'fail',
                      message: '실패했습니다',
                    },
                  }),
                );
              });
          },
        },
      }),
    );
  }

  // 전체 사진 다운로드 모달표시
  function handleClickDownloadAll() {
    dispatch(
      openModal({
        Component: DefectImageModal,
        props: {
          placeIdx: currentIdx,
        },
      }),
    );
  }

  return (
    <PageLayout>
      <TitleContainer>
        <h2>
          <span>하자관리</span>
          <span>현장별 하자관리</span>
          <b>{placeDetails.place && placeDetails.place.name}</b>
        </h2>
      </TitleContainer>

      <ContentsContainer>
        <Card.Layout>
          <Card.Header>
            <Card.HeaderTitle>하자검색</Card.HeaderTitle>
            <Card.HeaderButtons>
              <Button
                type="submit"
                size={'md'}
                theme={'point'}
                status={
                  watch().sort1 !== '' ||
                  watch().status.length > 0 ||
                  watch().id !== '' ||
                  watch().type !== '' ||
                  shootingDay ||
                  shootingDayRange
                    ? ''
                    : 'disabled'
                  // 셀렉트 버그로 watch().sort1 !== "" 추가
                }
                onClick={handleSubmit(onSubmitSearch)}
              >
                검색
              </Button>
              <Button
                type="button"
                size={'md'}
                theme={'point2'}
                status={
                  watch().sort1 !== '' ||
                  watch().status.length > 0 ||
                  watch().id !== '' ||
                  watch().type !== '' ||
                  shootingDayMte ||
                  shootingDayLte ||
                  shootingDayRange ||
                  searchData
                    ? ''
                    : 'disabled'
                }
                onClick={handleClickReset}
              >
                초기화
              </Button>
            </Card.HeaderButtons>
          </Card.Header>
          <Card.Body>
            <SearchCard.Layout
              method="post"
              onSubmit={handleSubmit(onSubmitSearch, onError)}
            >
              <SearchCard.Fieldset>
                <SearchCard.Label>작업장소</SearchCard.Label>

                <SearchCard.FormContent>
                  <Controller
                    control={control}
                    name="sort1"
                    render={({ field }) => (
                      <CustomSelect
                        {...field}
                        inputId="sort1"
                        width={'200px'}
                        styles={styleBox}
                        isSearchable={false}
                        placeholder="동"
                        options={
                          sort1Arr &&
                          sort1Arr.map((item, idx) => {
                            return { value: item, label: item };
                          })
                        }
                        onChange={changeSort1}
                        value={sort1}
                      />
                    )}
                  />
                  {watch(sort1) && (
                    <Controller
                      control={control}
                      name="sort2"
                      render={({ field }) => (
                        <CustomSelect
                          {...field}
                          inputId="sort2"
                          width={'200px'}
                          styles={styleBox}
                          isSearchable={false}
                          placeholder="호"
                          options={
                            sort2Arr &&
                            sort2Arr.map((item, idx) => {
                              return { value: item, label: item };
                            })
                          }
                          onChange={changeSort2}
                          value={sort2}
                        />
                      )}
                    />
                  )}

                  {watch(sort2) && (
                    <Controller
                      control={control}
                      name="sort3"
                      render={({ field }) => (
                        <CustomSelect
                          {...field}
                          inputId="sort3"
                          width={'200px'}
                          styles={styleBox}
                          isSearchable={false}
                          placeholder="위치"
                          options={
                            sort3Arr &&
                            sort3Arr.split(',').map((item, idx) => {
                              return { value: item, label: item };
                            })
                          }
                          onChange={changeSort3}
                          value={sort3}
                        />
                      )}
                    />
                  )}
                </SearchCard.FormContent>
              </SearchCard.Fieldset>

              <SearchCard.Fieldset>
                <SearchCard.Label>작업상태</SearchCard.Label>
                <SearchCard.FormContent>
                  {WorkStatusData.map((val, idx) => (
                    <Input.Checkbox key={idx}>
                      <input
                        type="checkbox"
                        value={`${val.value}`}
                        {...register('status')}
                      />
                      {val.label}
                    </Input.Checkbox>
                  ))}
                </SearchCard.FormContent>
              </SearchCard.Fieldset>

              <SearchCard.Fieldset>
                <SearchCard.Label>유형</SearchCard.Label>
                <SearchCard.FormContent>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                      <CustomSelect
                        {...field}
                        width={'350px'}
                        styles={styleBox}
                        isSearchable={false}
                        placeholder="전체"
                        options={expandDefectTypeData}
                        defaultValue={{ value: '', label: '전체' }}
                      />
                    )}
                  />
                </SearchCard.FormContent>
              </SearchCard.Fieldset>

              <SearchCard.Fieldset>
                <SearchCard.Label>촬영일</SearchCard.Label>
                <SearchCard.FormContent>
                  <SearchCard.DataPickerTab>
                    <SearchCard.DataPickerBtn
                      type="button"
                      onClick={() => {
                        dateRangePicker('today');
                      }}
                      active={
                        shootingDayRange.type === 'today' &&
                        shootingDayRange.range === 1
                          ? 'true'
                          : 'false'
                      }
                    >
                      오늘
                    </SearchCard.DataPickerBtn>
                    <SearchCard.DataPickerBtn
                      type="button"
                      onClick={() => {
                        dateRangePicker('week');
                      }}
                      active={
                        shootingDayRange.type === 'week' &&
                        shootingDayRange.range === 1
                          ? 'true'
                          : 'false'
                      }
                    >
                      일주일
                    </SearchCard.DataPickerBtn>
                    <SearchCard.DataPickerBtn
                      type="button"
                      onClick={() => {
                        dateRangePicker('month');
                      }}
                      active={
                        shootingDayRange.type === 'month' &&
                        shootingDayRange.range === 1
                          ? 'true'
                          : 'false'
                      }
                    >
                      1개월
                    </SearchCard.DataPickerBtn>
                    <SearchCard.DataPickerBtn
                      type="button"
                      onClick={() => {
                        dateRangePicker('month', 3);
                      }}
                      active={
                        shootingDayRange.type === 'month' &&
                        shootingDayRange.range === 3
                          ? 'true'
                          : 'false'
                      }
                    >
                      3개월
                    </SearchCard.DataPickerBtn>
                    <SearchCard.DataPickerBtn
                      type="button"
                      onClick={() => {
                        dateRangePicker('month', 6);
                      }}
                      active={
                        shootingDayRange.type === 'month' &&
                        shootingDayRange.range === 6
                          ? 'true'
                          : 'false'
                      }
                    >
                      6개월
                    </SearchCard.DataPickerBtn>
                  </SearchCard.DataPickerTab>
                  <DatePickerWrap>
                    <DatePicker
                      selectsStart
                      selected={shootingDayMte}
                      onChange={date => {
                        setShootingDayMte(date);
                        setShootingDayLte();
                      }}
                      isSelected={shootingDay}
                    />
                    <i>~</i>
                    <DatePicker
                      selectsEnd
                      selected={shootingDayLte}
                      startDate={shootingDayMte}
                      minDate={shootingDayMte}
                      isSelected={shootingDay}
                      onChange={date => setShootingDayLte(date)}
                    />
                  </DatePickerWrap>
                </SearchCard.FormContent>
              </SearchCard.Fieldset>

              <SearchCard.Fieldset>
                <SearchCard.Label>작성자 아이디</SearchCard.Label>
                <SearchCard.FormContent>
                  <TextInput
                    register={register('id')}
                    errors={errors}
                    maxWidth={'350px'}
                  />
                </SearchCard.FormContent>
              </SearchCard.Fieldset>
            </SearchCard.Layout>
          </Card.Body>
        </Card.Layout>

        <Card.Layout>
          <Card.Header>
            <Card.HeaderTitle>하자목록</Card.HeaderTitle>
            <Card.HeaderCount>
              총 <em>{tableLists.total ? tableLists.total : 0}</em>개
            </Card.HeaderCount>
            <Card.HeaderSorting>
              <CustomSelect
                name="sortNum"
                styles={styleDefault}
                options={SortNumOptions}
                value={sortNumValue}
                isSearchable={false}
                onChange={e => {
                  handleSortingNum(e);
                }}
              />
              <CustomSelect
                name="sortOrder"
                styles={styleDefault}
                isSearchable={false}
                options={SortOrderOptions}
                value={sortOrderValue}
                onChange={e => {
                  handleSortingOrder(e);
                }}
              />
            </Card.HeaderSorting>
          </Card.Header>
          <Card.Body>
            <Table.Options>
              <div>
                <Button
                  theme={'outline-default'}
                  size={'md'}
                  onClick={handleClickDownloadAll}
                  status={tableLists.total === 0 ? 'disabled' : ''}
                >
                  <img src={IconDownload} alt="" />
                  전체 사진 다운로드
                </Button>
                <Button
                  href={`${API_URL}/file/downloads/select?file=${watch().checkedLists.join(
                    ',',
                  )}`}
                  theme={'outline-default'}
                  size={'md'}
                  status={watch().checkedLists.length > 0 ? '' : 'disabled'}
                  onClick={() => {
                    console.log(
                      `/file/downloads/select?file=${watch().checkedLists.join(
                        ',',
                      )}`,
                    );
                  }}
                  download
                >
                  <img src={IconDownload} alt="" />
                  선택 사진 다운로드
                </Button>
                <Button
                  href={`${API_URL}/defect/excel/${currentIdx}`}
                  theme={'outline-default'}
                  size={'md'}
                  status={tableLists.total === 0 ? 'disabled' : ''}
                  download
                >
                  <img src={IconExcel} alt="" />
                  전체 엑셀 다운로드
                </Button>
              </div>
              <div>
                <Button
                  type="submit"
                  size={'md'}
                  theme={'point2'}
                  status={watch().checkedLists.length > 0 ? '' : 'disabled'}
                  onClick={handleClickDelete}
                >
                  선택삭제
                </Button>
              </div>
            </Table.Options>
            <Table.Container>
              <Table.Layout layout={'auto'}>
                <colgroup>
                  <col style={{ width: '80px', minWidth: '80px' }} />
                  <col style={{ width: '80px', minWidth: '80px' }} />
                  <col style={{ width: '120px', minWidth: '80px' }} />
                  <col style={{ width: '120px', minWidth: '80px' }} />
                  <col style={{ width: '120px', minWidth: '80px' }} />
                  <col style={{ width: '120px', minWidth: '80px' }} />
                  <col style={{ width: '120px', minWidth: '120px' }} />
                  <col style={{ width: '220px', minWidth: '180px' }} />
                  <col style={{ width: '218px', minWidth: '180px' }} />
                  <col style={{ width: '160px', minWidth: '120px' }} />
                  <col style={{ width: '160px', minWidth: '100px' }} />
                </colgroup>
                <thead>
                  <Table.Tr>
                    <Table.Th type={'checkbox'}>
                      <Input.Checkbox>
                        <input
                          type="checkbox"
                          onChange={e => handleAllCheckedList(e.target.checked)}
                          checked={
                            sortingNum &&
                            tableLists.pageTotal &&
                            (watch().checkedLists.length === sortingNum ||
                              watch().checkedLists.length >=
                                tableLists.pageTotal)
                              ? true
                              : false
                          }
                        />
                      </Input.Checkbox>
                    </Table.Th>
                    <Table.Th>번호</Table.Th>
                    <Table.Th>동</Table.Th>
                    <Table.Th>호수</Table.Th>
                    <Table.Th>위치</Table.Th>
                    <Table.Th>작업상태</Table.Th>
                    <Table.Th>유형</Table.Th>
                    <Table.Th>촬영일</Table.Th>
                    <Table.Th>등록일</Table.Th>
                    <Table.Th>작성자 아이디</Table.Th>
                    <Table.Th type={'more'}>관리</Table.Th>
                  </Table.Tr>
                </thead>
                <tbody>
                  {tableLists.length === 0 || tableLists.total === 0 ? (
                    <Table.Tr noHover>
                      <Table.Td colSpan="11">
                        <NoResult />
                      </Table.Td>
                    </Table.Tr>
                  ) : (
                    tableLists.results.map((item, itemIdx) => (
                      <Table.Tr key={item.idx}>
                        <Table.Td type={'checkbox'}>
                          <Input.Checkbox>
                            <input
                              type="checkbox"
                              value={item.idx}
                              {...register('checkedLists')}
                            />
                          </Input.Checkbox>
                        </Table.Td>
                        <Table.Td>
                          {setListIndex(
                            itemIdx,
                            activePage,
                            sortingNum,
                            totalNum,
                          )}
                        </Table.Td>
                        <Table.Td>{item.sort1}</Table.Td>
                        <Table.Td>{item.sort2}</Table.Td>
                        <Table.Td>{item.sort3}</Table.Td>
                        <Table.Td>
                          {
                            WorkStatusData.find(
                              val => val.value === item.status,
                            ).label
                          }
                        </Table.Td>
                        <Table.Td>
                          {
                            expandDefectTypeData.find(
                              val => val.value === item.type,
                            ).label
                          }
                        </Table.Td>
                        <Table.Td>
                          <ReactMoment
                            date={item.shooting_day}
                            format="YYYY-MM-DD&nbsp;&nbsp;HH:mm:ss"
                          />
                        </Table.Td>
                        <Table.Td>
                          <ReactMoment
                            date={item.createdAt}
                            format="YYYY-MM-DD&nbsp;&nbsp;HH:mm:ss"
                          />
                        </Table.Td>
                        <Table.Td>
                          {item.user.id} ({item.user.name})
                        </Table.Td>
                        <Table.Td type={'more'}>
                          <Button
                            theme={'underline'}
                            size={'sm'}
                            onClick={() => {
                              navigate(
                                `/root/defectsbyplace/${currentIdx}/details/${item.idx}`,
                              );
                            }}
                          >
                            상세보기
                          </Button>
                        </Table.Td>
                      </Table.Tr>
                    ))
                  )}
                </tbody>
              </Table.Layout>
            </Table.Container>
            <Table.Options>
              <div>
                <Button
                  href={`${API_URL}/file/downloads/all/${currentIdx} `}
                  theme={'outline-default'}
                  size={'md'}
                  status={tableLists.total === 0 ? 'disabled' : ''}
                >
                  <img src={IconDownload} alt="" />
                  전체 사진 다운로드
                </Button>
                <Button
                  href={`${API_URL}/file/downloads/select?file=${watch().checkedLists.join(
                    ',',
                  )}`}
                  theme={'outline-default'}
                  size={'md'}
                  status={watch().checkedLists.length > 0 ? '' : 'disabled'}
                  download
                >
                  <img src={IconDownload} alt="" />
                  선택 사진 다운로드
                </Button>
                <Button
                  href={`${API_URL}/defect/excel/${currentIdx}`}
                  theme={'outline-default'}
                  size={'md'}
                  status={tableLists.total === 0 ? 'disabled' : ''}
                >
                  <img src={IconExcel} alt="" />
                  전체 엑셀 다운로드
                </Button>
              </div>
              <div>
                <Button
                  type="submit"
                  size={'md'}
                  theme={'point2'}
                  status={watch().checkedLists.length > 0 ? '' : 'disabled'}
                  onClick={handleClickDelete}
                >
                  선택삭제
                </Button>
              </div>
            </Table.Options>
            {totalNum ? (
              <Table.Pagination>
                <BasicPagination
                  total={totalNum}
                  limit={sortingNum}
                  activePage={activePage}
                  handleClick={handleClickPage}
                />
              </Table.Pagination>
            ) : null}
          </Card.Body>
        </Card.Layout>
      </ContentsContainer>
    </PageLayout>
  );
}

const DatePickerWrap = styled.div`
  display: flex;
  align-items: center;
  i {
    margin: 0 10px;
    font-size: 14px;
    color: #323232;
  }
`;
