import { useSelector, useDispatch } from 'react-redux';

import { closeModal } from 'redux/common/slices/modals';

export default function Modals() {
  const dispatch = useDispatch();
  const { openedModals } = useSelector(state => state.modals);

  return openedModals.map((modal, index) => {
    const { Component, props } = modal;
    const {
      handleModalSubmit,
      handleModalClose,
      handleModalCancel,
      ...restProps
    } = props;

    const handleClose = async () => {
      if (typeof handleModalClose === 'function') {
        await handleModalClose();
      }
      dispatch(
        closeModal({
          Component: Component,
        }),
      );
    };

    const handleSubmit = async () => {
      if (typeof handleModalSubmit === 'function') {
        await handleModalSubmit();
      }
      handleClose();
    };
    const handleCancel = async () => {
      if (typeof handleModalCancel === 'function') {
        await handleModalCancel();
      }
      handleClose();
    };

    return (
      <Component
        key={index}
        modalClose={handleClose}
        modalSubmit={handleSubmit}
        modalCancel={handleCancel}
        {...restProps}
      />
    );
  });
}
