import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { API_URL, API_BOARD } from 'shared/constants/constants';

export const getBoardDetails = createAsyncThunk(
  'get/boardContent',
  async (bcData, thunkAPI) => {
    const { bdIdx, bcIdx } = bcData;
    if (bdIdx !== null) {
      const response = await axios.get(`${API_BOARD}/${bdIdx}/${bcIdx}`);
      return response.data;
    }
  },
);

export const getBoardNoticeLists = createAsyncThunk(
  'get/boardList',
  async (data, thunkAPI) => {
    console.log('data', data);
    const { bdIdx } = data;
    try {
      const response = await axios.get(
        `${API_URL}/notice-contents/${bdIdx}?category=0`,
      );

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue();
    }
  },
);

const boardSlice = createSlice({
  name: 'board',
  initialState: {
    boardNoticeLists: [],
    BoardLists: [],
    boardDetails: [],
  },
  reducers: {
    //
  },
  extraReducers: {
    [getBoardNoticeLists.fulfilled]: (state, action) => {
      state.boardNoticeLists = action.payload;
    },
    [getBoardDetails.fulfilled]: (state, action) => {
      state.boardDetails = action.payload;
    },
  },
});

export default boardSlice.reducer;
