import { Routes, Route } from 'react-router-dom';
import Modal from 'react-modal';

import User from 'router/User';
import Admin from 'router/Admin';

function App() {
  return (
    <Routes>
      <Route path="/*" element={<User />} />
      <Route path="/root/*" element={<Admin />} />
    </Routes>
  );
}
// element is not defined 에러가 방지
//사용자가 모달창이 아닌 다른 컴포넌트의 content를 바라보지 않도록 하기 위함
Modal.setAppElement('#root');

export default App;
