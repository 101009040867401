import { useState, useEffect } from 'react';

import Pagination from 'react-js-pagination';

import styled from '@emotion/styled';
import { theme } from 'shared/root/styles/theme';

import IconFirst from 'root/assets/imgs/pagination-left-icon-double-arrow.svg';
import IconPrev from 'root/assets/imgs/pagination-left-icon-arrow.svg';
import IconNext from 'root/assets/imgs/pagination-right-icon-arrow.svg';
import IconLast from 'root/assets/imgs/pagination-right-icon-double-arrow.svg';

// total : 전체 리스트 개수
// limit : 한 페이지에 보일 개수
// activePage : 활성화할 번호
// handleClick : 숫자 클릭 시, 이벤트 (리스트재정렬)
export default function BasicPagination({
  total,
  limit,
  activePage,
  handleClick,
}) {
  const [page, setPage] = useState(activePage);

  const handlePageChange = page => {
    setPage(page);
    handleClick(page);
  };

  useEffect(() => {
    setPage(activePage);
  }, [activePage, page]);

  return (
    <>
      {total && limit !== 0 ? (
        <PaginationContainer>
          <Pagination
            activePage={page}
            itemsCountPerPage={limit}
            totalItemsCount={total}
            pageRangeDisplayed={5}
            itemClassFirst={'item-first'}
            itemClassPrev={'item-prev'}
            itemClassNext={'item-next'}
            itemClassLast={'item-last'}
            onChange={handlePageChange}
          />
        </PaginationContainer>
      ) : null}
    </>
  );
}

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    font-size: 13px;
    color: #6c7293;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    &[class*='item'] {
      font-size: 0;
    }
    &.item-first {
      margin-right: -5px;
      background-image: url(${IconFirst});
    }
    &.item-prev {
      background-image: url(${IconPrev});
    }
    &.item-next {
      background-image: url(${IconNext});
    }
    &.item-last {
      margin-left: -5px;
      background-image: url(${IconLast});
    }
    &.active {
      font-weight: 700;
      border: 1px solid ${theme.colors.point};
      color: ${theme.colors.point};
      cursor: revert;
      transform: revert;
    }
    &.disabled {
      cursor: revert;
      transform: revert;
      opacity: 0;
    }
  }
`;
