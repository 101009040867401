import { useEffect, useState, useRef, useCallback } from 'react';

import { useForm, Controller } from 'react-hook-form';

import ReactMoment from 'react-moment';

import { Link, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { getPlaceList } from 'redux/root/slices/place';

import { SortNumOptions, PlaceStatusOptions } from 'shared/root/optionData';

import { updatePlaceStatus, deletePlace } from 'api/place';
import { setListIndex } from 'utils';

import PageLayout from 'root/components/PageLayout';
import TitleContainer from 'root/components/TitleContainer';
import ContentsContainer from 'root/components/ContentsContainer';
import BasicPagination from 'root/components/Pagination';

import ConfirmModal from 'root/components/Modals/ConfirmModal';
import ToastMessage from 'root/components/Modals/ToastMessage';
import { openModal } from 'redux/common/slices/modals';

import CommonStyledComponents from 'shared/root/styles/CommonStyledComponents';
import { CustomSelect, styleDefault } from 'shared/root/styles/common/Select';

import NoResult from 'root/components/NoResult';

const { Card, Table, Input, Button } = CommonStyledComponents;

export default function PlaceList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tableLists = useSelector(state => state.place.placeList);

  const takeNum = 10; // 한페이지 게시물수
  const [totalNum, setTotalNum] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [sortingNum, setSortingNum] = useState(takeNum);
  const [statusValue, setStatusValue] = useState('');
  const selectInputRef = useRef();

  const initialValues = {
    status: '',
    checkedLists: [],
  };
  const { register, handleSubmit, reset, control, watch, setValue, getValues } =
    useForm({
      defaultValues: initialValues,
    });

  const setTableLists = useCallback(
    (sortingNum, activePage) => {
      dispatch(
        getPlaceList({
          take: sortingNum,
          page: activePage,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    setTableLists(sortingNum, 1);
  }, [setTableLists, sortingNum]);

  useEffect(() => {
    setTotalNum(tableLists.total);
  }, [tableLists.total]);

  function handleClickPage(num) {
    setActivePage(num);
    setTableLists(sortingNum, num);
    reset(initialValues);
  }

  function handleSorting(e) {
    setSortingNum(e.value);
    setTableLists(e.value, 1);
    reset();
    reset(initialValues);
  }

  // 개별 상태변경
  function changeSingleStatus(value, action, idx) {
    updatePlaceStatus({
      checkedLists: idx,
      status: value.value,
    })
      .then(response => {
        setTableLists(sortingNum, activePage);
        reset();
        dispatch(
          openModal({
            Component: ToastMessage,
            props: {
              iconImg: 'complete',
              message: '저장되었습니다',
            },
          }),
        );
      })
      .catch(error => {
        dispatch(
          openModal({
            Component: ToastMessage,
            props: {
              iconImg: 'fail',
              message: '실패했습니다',
            },
          }),
        );
      });
  }

  // 일괄 상태변경
  function changeMultiStatus(e) {
    setStatusValue(e);
    setValue('status', e.value);
    const data =
      PlaceStatusOptions[
        PlaceStatusOptions.findIndex(obj => obj.value === `${e.value}`)
      ].label;

    onSubmit(watch(), data);
  }

  // 체크박스 전체 선택
  const handleAllCheckedList = checked => {
    if (checked) {
      const arr = [];
      tableLists.results.forEach(el => arr.push(String(el.idx)));
      setValue('checkedLists', arr);
    } else {
      setValue('checkedLists', []);
    }
  };

  // 선택삭제
  function handleDelete() {
    dispatch(
      openModal({
        Component: ConfirmModal,
        props: {
          message: '선택하신 항목을 삭제하시겠습니까?',
          handleModalSubmit: () => {
            const { status, ...deleteIdxs } = getValues();
            if (deleteIdxs.checkedLists.length !== 0) {
              deletePlace(deleteIdxs.checkedLists)
                .then(response => {
                  setTableLists(sortingNum, activePage);
                  reset();
                  dispatch(
                    openModal({
                      Component: ToastMessage,
                      props: {
                        iconImg: 'complete',
                        message: '삭제되었습니다',
                      },
                    }),
                  );
                })
                .catch(error => {
                  console.log(error);
                  reset();
                  dispatch(
                    openModal({
                      Component: ToastMessage,
                      props: {
                        iconImg: 'fail',
                        message: '실패했습니다',
                      },
                    }),
                  );
                });
            }
          },
        },
      }),
    );
  }

  const onSubmit = (data, status) => {
    console.log(data);
    if (data.checkedLists.length !== 0) {
      dispatch(
        openModal({
          Component: ConfirmModal,
          props: {
            message: `선택하신 항목을 ${status}(으)로 변경하시겠습니까?`,
            handleModalSubmit: () => {
              updatePlaceStatus(data)
                .then(response => {
                  setTableLists(sortingNum, activePage);
                  reset();
                  handleSelectReset();
                  dispatch(
                    openModal({
                      Component: ToastMessage,
                      props: {
                        iconImg: 'complete',
                        message: '수정되었습니다',
                      },
                    }),
                  );
                })
                .catch(error => {
                  console.log(error);
                  openModal({
                    Component: ToastMessage,
                    props: {
                      iconImg: 'fail',
                      message: '실패했습니다',
                    },
                  });
                });
            },
          },
        }),
      );
    } else {
      dispatch(
        openModal({
          Component: ToastMessage,
          props: {
            iconImg: 'fail',
            message: '항목을 선택해 주세요.',
          },
        }),
      );

      handleSelectReset();
    }
  };

  function handleSelectReset() {
    setStatusValue('');
    // console.log(selectInputRef.current.props.value);
  }

  return (
    <PageLayout>
      <TitleContainer>
        <h2>
          <span>현장관리</span>
          <b>현장목록</b>
        </h2>
      </TitleContainer>

      <ContentsContainer>
        <Card.Layout>
          <Card.Header>
            <Card.HeaderTitle>현장목록</Card.HeaderTitle>
            <Card.HeaderCount>
              총 <em>{tableLists.total ? tableLists.total : 0}</em>개
            </Card.HeaderCount>
            <Card.HeaderSorting>
              <CustomSelect
                name="sortNum"
                styles={styleDefault}
                options={SortNumOptions}
                defaultValue={SortNumOptions[0]}
                isSearchable={false}
                onChange={handleSorting}
              />
            </Card.HeaderSorting>
          </Card.Header>
          <Card.Body>
            <form method="post" onSubmit={handleSubmit(onSubmit)}>
              <Table.Options>
                <div>
                  <CustomSelect
                    ref={selectInputRef}
                    name="status"
                    placeholder={'진행상태 변경'}
                    onChange={changeMultiStatus}
                    styles={styleDefault}
                    value={statusValue}
                    isSearchable={false}
                    options={PlaceStatusOptions}
                  />
                  <Button
                    type="button"
                    size={'md'}
                    theme={'point2'}
                    status={watch().checkedLists.length > 0 ? '' : 'disabled'}
                    onClick={handleDelete}
                  >
                    선택삭제
                  </Button>
                </div>
              </Table.Options>
              <Table.Container layout={'fixed'}>
                <Table.Layout>
                  <colgroup>
                    <col style={{ width: '80px' }} />
                    <col style={{ width: '80px' }} />
                    <col style={{ width: '69.25%' }} />
                    <col style={{ width: '69.25%' }} />
                    <col style={{ width: '16%' }} />
                    <col style={{ width: '20%' }} />
                  </colgroup>
                  <thead>
                    <Table.Tr>
                      <Table.Th type={'checkbox'}>
                        <Input.Checkbox>
                          <input
                            type="checkbox"
                            onChange={e =>
                              handleAllCheckedList(e.target.checked)
                            }
                            checked={
                              sortingNum &&
                              tableLists.pageTotal &&
                              (watch().checkedLists.length === sortingNum ||
                                watch().checkedLists.length >=
                                  tableLists.pageTotal)
                                ? true
                                : false
                            }
                          />
                        </Input.Checkbox>
                      </Table.Th>
                      <Table.Th>번호</Table.Th>
                      <Table.Th>현장명</Table.Th>
                      <Table.Th>등록일</Table.Th>
                      <Table.Th>진행상태</Table.Th>
                      <Table.Th type={'more'}>관리</Table.Th>
                    </Table.Tr>
                  </thead>
                  <tbody>
                    {tableLists.length === 0 ? (
                      <Table.Tr noHover>
                        <Table.Td colSpan="6">
                          <NoResult />
                        </Table.Td>
                      </Table.Tr>
                    ) : (
                      tableLists.results.map((item, itemIdx) => (
                        <Table.Tr key={item.idx}>
                          <Table.Td type={'checkbox'}>
                            <Input.Checkbox>
                              <input
                                type="checkbox"
                                value={item.idx}
                                {...register('checkedLists')}
                              />
                            </Input.Checkbox>
                          </Table.Td>
                          <Table.Td>
                            {setListIndex(
                              itemIdx,
                              activePage,
                              sortingNum,
                              totalNum,
                            )}
                          </Table.Td>
                          <Table.Td
                            type={'link'}
                            onClick={() => {
                              navigate(`/root/place/details/${item.idx}`);
                            }}
                          >
                            {item.name}
                          </Table.Td>
                          <Table.Td>
                            <ReactMoment
                              date={item.createdAt}
                              format="YYYY-MM-DD&nbsp;&nbsp;HH:mm:ss"
                            />
                          </Table.Td>
                          <Table.Td type={'statusSelect'}>
                            <Controller
                              name=""
                              control={control}
                              defaultValue={
                                PlaceStatusOptions[
                                  PlaceStatusOptions.findIndex(
                                    obj => obj.value === `${item.status}`,
                                  )
                                ]
                              }
                              render={({ field }) => (
                                <CustomSelect
                                  {...field}
                                  isSearchable={false}
                                  styles={styleDefault}
                                  options={PlaceStatusOptions}
                                  value={
                                    PlaceStatusOptions[
                                      PlaceStatusOptions.findIndex(
                                        obj => obj.value === `${item.status}`,
                                      )
                                    ]
                                  }
                                  menuPortalTarget={document.querySelector(
                                    'body',
                                  )}
                                  onChange={(
                                    val,
                                    action,
                                    idx = `${item.idx}`,
                                  ) => changeSingleStatus(val, action, idx)}
                                />
                              )}
                            />
                          </Table.Td>
                          <Table.Td type={'more'}>
                            <Button
                              theme={'underline'}
                              size={'sm'}
                              onClick={() => {
                                navigate(`/root/place/details/${item.idx}`);
                              }}
                            >
                              상세보기
                            </Button>
                          </Table.Td>
                        </Table.Tr>
                      ))
                    )}
                  </tbody>
                </Table.Layout>
              </Table.Container>
              <Table.Options>
                <div>
                  <CustomSelect
                    name="status"
                    ref={selectInputRef}
                    placeholder={'진행상태 변경'}
                    onChange={changeMultiStatus}
                    value={statusValue}
                    styles={styleDefault}
                    isSearchable={false}
                    options={PlaceStatusOptions}
                  />
                  <Button
                    type="button"
                    size={'md'}
                    theme={'point2'}
                    status={watch().checkedLists.length > 0 ? '' : 'disabled'}
                    onClick={handleDelete}
                  >
                    선택삭제
                  </Button>
                </div>
              </Table.Options>
            </form>
            {totalNum ? (
              <Table.Pagination>
                <BasicPagination
                  total={totalNum}
                  limit={sortingNum}
                  activePage={activePage}
                  handleClick={handleClickPage}
                />
              </Table.Pagination>
            ) : null}
          </Card.Body>
        </Card.Layout>
      </ContentsContainer>
    </PageLayout>
  );
}
