import styled from '@emotion/styled';
import { patchUsersChangepw } from 'api/user';
import TextInput from 'components/Form/TextInput';
import ToastMessage from 'components/Modals/ToastMessage';
import PageLayout from 'components/PageLayout';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { openModal } from 'redux/common/slices/modals';
import {
  PASSWORD_REGEXP,
  PASSWORD_VALID_MESSAGE,
} from 'shared/constants/constants';
import CommonStyledComponents from 'shared/styles/CommonStyledComponents';

const { Button, Area, FormField, FieldContainer, FieldLabel } =
  CommonStyledComponents;

const initialValues = {
  password: '',
  targetPassWord: '',
  targetPassWordConfirm: '',
};

export default function ChangePassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: initialValues,
  });

  //저장버튼 클릭 form전송
  async function handleClickSubmit(data) {
    const reqData = {
      cur_password: data.password,
      new_password: data.targetPassword,
    };
    try {
      const res = patchUsersChangepw(reqData);
      if (res) {
        dispatch(
          openModal({
            Component: ToastMessage,
            props: {
              iconImg: 'complete',
              message: '비밀번호가 변경되었습니다.',
            },
          }),
        );
        navigate('/');
      }
    } catch (error) {
      dispatch(
        openModal({
          Component: ToastMessage,
          props: {
            iconImg: 'fail',
            message: error.response.data.message,
          },
        }),
      );
    }
  }

  return (
    <ChangePasswordContainer>
      <PageLayout title="비밀번호 변경" closeBtn>
        <Area>
          <FormField align="vertical">
            <FieldLabel>현재 비밀번호</FieldLabel>
            <FieldContainer>
              <TextInput
                type="password"
                register={register('password', {
                  required: '현재 비밀번호를 입력해주세요.',
                  minLength: {
                    value: 8,
                    message: PASSWORD_VALID_MESSAGE,
                  },
                  maxLength: {
                    value: 12,
                    message: PASSWORD_VALID_MESSAGE,
                  },
                  pattern: {
                    value: PASSWORD_REGEXP,
                    message: PASSWORD_VALID_MESSAGE,
                  },
                })}
                errors={errors}
                placeholder="현재 비밀번호 입력"
              />
            </FieldContainer>
          </FormField>

          <FormField align="vertical">
            <FieldLabel>새 비밀번호</FieldLabel>
            <FieldContainer>
              <TextInput
                type="password"
                register={register('targetPassword', {
                  required: '새 비밀번호를 입력해주세요.',
                  minLength: {
                    value: 8,
                    message: PASSWORD_VALID_MESSAGE,
                  },
                  maxLength: {
                    value: 12,
                    message: PASSWORD_VALID_MESSAGE,
                  },
                  pattern: {
                    value: PASSWORD_REGEXP,
                    message: PASSWORD_VALID_MESSAGE,
                  },
                })}
                errors={errors}
                placeholder="새 비밀번호 입력"
              />
            </FieldContainer>
          </FormField>

          <FormField align="vertical">
            <FieldLabel>새 비밀번호 확인</FieldLabel>
            <FieldContainer>
              <TextInput
                type="password"
                register={register('targetPassWordConfirm', {
                  required: '새 비밀번호를 입력해주세요.',
                  validate: value =>
                    value === watch('targetPassword') ||
                    '비밀번호가 일치하지 않습니다.',
                })}
                errors={errors}
                placeholder="새 비밀번호 확인"
              />
            </FieldContainer>
          </FormField>

          <ButtonWrapper>
            <Button
              type="button"
              size={'full-xl'}
              theme={'point'}
              status={!isValid && 'disabled'}
              onClick={handleSubmit(d => handleClickSubmit(d))}
            >
              다음
            </Button>
          </ButtonWrapper>
        </Area>
      </PageLayout>
    </ChangePasswordContainer>
  );
}

const ChangePasswordContainer = styled.div``;
const ButtonWrapper = styled.div`
  margin-top: 30px;
`;
