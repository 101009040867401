import styled from '@emotion/styled';

import { Link } from 'react-router-dom';

import ImgNoList from 'assets/imgs/img_no_list.svg';

import Button from 'shared/styles/common/Button';

export default function DefectNoList() {
  return (
    <NoListContainer>
      <img src={ImgNoList} alt="" />
      <NoListTitle>전송한 기록이 없습니다.</NoListTitle>
      <NoListDesc>새로운 하자내용을 작성해주세요.</NoListDesc>
      <Link to="/defectwrite">
        <Button size={'sm'} theme={'point'}>
          하자 작성하기
        </Button>
      </Link>
    </NoListContainer>
  );
}

// 전송기록이 없는 컨텐츠
const NoListContainer = styled.div`
  padding: 10vh 0;
  text-align: center;
`;
const NoListTitle = styled.div`
  margin-top: 14px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
`;
const NoListDesc = styled.div`
  margin: 4px 0 14px;
  font-size: 14px;
  line-height: 1.57;
  color: #444;
`;
