import styled from '@emotion/styled';
import { patchUsersRepw } from 'api/user';
import TextInput from 'components/Form/TextInput';
import PageLayout from 'components/PageLayout';
import Description from 'components/signup/Description';
import Paragraph from 'components/signup/Paragraph';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CommonStyledComponents from 'shared/styles/CommonStyledComponents';
import { openModal } from 'redux/common/slices/modals';
import ToastMessage from 'components/Modals/ToastMessage';
const { Area, Button, FormField, FieldContainer, FieldLabel } =
  CommonStyledComponents;

const initialValues = {
  id: '',
  email: '',
};

export default function FindPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [verifyPassed, setVerifyPassed] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: initialValues,
  });

  async function handleClickSubmit(data) {
    try {
      const res = await patchUsersRepw(data.id, data.email);
      if (res.error) {
      } else {
        dispatch(
          openModal({
            Component: ToastMessage,
            props: {
              iconImg: 'complete',
              message: '비밀번호가 재발급 되었습니다. 이메일을 확인해주세요.',
            },
          }),
        );
        setVerifyPassed(true);
      }
    } catch (error) {
      dispatch(
        openModal({
          Component: ToastMessage,
          props: {
            iconImg: 'fail',
            message: '일치하는 회원정보가 없습니다.',
          },
        }),
      );
    }
  }

  return (
    <PageLayout title="비밀번호 재발급" backBtn>
      <FindPasswordContainer>
        <Area>
          <Paragraph>비밀번호 재발급</Paragraph>
          <Description>
            {verifyPassed ? (
              <>
                메일을 발송했습니다.
                <br />
                새로운 비밀번호로 로그인해주세요.
              </>
            ) : (
              <>
                회원가입 시 등록한 아이디와 이메일을 입력해주세요.
                <br />
                새로운 비밀번호를 보내드립니다.
              </>
            )}
          </Description>

          <FormField align="vertical">
            <FieldLabel>아이디</FieldLabel>
            <FieldContainer>
              <TextInput
                register={register('id', {
                  required: '아이디를 입력해주세요.',
                })}
                errors={errors}
                placeholder="아이디"
                disabled={verifyPassed}
              />
            </FieldContainer>
          </FormField>

          <FormField align="vertical">
            <FieldLabel>이메일</FieldLabel>
            <FieldContainer>
              <TextInput
                register={register('email', {
                  required: '이메일을 입력해주세요.',
                  pattern: {
                    value:
                      /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/,
                    message: '올바른 이메일 주소만 입력 가능합니다',
                  },
                })}
                errors={errors}
                placeholder="이메일"
                disabled={verifyPassed}
              />
            </FieldContainer>
          </FormField>

          <ButtonWrapper>
            {verifyPassed ? (
              <Button
                type="button"
                size={'full-xl'}
                theme={'point'}
                onClick={() => navigate('/login')}
              >
                로그인
              </Button>
            ) : (
              <Button
                type="button"
                size={'full-xl'}
                theme={'point'}
                status={!isValid && 'disabled'}
                onClick={handleSubmit(d => handleClickSubmit(d))}
              >
                다음
              </Button>
            )}
          </ButtonWrapper>
        </Area>
      </FindPasswordContainer>
    </PageLayout>
  );
}

const FindPasswordContainer = styled.div``;
const ButtonWrapper = styled.div`
  margin-top: 30px;
`;
