import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'shared/root/styles/theme';

import IconCheck from 'assets/imgs/checkbox-icon-arrow.svg';

const Checkbox = styled.label`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  &:not(:last-of-type) {
    margin-right: 16px;
  }
  input[type='checkbox'] {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border: 1px solid #e3e4eb;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    &:checked {
      border-color: #0b6fe8;
      background-color: #0b6fe8;
      background-image: url(${IconCheck});
      background-repeat: no-repeat;
      background-position: center;
    }
  }
`;

const Radio = styled.label`
  display: inline-flex;
  align-items: center;
  padding: 6px 8px;
  cursor: pointer;
  input[type='radio'] {
    position: relative;
    margin-right: 6px;
    width: 20px;
    height: 20px;
    border: 1px solid ${theme.colors.divider};
    border-radius: 4px;
    cursor: pointer;
    &:checked {
      background-color: ${theme.colors.point};
      border-color: ${theme.colors.point};
      background-image: url(${IconCheck});
      background-repeat: no-repeat;
      background-position: center;
    }
  }
`;

export const Input = {
  Checkbox,
  Radio,
};
