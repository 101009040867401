import { useState, useEffect } from 'react';

import Pagination from 'react-js-pagination';

import styled from '@emotion/styled';
import { theme } from 'shared/root/styles/theme';

import IconFirst from 'root/assets/imgs/pagination-left-icon-double-arrow.svg';
import IconPrev from 'root/assets/imgs/pagination-left-icon-arrow.svg';
import IconNext from 'root/assets/imgs/pagination-right-icon-arrow.svg';
import IconLast from 'root/assets/imgs/pagination-right-icon-double-arrow.svg';

export default function usePagination({ target, take = 10 }) {
  const [page, setPage] = useState(1); // 현재 페이지
  const [total, setTotal] = useState(0); // 총 개수
  const [sorting, setSorting] = useState(take); // 정렬 개수

  // 총 개수 세팅
  useEffect(() => {
    setTotal(target.total || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target]);

  // 페이지 전환
  const handleChangePage = e => {
    setPage(e);
  };

  // 정렬
  const handleChangeSorting = e => {
    setPage(1); //페이지 초기화
    setSorting(e.value); //페이지 초기화
  };

  // 페이지네이션 컴포넌트
  const PaginationComponent = e => {
    return (
      <>
        {total && sorting !== 0 ? (
          <PaginationContainer>
            <Pagination
              activePage={Number(page)}
              itemsCountPerPage={Number(sorting)}
              totalItemsCount={Number(total)}
              pageRangeDisplayed={5}
              itemClassFirst={'item-first'}
              itemClassPrev={'item-prev'}
              itemClassNext={'item-next'}
              itemClassLast={'item-last'}
              onChange={handleChangePage}
            />
          </PaginationContainer>
        ) : null}
      </>
    );
  };

  // 페이지네이션 인덱스 출력
  const setPaginationIndex = idx => {
    const offset = (page - 1) * sorting;
    const indexNum = total ? total - offset - idx : 0;
    return Number(indexNum) > 0 ? indexNum : ''; //음수로 출력되는것을 방지
  };

  return {
    paginationData: { take, total, page, sorting },
    PaginationComponent,
    setPaginationIndex,
    handleChangePage,
    handleChangeSorting,
  };
}

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    font-size: 13px;
    color: #6c7293;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    &[class*='item'] {
      font-size: 0;
    }
    &.item-first {
      margin-right: -5px;
      background-image: url(${IconFirst});
    }
    &.item-prev {
      background-image: url(${IconPrev});
    }
    &.item-next {
      background-image: url(${IconNext});
    }
    &.item-last {
      margin-left: -5px;
      background-image: url(${IconLast});
    }
    &.active {
      font-weight: 700;
      border: 1px solid ${theme.colors.point};
      color: ${theme.colors.point};
      cursor: revert;
      transform: revert;
    }
    &.disabled {
      cursor: revert;
      transform: revert;
      opacity: 0;
    }
  }
`;
