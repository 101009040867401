import styled from '@emotion/styled';
import React from 'react';

const Paragraph = ({ children }) => {
  return <ParagraphContainer>{children}</ParagraphContainer>;
};

export default Paragraph;

const ParagraphContainer = styled.h1`
  margin-bottom: 20px;
  color: var(--black, #000);
  /* 26_medium */
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 36.4px */
`;
