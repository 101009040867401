import { configureStore } from '@reduxjs/toolkit';

import tokenSlice from 'redux/slices/token';
import userSlice from 'redux/slices/user';
import boardSlice from 'redux/slices/board';
import permissionSlice from 'redux/slices/permission';
import selectionModalSlice from 'redux/slices/selectionModal';
import modalsSlice from 'redux/common/slices/modals';

// root slices
import placeSlice from 'redux/root/slices/place';
import rootNavSlice from 'redux/root/slices/nav';
import rootTokenSlice from 'redux/root/slices/token';
import rootUserSlice from 'redux/root/slices/user';
import rootDefectSlice from 'redux/root/slices/defect';
import rootBoardSlice from 'redux/root/slices/board';
import settingsSlice from 'redux/root/slices/settings';

const reducer = {
  //common
  modals: modalsSlice,
  //user
  token: tokenSlice,
  user: userSlice,
  board: boardSlice,
  permission: permissionSlice,
  selectionModal: selectionModalSlice,
  place: placeSlice,
  //root
  rootSideNav: rootNavSlice,
  rootToken: rootTokenSlice,
  rootUser: rootUserSlice,
  rootDefect: rootDefectSlice,
  rootBoard: rootBoardSlice,
  settings: settingsSlice,
};

export default configureStore({
  reducer: reducer,
  devTools: true,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
