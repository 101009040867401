import { useSelector, useDispatch } from 'react-redux';

import TextInput from 'components/Form/TextInput';
import RadioInputs from 'components/Form/RadioInputs';

import selectionModal from 'components/Modals/SelectionModal';
import { openModal } from 'redux/common/slices/modals';

import {
  DefectTypeData,
  WorkStatusData,
  WorkMethodData,
} from 'shared/root/defectData';

import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'shared/styles/theme';
import CommonStyledComponents from 'shared/styles/CommonStyledComponents';

import arrowIcon from 'assets/imgs/icon_arrow.svg';

const {
  Area,
  Button,
  FormField,
  FieldLabel,
  FieldContainer,
  FieldFlex,
  ErrorMessage,
} = CommonStyledComponents;

export default function DefectWriteForm({
  setValue,
  getValues,
  watch,
  ...props
}) {
  const dispatch = useDispatch();
  const userPlaceDetails = useSelector(state => state.place.placeDetails);
  const dfpList = userPlaceDetails.dfp;
  const sort1Arr =
    dfpList &&
    Object.keys(dfpList).map((val, idx) => {
      return (val = `${val}`);
    });

  const dfpArr_sort1 =
    dfpList &&
    watch.sort1 &&
    Object.values(dfpList[getValues('sort1')]);

  const sort2Arr =
    dfpList &&
    watch.sort1 &&
    dfpArr_sort1.map((val, idx) => {
      return val.sort2;
    });

  const sort3Arr =
    dfpList &&
    watch.sort1 &&
    watch.sort2 &&
    dfpArr_sort1[
      dfpArr_sort1.findIndex(elem => {
        return elem.sort2 === watch.sort2;
      })
    ].sort3;

  const showselectionModal = data => {
    dispatch(
      openModal({
        Component: selectionModal,
        props: {
          data: data,
          handleModalSubmit: () => {
            if (data.name === 'sort1') {
              setValue('sort2', '');
              setValue('sort3', '');
            } else if (data.name === 'sort2') {
              setValue('sort3', '');
            }
          },
        },
      }),
    );
  };

  return (
    <Area>
      <FormField>
        <FieldLabel>동</FieldLabel>
        <FieldContainer>
          <SelectionInput
            isError={props.errors['sort1'] ? true : false}
            isChecked={watch.sort1 ? true : false}
            onClick={() => {
              showselectionModal({
                title: '동',
                name: 'sort1',
                item: sort1Arr.map((item, idx) => {
                  return { value: item, label: item };
                }),
                defaultChecked: getValues('sort1'),
              });
            }}
          >
            {watch.sort1 ? watch.sort1 : '선택하세요'}
            <img src={arrowIcon} alt="작성 취소" />
          </SelectionInput>
          <input
            type="hidden"
            register={props.register('sort1', {
              required: '동을 선택해주세요.',
            })}
            errors={props.errors}
          />
          {props.errors['sort1'] && props.errors['sort1'].message && (
            <ErrorMessage>{props.errors['sort1'].message}</ErrorMessage>
          )}
        </FieldContainer>
      </FormField>

      <FormField>
        <FieldLabel>호수</FieldLabel>
        <FieldContainer>
          <SelectionInput
            isError={props.errors['sort2'] ? true : false}
            isChecked={watch.sort2 ? true : false}
            disabled={watch.sort1 ? false : true}
            onClick={() => {
              watch.sort1 &&
                showselectionModal({
                  title: '호수',
                  name: 'sort2',
                  item: sort2Arr.map((item, idx) => {
                    return { value: item, label: item };
                  }),
                  defaultChecked: getValues('sort2'),
                });
            }}
          >
            {watch.sort2 ? watch.sort2 : '선택하세요'}
            <img src={arrowIcon} alt="작성 취소" />
          </SelectionInput>
          <input
            type="hidden"
            register={props.register('sort2', {
              required: '호수를 선택해주세요.',
            })}
            errors={props.errors}
          />
          {props.errors['sort2'] && props.errors['sort2'].message && (
            <ErrorMessage>{props.errors['sort2'].message}</ErrorMessage>
          )}
        </FieldContainer>
      </FormField>

      <FormField>
        <FieldLabel>위치</FieldLabel>
        <FieldContainer>
          <SelectionInput
            isError={props.errors['sort3'] ? true : false}
            isChecked={watch.sort3 ? true : false}
            disabled={watch.sort1 && watch.sort2 ? false : true}
            onClick={() => {
              watch.sort1 &&
                watch.sort2 &&
                showselectionModal({
                  title: '위치',
                  name: 'sort3',
                  item: sort3Arr
                    .replace(' ', '')
                    .split(',')
                    .map((item, idx) => {
                      return { value: item, label: item };
                    }),
                  defaultChecked: getValues('sort3'),
                });
            }}
          >
            {watch.sort3 ? watch.sort3 : '선택하세요'}
            <img src={arrowIcon} alt="작성 취소" />
          </SelectionInput>
          <input
            type="hidden"
            register={props.register('sort3', {
              required: '위치를 선택해주세요.',
            })}
            errors={props.errors}
          />
          {props.errors['sort3'] && props.errors['sort3'].message && (
            <ErrorMessage>{props.errors['sort3'].message}</ErrorMessage>
          )}
        </FieldContainer>
      </FormField>

      <FormField>
        <FieldLabel>작업상태</FieldLabel>
        <FieldContainer>
          <RadioInputs
            register={props.register('status', {
              required: '작업상태를 선택해주세요.',
            })}
            errors={props.errors}
            data={WorkStatusData}
          ></RadioInputs>
        </FieldContainer>
      </FormField>

      <FormField>
        <FieldLabel>하자유형</FieldLabel>
        <FieldContainer>
          <SelectionInput
            isError={props.errors['type'] ? true : false}
            isChecked={watch.type ? true : false}
            onClick={() => {
              showselectionModal({
                title: '하자유형',
                name: 'type',
                item: DefectTypeData.map((item, idx) => {
                  return { value: item.value, label: item.label };
                }),
                defaultChecked: getValues('type'),
              });
            }}
          >
            {watch.type
              ? DefectTypeData[
                  DefectTypeData.findIndex(obj => {
                    return obj.value === getValues('type');
                  })
                ].label
              : '선택하세요'}
            <img src={arrowIcon} alt="작성 취소" />
          </SelectionInput>
          <input
            type="hidden"
            register={props.register('type', {
              required: '하자유형을 선택해주세요.',
            })}
            errors={props.errors}
          />
          {props.errors['type'] && props.errors['type'].message && (
            <ErrorMessage>{props.errors['type'].message}</ErrorMessage>
          )}
        </FieldContainer>
      </FormField>

      <FormField>
        <FieldLabel>
          내용
          <TextCountItem>
            (
            <em>
              {watch.content && watch.content.length !== 0
                ? watch.content.length
                : 0}
            </em>
            /20자)
          </TextCountItem>
        </FieldLabel>
        <FieldContainer>
          <TextInput
            register={props.register('content', {
              maxLength: {
                value: 20,
                message: '20자 이하로만 작성이 가능합니다.',
              },
              onChange: e => {
                const text = e.target.value;
                if (e.target.value.length >= 20) {
                  e.target.value = text.substr(0, 21);
                }
              },
            })}
            errors={props.errors}
            placeholder="내용을 입력하세요"
          />
        </FieldContainer>
      </FormField>

      {
        // 작업상태의 작업완료 선택 시 작업방법 항목 추가 표시
        watch.status === 'completed_work' && (
          <FormField>
            <FieldLabel>작업방법</FieldLabel>
            <FieldContainer>
              <RadioInputs
                register={props.register('work_method', {
                  required: '작업방법을 선택해주세요.',
                })}
                errors={props.errors}
                data={WorkMethodData}
              ></RadioInputs>
            </FieldContainer>
          </FormField>
        )
      }

      {
        // 작업방법 '교체'항목 선택 시 '교체면적' 표시
        watch.work_method === 'replacement' && (
          <FormField>
            <FieldLabel>교체면적</FieldLabel>
            <FieldContainer>
              <FieldFlex>
                <TextInput
                  register={props.register('replacement_square_meter', {
                    required: true,
                    // pattern: /^[0-9]*$/,
                  })}
                  errors={props.errors}
                  suffix="m"
                />
                <TextInput
                  register={props.register('replacement_sheet', {
                    required: true,
                    // pattern: /^[0-9]*$/,
                  })}
                  errors={props.errors}
                  suffix="장"
                />
              </FieldFlex>
              {((props.errors.replacement_square_meter &&
                props.errors.replacement_square_meter.type === 'required') ||
                (props.errors.replacement_sheet &&
                  props.errors.replacement_sheet.type === 'required')) && (
                <ErrorMessage>교체면적을 입력해주세요.</ErrorMessage>
              )}
              {((props.errors.replacement_square_meter &&
                props.errors.replacement_square_meter.type === 'pattern') ||
                (props.errors.replacement_sheet &&
                  props.errors.replacement_sheet.type === 'pattern')) && (
                <ErrorMessage>숫자만 입력가능합니다.</ErrorMessage>
              )}
            </FieldContainer>
          </FormField>
        )
      }
    </Area>
  );
}

const SelectionInput = styled.div`
  position: relative;
  display: flex;
  height: 48px;
  padding: 15px 50px 15px 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  color: ${theme.colors.placeholder};
  cursor: pointer;
  img {
    position: absolute;
    width: 12px;
    height: 12px;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
  ${props =>
    props.isError === true &&
    css`
      border-color: ${theme.colors.valid} !important;
    `}
  ${props =>
    props.isChecked === true &&
    css`
      color: #000;
    `}
  ${props =>
    props.disabled === true &&
    css`
      background-color: ${theme.colors.bg2};
      color: #ccc;
      img {
        opacity: 0.3;
      }
    `}
`;
const TextCountItem = styled.span`
  font-size: 13px;
  line-height: 1.62;
  margin-left: 3px;
  color: ${theme.colors.placeholder};
  em {
    color: #0089cf;
  }
`;
