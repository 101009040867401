import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from '../theme';

export default function Button({ size, theme, children, ...props }) {
  if (props.href) {
    return (
      <StyledLinkButton size={size} theme={theme} {...props}>
        {children}
      </StyledLinkButton>
    );
  } else {
    return (
      <StyledButton size={size} theme={theme} {...props}>
        {children}
      </StyledButton>
    );
  }
}

// styled-components
const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  border-radius: 2px;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  -webkit-appearance: none;
  outline: none;
  transition: all 0.2s;
  cursor: pointer;
  img {
    margin-right: 5px;
    ${props =>
      props.status === 'disabled' &&
      css`
        filter: opacity(0.5);
      `}
  }

  /* theme */
  ${props =>
    props.theme === 'point' &&
    css`
      background-color: ${theme.colors.point};
      color: #fff;
      &:hover {
        background-color: #0047d5;
      }
      ${props.status === 'disabled' &&
      css`
        color: #fff;
        background-color: #a9cdf7;
        pointer-events: none;
      `}
    `}
  ${props =>
    props.theme === 'point2' &&
    css`
      background-color: ${theme.colors.point2};
      color: ${theme.colors.point};
      &:hover {
        background-color: #b0d0f8;
      }
      ${props.status === 'disabled' &&
      css`
        color: #999;
        background-color: #f5f5f5;
        pointer-events: none;
      `}
    `}
  ${props =>
    props.theme === 'outline-default' &&
    css`
      border: 1px solid #e3e4eb;
      background-color: #fff;
      color: #323232;
      &:hover {
        background-color: #f3f6fb;
      }
      ${props.status === 'disabled' &&
      css`
        color: #999;
        border-color: #f5f5f5;
        background-color: #f5f5f5;
        pointer-events: none;
      `}
    `}
  ${props =>
    props.theme === 'underline' &&
    css`
      position: relative;
      padding-bottom: 2px;
      text-decoration: none !important;
      background-color: transparent;
      color: #0b6fe8;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #0b6fe8;
      }
      &:hover {
        color: #0047d5;
      }
      ${props.status === 'disabled' &&
      css`
        color: #999;
        pointer-events: none;
      `}
    `}
  

  /* size */
  ${props =>
    props.size === 'sm' &&
    css`
      width: auto;
      font-size: 14px;
    `}
  ${props =>
    props.size === 'md' &&
    css`
      width: auto;
      min-width: 74px;
      padding: 0 12px;
      height: 36px;
      font-size: 14px;
    `}
  ${props =>
    props.size === 'lg' &&
    css`
      width: 130px;
      height: 50px;
      font-size: 14px;
    `}
  ${props =>
    props.size === 'xl' &&
    css`
      height: 50px;
      font-size: 16px;
      font-weight: 500;
    `}
`;
const StyledLinkButton = StyledButton.withComponent('a');
