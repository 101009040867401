import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BoardStatusOptions, BoardTypeOptions } from 'shared/root/optionData';

import { getBoardDetails } from 'redux/root/slices/board';
import { StatusChange } from 'api/board';
import { addBoardContent, UpdateBoardContent } from 'api/board';

import { BoardWriteData } from 'shared/root/boardSettingsData';

import PageLayout from 'root/components/PageLayout';
import TitleContainer from 'root/components/TitleContainer';
import ContentsContainer from 'root/components/ContentsContainer';
import TextInput from 'root/components/Form/TextInput';

import ConfirmModal from 'root/components/Modals/ConfirmModal';
import ToastMessage from 'root/components/Modals/ToastMessage';
import { openModal } from 'redux/common/slices/modals';

import CommonStyledComponents from 'shared/root/styles/CommonStyledComponents';
import { CustomSelect, styleBox } from 'shared/root/styles/common/Select';
import FormCkEditor from 'root/components/FormCkEditor';
const { Card, WriteForm, Button, ButtonsGrid } = CommonStyledComponents;

export default function BoardWrite() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const { admin } = useSelector(state => state.rootUser);

  const bdIdx = params.bdIdx;
  const bcIdx = params.bcIdx;
  const isUpdate = bdIdx && bcIdx ? true : false;
  const boardData = BoardWriteData[params.bdIdx];
  const BoardDetails = useSelector(state => state.rootBoard.boardDetails);

  const [createdAt, setCreatedAt] = useState(new Date());

  const initialValues = {
    status: '', // 0:삭제, 1:미등록, 2:등록
    type: '', // 1:공지사항, 2:일반
    bd_idx: String(bdIdx),
    admin_idx: '',
    write_name: '',
    title: '',
    link_status: '0',
    link: '',
    content: '',
    category: [],
  };

  const {
    register,
    setValue,
    reset,
    control,
    watch,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: initialValues,
  });

  useEffect(() => {
    //게시글 수정
    if (isUpdate) {
      dispatch(
        getBoardDetails({
          bdIdx: bdIdx,
          bcIdx: bcIdx,
        }),
      )
        .then(res => {
          if (res.payload.status === 0) {
            navigate('/root/notfound', { replace: true });
          } else {
            //기존 정보 불러오기
            reset(res.payload);
            setValue(
              'type',
              BoardTypeOptions[
                BoardTypeOptions.findIndex(
                  obj => obj.value === `${res.payload.type}`,
                )
              ],
            );
            setValue(
              'status',
              BoardStatusOptions[
                BoardStatusOptions.findIndex(
                  obj => obj.value === `${res.payload.status}`,
                )
              ],
            );
            setCreatedAt(BoardDetails.createdAt);
          }
        })
        .catch(error => {
          navigate('/root/notfound', { replace: true });
        });
    }
    //게시글 신규등록
    else {
      if (admin) {
        reset(initialValues);
        setValue('status', BoardStatusOptions[1]);
        setValue('type', BoardTypeOptions[1]);
        setValue('admin_idx', admin.idx);
        setValue('write_name', admin.name);
      }
    }
  }, [params, admin, dispatch, setValue]);

  const onSubmit = data => {
    console.log(data);
    if (isUpdate) {
      UpdateBoardContent(data)
        .then(response => {
          dispatch(
            openModal({
              Component: ToastMessage,
              props: {
                iconImg: 'complete',
                message: '게시글이 수정되었습니다.',
              },
            }),
          );
          navigate(-1);
        })
        .catch(error => {
          console.log(error.request.status);
          dispatch(
            openModal({
              Component: ToastMessage,
              props: {
                iconImg: 'fail',
                message:
                  error.request.status === 413
                    ? '용량이 초과되어 등록이 불가능합니다. (최대 500 MB)'
                    : error.message, //'실패했습니다',
              },
            }),
          );
        });
    } else {
      addBoardContent(data)
        .then(response => {
          dispatch(
            openModal({
              Component: ToastMessage,
              props: {
                iconImg: 'complete',
                message: '게시글이 등록되었습니다.',
              },
            }),
          );
          navigate(-1);
        })
        .catch(error => {
          console.log(error);
          dispatch(
            openModal({
              Component: ToastMessage,
              props: {
                iconImg: 'fail',
                message:
                  error.request.status === 413
                    ? '용량이 초과되어 등록이 불가능합니다. (최대 500 MB)'
                    : error.message, //'실패했습니다',
              },
            }),
          );
        });
    }
  };
  const onError = error => {
    console.log('onError', error);
  };

  function handleClickSubmit(data) {
    onSubmit(data);
  }
  const handleClickDelete = data => {
    console.log(data);
    const deleteData = {
      status: '0',
      bc_idxs: [data.idx],
    };
    dispatch(
      openModal({
        Component: ConfirmModal,
        props: {
          message: '선택하신 항목을 삭제하시겠습니까?',
          handleModalSubmit: () => {
            StatusChange(deleteData)
              .then(response => {
                dispatch(
                  openModal({
                    Component: ToastMessage,
                    props: {
                      iconImg: 'complete',
                      message: '게시글이 삭제되었습니다.',
                    },
                  }),
                );
                navigate(-1);
              })
              .catch(error => {
                console.log(error);
                dispatch(
                  openModal({
                    Component: ToastMessage,
                    props: {
                      iconImg: 'fail',
                      message: error.message, //'실패했습니다',
                    },
                  }),
                );
              });
          },
        },
      }),
    );
  };

  return (
    <PageLayout>
      <TitleContainer>
        <h2>
          <span>게시판</span>
          <span>{boardData ? boardData.title : null}</span>
          <b>{isUpdate ? '상세' : '추가하기'}</b>
        </h2>
        <ButtonsGrid>
          {isUpdate ? (
            <Button
              theme={'outline-default'}
              size={'md'}
              onClick={() => handleClickDelete(watch())}
            >
              삭제
            </Button>
          ) : null}
          <Button
            type="submit"
            theme={'point'}
            size={'md'}
            onClick={handleSubmit(d => handleClickSubmit(d))}
            status={Object.keys(dirtyFields).length > 0 ? '' : 'disabled'}
          >
            저장
          </Button>
        </ButtonsGrid>
      </TitleContainer>

      <ContentsContainer>
        <Card.Layout>
          <Card.Header>
            <Card.HeaderTitle>게시물 내용</Card.HeaderTitle>
            <Card.HeaderRequired>필수항목</Card.HeaderRequired>
          </Card.Header>
          <Card.Body>
            <WriteForm.Layout
              method="post"
              onSubmit={handleSubmit(onSubmit, onError)}
            >
              {boardData &&
                boardData.write_list.map((item, itemIdx) => {
                  if (item.column === 'status') {
                    return (
                      <WriteForm.Fieldset key={item.column}>
                        <WriteForm.Label>게시 상태</WriteForm.Label>
                        <WriteForm.Inputs>
                          <Controller
                            name="status"
                            control={control}
                            render={({ field }) => (
                              <CustomSelect
                                {...field}
                                styles={styleBox}
                                width={'400px'}
                                isSearchable={false}
                                options={BoardStatusOptions}
                                defaultValue={
                                  BoardStatusOptions[
                                    BoardStatusOptions.findIndex(
                                      obj => obj.value === 1,
                                    )
                                  ]
                                }
                              />
                            )}
                          />
                        </WriteForm.Inputs>
                      </WriteForm.Fieldset>
                    );
                  } else if (item.column === 'type') {
                    return (
                      <WriteForm.Fieldset key={item.column}>
                        <WriteForm.Label>게시 타입</WriteForm.Label>
                        <WriteForm.Inputs>
                          <Controller
                            name="type"
                            control={control}
                            render={({ field }) => (
                              <CustomSelect
                                {...field}
                                styles={styleBox}
                                width={'400px'}
                                isSearchable={false}
                                options={BoardTypeOptions}
                              />
                            )}
                          />
                        </WriteForm.Inputs>
                      </WriteForm.Fieldset>
                    );
                  } else if (item.column === 'content') {
                    return (
                      <WriteForm.Fieldset key={item.column}>
                        <WriteForm.Label required={item.required}>
                          {item.title}
                        </WriteForm.Label>
                        <WriteForm.Inputs>
                          <FormCkEditor
                            data={isUpdate ? BoardDetails[item.column] : ''}
                            onChange={data => {
                              setValue(item.column, data, {
                                shouldDirty: true,
                              });
                            }}
                          />
                        </WriteForm.Inputs>
                      </WriteForm.Fieldset>
                    );
                  } else {
                    return (
                      <WriteForm.Fieldset key={item.column}>
                        <WriteForm.Label required={item.required}>
                          {item.title}
                        </WriteForm.Label>
                        <WriteForm.Inputs>
                          <TextInput
                            register={register(item.column, {
                              required: item.required
                                ? '필수 입력입니다.'
                                : false,
                            })}
                            errors={errors}
                            placeholder={item.placeholder}
                          />
                        </WriteForm.Inputs>
                      </WriteForm.Fieldset>
                    );
                  }
                })}
            </WriteForm.Layout>
          </Card.Body>
        </Card.Layout>

        {boardData && boardData.info_list_show === true ? (
          <Card.Layout>
            <Card.Header>
              <Card.HeaderTitle>게시물 정보 설정</Card.HeaderTitle>
              <Card.HeaderRequired>필수항목</Card.HeaderRequired>
            </Card.Header>
            <Card.Body>
              <WriteForm.Layout
                method="post"
                onSubmit={handleSubmit(onSubmit, onError)}
              >
                {boardData &&
                  boardData.info_list.map((item, itemIdx) => {
                    if (item.column === 'createdAt') {
                      return (
                        <WriteForm.Fieldset key={item.column}>
                          <WriteForm.Label required={item.required}>
                            {item.title}
                          </WriteForm.Label>
                          <WriteForm.Inputs>
                            <TextInput value={createdAt} readOnly disabled />
                          </WriteForm.Inputs>
                        </WriteForm.Fieldset>
                      );
                    } else {
                      return (
                        <WriteForm.Fieldset key={item.column}>
                          <WriteForm.Label required={item.required}>
                            {item.title}
                          </WriteForm.Label>
                          <WriteForm.Inputs>
                            <TextInput
                              register={register(item.column, {
                                required: item.required
                                  ? '필수 입력입니다.'
                                  : false,
                              })}
                              errors={errors}
                              placeholder={item.placeholder}
                            />
                          </WriteForm.Inputs>
                        </WriteForm.Fieldset>
                      );
                    }
                  })}
              </WriteForm.Layout>
            </Card.Body>
          </Card.Layout>
        ) : null}
      </ContentsContainer>
    </PageLayout>
  );
}
