import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';

import { DELETE_TOKEN } from 'redux/root/slices/token';
import { navToggle } from 'redux/root/slices/nav';
import { openModal } from 'redux/common/slices/modals';

import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'shared/root/styles/theme';

import { ReactComponent as IconProfile } from 'root/assets/imgs/icon_profile.svg';
import { ReactComponent as IconDashboard } from 'root/assets/imgs/icon_dashboard.svg';
import { ReactComponent as IconMember } from 'root/assets/imgs/icon_member.svg';
import { ReactComponent as IconSite } from 'root/assets/imgs/icon_site.svg';
import { ReactComponent as IconBoard } from 'root/assets/imgs/icon_board.svg';
import { ReactComponent as IconSetting } from 'root/assets/imgs/icon_setting.svg';
import iconArrow from 'root/assets/imgs/select_icon_arrow.svg';

import ConfirmModal from 'root/components/Modals/ConfirmModal';

export default function HeaderContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');

  const { admin } = useSelector(state => state.rootUser);
  const [currentUserGroupIdx, setCurrentUserGroupIdx] = useState();
  useEffect(() => {
    admin && setCurrentUserGroupIdx(admin.admin_group.idx);
  }, [admin]);

  const [menuHover, setMenuHover] = useState('');
  const [menuOpen, setMenuOpen] = useState([]);
  const [menuActive, setMenuActive] = useState();

  const { isNavShow } = useSelector(state => state.rootSideNav);

  const [accountPanelShow, setAccountPanelShow] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setAccountPanelShow(false);
      }
    }
    //이벤트 리스너 바인딩
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      //이벤트 리스너 바인딩 해제
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  //페이지 이동 시 네비게이션 숨김처리
  useEffect(() => {
    dispatch(navToggle(false));
  }, [location]);

  useEffect(() => {
    setMenuActive(splitLocation[2]);
  }, []);

  const confirmLogout = () => {
    dispatch(
      openModal({
        Component: ConfirmModal,
        props: {
          message: '로그아웃 하시겠습니까?',
          buttonText: {
            cancel: '취소',
            submit: '확인',
          },
          handleModalSubmit: () => {
            handleLogout();
          },
        },
      }),
    );
  };

  //로그아웃
  const handleLogout = () => {
    dispatch(DELETE_TOKEN());
    localStorage.removeItem('admin_access_token');
    navigate('/root/login');
  };

  //관리자 아이디 클릭 시 정보 패널표시
  const handleClick = () => {
    setAccountPanelShow(true);
  };

  const handleMenuToggle = target => {
    if (target === menuActive) {
      return false;
    } else {
      if (target !== menuOpen) {
        setMenuOpen(target);
      } else {
        setMenuOpen();
      }
    }
  };

  //네비게이션 토글
  const handleNavToggle = () => {
    if (isNavShow) {
      dispatch(navToggle(false));
    } else {
      dispatch(navToggle(true));
    }
  };

  return (
    <header>
      <h1 style={{ display: 'none' }}>소소하자 관리자 사이트</h1>

      <StyledNavbar isNavShow={isNavShow}>
        <AccountItem>
          <AccountIcon onClick={handleClick}>
            <IconProfile />
          </AccountIcon>
          {admin && (
            <AccountName onClick={handleClick}>
              <b>{admin.id}</b> 님
            </AccountName>
          )}
        </AccountItem>
        {accountPanelShow && admin && (
          <AccountPanel ref={wrapperRef}>
            <button
              type="button"
              onClick={() => {
                navigate(
                  `/root/member/admin/details/${currentUserGroupIdx}/${admin.id}`,
                );
              }}
            >
              관리자 정보
            </button>
            <button type="button" onClick={confirmLogout}>
              로그아웃
            </button>
          </AccountPanel>
        )}

        <MenuContainer>
          <MenuGroup>
            <MenuItemLink
              to="/root/dashboard"
              onMouseOver={() => setMenuHover('dashboard')}
              onMouseOut={() => setMenuHover('')}
              onClick={() => handleMenuToggle('dashboard')}
            >
              <MenuIcon>
                <IconDashboard
                  fill={
                    splitLocation[2] === 'dashboard' ||
                    menuHover === 'dashboard'
                      ? '#fff'
                      : '#6c7293'
                  }
                />
              </MenuIcon>
              대시보드
            </MenuItemLink>
          </MenuGroup>

          <MenuGroup>
            <MenuItem
              onMouseOver={() => setMenuHover('member')}
              onMouseOut={() => setMenuHover('')}
              onClick={() => handleMenuToggle('member')}
              className={
                splitLocation[2] === 'member' || menuOpen === 'member'
                  ? 'active'
                  : null
              }
            >
              <MenuIcon>
                <IconMember
                  fill={
                    splitLocation[2] === 'member' || menuHover === 'member'
                      ? '#fff'
                      : '#6c7293'
                  }
                />
              </MenuIcon>
              회원관리
            </MenuItem>
            <SubMenuPanel>
              <SubMenuLink
                to="/root/member/basic/lists"
                className={
                  splitLocation[2] === 'member' && splitLocation[3] === 'basic'
                    ? 'active'
                    : ''
                }
              >
                일반 회원목록
              </SubMenuLink>
              {admin?.admin_group.idx !== 4 && (
                <SubMenuLink
                  to="/root/member/admin/lists"
                  className={
                    splitLocation[2] === 'member' &&
                    splitLocation[3] === 'admin'
                      ? 'active'
                      : ''
                  }
                >
                  관리자 회원목록
                </SubMenuLink>
              )}
              <SubMenuLink to="/root/member/write">회원등록</SubMenuLink>
            </SubMenuPanel>
          </MenuGroup>

          <MenuGroup>
            <MenuItem
              onMouseOver={() => setMenuHover('place')}
              onMouseOut={() => setMenuHover('')}
              onClick={() => handleMenuToggle('place')}
              className={
                splitLocation[2] === 'place' || menuOpen === 'place'
                  ? 'active'
                  : null
              }
            >
              <MenuIcon>
                <IconSite
                  fill={
                    splitLocation[2] === 'place' || menuHover === 'place'
                      ? '#fff'
                      : '#6c7293'
                  }
                />
              </MenuIcon>
              현장관리
            </MenuItem>
            <SubMenuPanel>
              <SubMenuLink
                to="/root/place/lists"
                className={
                  (splitLocation[2] === 'place' &&
                    splitLocation[3] === 'lists') ||
                  (splitLocation[2] === 'place' &&
                    splitLocation[3] === 'details')
                    ? 'active'
                    : ''
                }
              >
                현장목록
              </SubMenuLink>

              {currentUserGroupIdx !== 4 && (
                <SubMenuLink to="/root/place/write">현장등록</SubMenuLink>
              )}
            </SubMenuPanel>
          </MenuGroup>

          <MenuGroup>
            <MenuItem
              onMouseOver={() => setMenuHover('defectsbyplace')}
              onMouseOut={() => setMenuHover('')}
              onClick={() => handleMenuToggle('defectsbyplace')}
              className={
                splitLocation[2] === 'defectsbyplace' ||
                menuOpen === 'defectsbyplace'
                  ? 'active'
                  : null
              }
            >
              <MenuIcon>
                <IconBoard
                  fill={
                    splitLocation[2] === 'defectsbyplace' ||
                    menuHover === 'defectsbyplace'
                      ? '#fff'
                      : '#6c7293'
                  }
                />
              </MenuIcon>
              하자관리
            </MenuItem>
            <SubMenuPanel>
              <SubMenuLink
                to="/root/defectsbyplace/lists"
                className={
                  splitLocation[2] === 'defectsbyplace' ? 'active' : ''
                }
              >
                현장별 하자관리
              </SubMenuLink>
            </SubMenuPanel>
          </MenuGroup>

          {admin?.admin_group.idx !== 4 && (
            <MenuGroup>
              <MenuItem
                onMouseOver={() => setMenuHover('board')}
                onMouseOut={() => setMenuHover('')}
                onClick={() => handleMenuToggle('board')}
                className={
                  splitLocation[2] === 'board' || menuOpen === 'board'
                    ? 'active'
                    : null
                }
              >
                <MenuIcon>
                  <IconBoard
                    fill={
                      splitLocation[2] === 'board' || menuHover === 'board'
                        ? '#fff'
                        : '#6c7293'
                    }
                  />
                </MenuIcon>
                게시판
              </MenuItem>
              <SubMenuPanel>
                <SubMenuLink
                  to="/root/board/lists/1"
                  className={splitLocation[4] === '1' ? 'active' : ''}
                >
                  공지사항
                </SubMenuLink>
                <SubMenuLink
                  to="/root/board/lists/2"
                  className={splitLocation[4] === '2' ? 'active' : ''}
                >
                  자주 묻는 질문
                </SubMenuLink>
              </SubMenuPanel>
            </MenuGroup>
          )}

          {admin?.admin_group.idx !== 4 && (
            <MenuGroup>
              <MenuItem
                onMouseOver={() => setMenuHover('settings')}
                onMouseOut={() => setMenuHover('')}
                onClick={() => handleMenuToggle('settings')}
                className={
                  splitLocation[2] === 'settings' || menuOpen === 'settings'
                    ? 'active'
                    : null
                }
              >
                <MenuIcon>
                  <IconSetting
                    fill={
                      splitLocation[2] === 'settings' ||
                      menuHover === 'settings'
                        ? '#fff'
                        : '#6c7293'
                    }
                  />
                </MenuIcon>
                환경설정
              </MenuItem>
              <SubMenuPanel>
                <SubMenuLink to="/root/settings/info">
                  기본정보 설정
                </SubMenuLink>
                <SubMenuLink to="/root/settings/guide">
                  사용 설명서 설정
                </SubMenuLink>
                <SubMenuLink to="/root/settings/terms">약관 설정</SubMenuLink>
              </SubMenuPanel>
            </MenuGroup>
          )}
        </MenuContainer>
      </StyledNavbar>
      {isNavShow ? <NavDim onClick={handleNavToggle}></NavDim> : null}
    </header>
  );
}

const StyledNavbar = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 272px;
  height: 100%;
  min-height: 100%;
  font-size: 16px;
  color: #fff;
  background: #232c51;
  transform: translateX(-100%);
  z-index: 2000;
  ${theme.mq.xxl} {
    transform: translateX(0);
    /* transition: transform 0.4s; */
  }
  ${props =>
    props.isNavShow &&
    css`
      transform: translateX(0);
    `}
`;
const NavDim = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 1500;
  ${theme.mq.xxl} {
    display: none;
  }
`;
const AccountItem = styled.div`
  position: sticky;
  display: flex;
  align-items: center;
  height: 100px;
  padding: 0 24px;
`;
const AccountIcon = styled.div`
  margin-right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  > * {
    width: 100%;
    height: 100%;
  }
`;
const AccountName = styled.span`
  font-size: 14px;
  color: #a7abc3;
  cursor: pointer;
  b {
    font-weight: 500;
    font-size: 16px;
    color: #fff;
  }
`;
const AccountPanel = styled.div`
  position: relative;
  top: 72px;
  left: 55px;
  position: absolute;
  width: 160px;
  padding: 12px 0;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 3px 6px 0 rgba(35, 44, 81, 0.15);
  background-color: #fff;
  z-index: 1;
  button {
    width: 100%;
    height: 38px;
    padding: 0 20px;
    font-size: 14px;
    line-height: 38px;
    color: #323232;
    text-align: left;
    cursor: pointer;
  }
`;
const MenuContainer = styled.div`
  padding: 0 10px 50px 10px;
  font-size: 14px;
  height: calc(100% - 100px);
  overflow-y: auto;
`;

// 메뉴 그룹
const MenuGroup = styled.div`
  cursor: pointer;
`;

// 대 메뉴
const MenuItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 15px;
  color: #ffffff;
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    width: 16px;
    height: 16px;
    transform: translateY(-50%) rotate(90deg);
    background-image: url(${iconArrow});
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: center right 15px;
  }
  &.active {
    color: #fff;
    &::after {
      transform: translateY(-50%) rotate(-90deg);
    }
    & ~ * {
      max-height: 1000px;
    }
  }
  &:hover {
    color: #fff;
  }
`;
// 대 메뉴 (NavLink사용)
const MenuItemLink = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 15px;
  transition: all 0.2s;
  color: #ffffff;
  &.active {
    background-color: #11193b;
    color: #fff;
  }
`;
// 대 메뉴 아이콘
const MenuIcon = styled.div`
  margin-right: 15px;
  width: 20px;
  height: 20px;
  > * {
    //아이콘 이미지 호버
    transition: 0.2s;
  }
`;
// 서브메뉴 영역
const SubMenuPanel = styled.div`
  cursor: pointer;
  max-height: 0;
  overflow: hidden;
  .MenuItem.active & {
    max-height: 1000px;
  }
`;
// 서브메뉴
const SubMenuLink = styled(NavLink)`
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 4px;
  padding: 0 10px 0 50px;
  color: #a7abc3;
  transition: all 0.2s;
  &.active {
    background-color: #11193b;
    color: #fff;
  }
`;
