// 공통 - '전체'옵션 추가
function addExpandOption(data) {
  return [{ value: '', label: '전체' }, ...data];
}

// 하자 작업상태
export const WorkStatusData = [
  { value: 'before_work', label: '작업전' },
  { value: 'working', label: '작업중' },
  { value: 'completed_work', label: '작업완료' },
];

// 하자유형
export const DefectTypeData = [
  { value: 'speckled', label: '반점' },
  { value: 'pollution', label: '오염' },
  { value: 'damage', label: '훼손(찢김, 찍힘, 뜯김 등)' },
  { value: 'duster', label: '걸레받이수정' },
  { value: 'molding', label: '몰딩수정' },
  { value: 'gypsum', label: '석고수정' },
  { value: 'door_frame', label: '문틀수정' },
  { value: 'air_conditioner', label: '에어컨수정' },
  { value: 'furniture', label: '가구수정' },
  { value: 'window_frame', label: '창틀수정' },
  { value: 'cotton_defect', label: '면불량' },
  { value: 'mold', label: '곰팡이' },
  { value: 'err_hole', label: '오타공' },
  { value: 'orther', label: '기타' },
];

// 하자 작업방법
export const WorkMethodData = [
  { value: 'care', label: '손보기' },
  { value: 'replacement', label: '교체' },
];

export const expandDefectTypeData = addExpandOption(DefectTypeData);
