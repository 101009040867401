import styled from '@emotion/styled';
import { theme } from 'shared/styles/theme';
import { css } from '@emotion/react';

import Button from './common/Button';
import Spinners from './common/Spinners';
// import Modal from './common/modal.css';

// area
const Area = styled.div`
  width: 100%;
  max-width: 414px;
  margin: 0 auto;
  padding: 0 ${theme.gutter.mobile};
`;

const Baseline = styled.hr`
  height: 14px;
  border: none;
  margin: 0;
  background-color: #f2f5f6;
`;

const ErrorMessage = styled.p`
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 500;
  color: ${theme.colors.valid};
  ${props =>
    props.custom &&
    css`
      margin: 0;
    `}
`;

// form
const FormField = styled.div`
  display: flex;
  margin-bottom: 15px;
  &.hidden {
    display: none;
  }
  ${props =>
    props.gap &&
    css`
      margin-bottom: ${props.gap};
    `}
  ${props =>
    props.align === 'vertical' &&
    css`
      flex-direction: column;
      row-gap: 10px;
      label {
        width: 100%;
      }
    `}
`;

const FieldLabel = styled.label`
  flex: none;
  width: 88px;
  padding-top: 12px;
  font-size: 15px;
  line-height: 1.4;
  color: #444;
`;

const FieldContainer = styled.div`
  flex: 1;
  min-height: 48px;
  input {
    width: 100%;
  }
  select option[value=''][disabled] {
    display: none;
  }
`;

const FieldFlex = styled.div`
  display: flex;
  margin: 0 -2px;
  > * {
    margin: 0 2px;
  }
`;

const CommonStyledComponents = {
  Area,
  Button,
  // Modal,
  Baseline,
  ErrorMessage,
  //form
  FormField,
  FieldLabel,
  FieldContainer,
  FieldFlex,
  Spinners,
};
export default CommonStyledComponents;
