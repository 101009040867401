import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import ReactMoment from 'react-moment';

import { getUsersList, getAdminUsersList } from 'redux/root/slices/user';
import { getPlaceDefectList } from 'redux/root/slices/defect';

import PageLayout from 'root/components/PageLayout';
import TitleContainer from 'root/components/TitleContainer';
import ContentsContainer from 'root/components/ContentsContainer';
import NoResult from 'root/components/NoResult';

import styled from '@emotion/styled';
import { css } from '@emotion/react';
import CommonStyledComponents from 'shared/root/styles/CommonStyledComponents';
const { Card, Table, Button } = CommonStyledComponents;

export default function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const UsersList = useSelector(state => state.rootUser.usersList);
  const AdminUsersList = useSelector(state => state.rootUser.adminUsersList);

  const tableLists = useSelector(state => state.rootDefect.placeDefectList);

  const takeNum = 10; // 최근 현장 목록 게시물 최신순으로 10개까지만
  const [totalNum, setTotalNum] = useState(0);
  const [userTotalNum, setUserTotalNum] = useState(0);
  const [adminTotalNum, setAdminTotalNum] = useState(0);

  const { admin } = useSelector(state => state.rootUser);

  useEffect(() => {
    dispatch(
      getUsersList({
        takeNum: 1,
        activePage: 1,
      }),
    );
    dispatch(
      getAdminUsersList({
        takeNum: 1,
        activePage: 1,
      }),
    );
    dispatch(
      getPlaceDefectList({
        take: takeNum,
        page: 1,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    UsersList.total && setUserTotalNum(UsersList.total);
    AdminUsersList.total && setAdminTotalNum(AdminUsersList.total);
    userTotalNum && adminTotalNum && setTotalNum(userTotalNum + adminTotalNum);
  }, [userTotalNum, adminTotalNum, UsersList.total, AdminUsersList.total]);

  return (
    <PageLayout>
      <TitleContainer>
        <h2 className={'active'}>
          <span>대시보드</span>
        </h2>
      </TitleContainer>

      <ContentsContainer>
        <SimplifyCardContainer>
          {admin?.admin_group.idx !== 4 && (
            <>
              <SimplifyCard>
                <dt>총 회원</dt>
                <dd>
                  <em>{totalNum}</em>명
                </dd>
              </SimplifyCard>
              <SimplifyCard
                hasLink
                onClick={() => {
                  navigate('/root/member/admin/lists');
                }}
              >
                <dt>관리자</dt>
                <dd>
                  <em>{adminTotalNum}</em>명
                </dd>
              </SimplifyCard>
            </>
          )}
          <SimplifyCard
            hasLink
            onClick={() => {
              navigate('/root/member/basic/lists');
            }}
            style={{
              maxWidth: '35%',
            }}
          >
            <dt>일반 회원</dt>
            <dd>
              <em>{userTotalNum}</em>명
            </dd>
          </SimplifyCard>
        </SimplifyCardContainer>

        <Card.Layout
          styled={css`
            overflow: hidden;
          `}
        >
          <Card.Header>
            <Card.HeaderTitle>최근 현장 목록</Card.HeaderTitle>
          </Card.Header>
          <Card.Body>
            <Table.Container layout={'fixed'}>
              <Table.Layout>
                <colgroup>
                  <col style={{ width: '12.5%' }} />
                  <col style={{ width: '54.5%' }} />
                  <col style={{ width: '54.5%' }} />
                  <col style={{ width: '54.5%' }} />
                  <col style={{ width: '17.5%' }} />
                </colgroup>
                <thead>
                  <Table.Tr>
                    <Table.Th type={'num'}>번호</Table.Th>
                    <Table.Th>현장명</Table.Th>
                    <Table.Th>하자개수</Table.Th>
                    <Table.Th>등록일</Table.Th>
                    <Table.Th type={'more'}>관리</Table.Th>
                  </Table.Tr>
                </thead>
                <tbody>
                  {tableLists.length === 0 ? (
                    <Table.Tr noHover>
                      <Table.Td colSpan="6">
                        <NoResult />
                      </Table.Td>
                    </Table.Tr>
                  ) : (
                    tableLists.results.map((item, itemIndex) => (
                      <Table.Tr key={itemIndex}>
                        <Table.Td type={'num'}>{takeNum - itemIndex}</Table.Td>
                        <Table.Td>
                          <Link to={`/root/defectsbyplace/${item.idx}/lists`}>
                            {item.name}
                          </Link>
                        </Table.Td>
                        <Table.Td>{item.defect_cnt}건</Table.Td>
                        <Table.Td>
                          <ReactMoment
                            date={item.createdAt}
                            format="YYYY-MM-DD&nbsp;&nbsp;HH:mm:ss"
                          />
                        </Table.Td>
                        <Table.Td type={'more'}>
                          <Button
                            theme={'underline'}
                            size={'sm'}
                            onClick={() => {
                              navigate(
                                `/root/defectsbyplace/${item.idx}/lists`,
                              );
                            }}
                          >
                            상세보기
                          </Button>
                        </Table.Td>
                      </Table.Tr>
                    ))
                  )}
                </tbody>
              </Table.Layout>
            </Table.Container>
          </Card.Body>
        </Card.Layout>
      </ContentsContainer>
    </PageLayout>
  );
}

const SimplifyCardContainer = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 25px;
`;
const SimplifyCard = styled.dl`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  padding: 0 25px;
  height: 84px;
  border-radius: 4px;
  background-color: #fff;
  overflow: hidden;
  box-shadow: 0 0 10px 0 rgba(35, 44, 81, 0.05);
  cursor: default;
  dt {
    flex: none;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.38;

    color: #323232;
  }
  dd {
    flex: 1 1 0;
    font-size: 20px;
    line-height: 1.1;
    color: #323232;
    text-align: right;
    em {
      margin-right: 5px;
      font-weight: 700;
      color: #0b6fe8;
    }
  }
  ${props =>
    props.hasLink &&
    css`
      cursor: pointer;
      &:hover {
        dd {
          em {
            position: relative;
            &:after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 1px;
              background-color: #0b6fe8;
            }
          }
        }
      }
    `}
`;
