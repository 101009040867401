import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { loginAdmin, getAdminProfile } from 'api/user';
import { SET_TOKEN } from 'redux/root/slices/token';
import { SET_ADMIN } from 'redux/root/slices/user';

import TextInput from 'components/Form/TextInput';

import logoIcon from 'assets/imgs/logo_login.svg';

import styled from '@emotion/styled';
import CommonStyledComponents from 'shared/root/styles/CommonStyledComponents';
import { css } from '@emotion/react';
import {
  PASSWORD_REGEXP,
  PASSWORD_VALID_MESSAGE,
} from 'shared/constants/constants';
const { Area, Button } = CommonStyledComponents;

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {},
  });

  const onSubmit = async ({ id, password }) => {
    // input 태그 값 비우기
    setValue('password', '');

    const accessToken = await loginAdmin({ id, password });
    if (accessToken) {
      localStorage.setItem('admin_access_token', accessToken);
      dispatch(SET_TOKEN(accessToken));
      getAdminProfile()
        .then(response => {
          dispatch(SET_ADMIN(response));
        })
        .catch(e => {
          console.log('로그인에러', e);
        });
      return navigate('/root');
    }
  };

  const onError = error => {
    console.log(error);
  };

  return (
    <LoginContainer>
      <Area>
        <FormContainer>
          <LoginLogo>
            <img src={logoIcon} alt="소소하자" />
          </LoginLogo>
          <form method="post" onSubmit={handleSubmit(onSubmit, onError)}>
            <FormField gap={'8px'}>
              <FieldContainer>
                <TextInput
                  register={register('id', {
                    required: '아이디를 입력해주세요.',
                    maxLength: {
                      value: 16,
                      message: '16자 이하로만 작성이가능합니다.',
                    },
                  })}
                  errors={errors}
                  placeholder="아이디"
                />
              </FieldContainer>
            </FormField>
            <FormField>
              <FieldContainer>
                <TextInput
                  type="password"
                  register={register('password', {
                    required: '비밀번호를 입력해주세요.',
                    minLength: {
                      value: 8,
                      message: PASSWORD_VALID_MESSAGE,
                    },
                    maxLength: {
                      value: 12,
                      message: PASSWORD_VALID_MESSAGE,
                    },
                    pattern: {
                      value: PASSWORD_REGEXP,
                      message: PASSWORD_VALID_MESSAGE,
                    },
                  })}
                  errors={errors}
                  placeholder="비밀번호"
                />
              </FieldContainer>
            </FormField>
            <ButtonContainer>
              <Button type="submit" size={'xl'} theme={'point'}>
                로그인
              </Button>
            </ButtonContainer>
          </form>
        </FormContainer>
      </Area>
    </LoginContainer>
  );
}

const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f6f7f9;
  height: 100vh;
`;

const FormField = styled.div`
  display: flex;
  margin-bottom: 15px;
  &.hidden {
    display: none;
  }
  ${props =>
    props.gap &&
    css`
      margin-bottom: ${props.gap};
    `}
`;

const FieldContainer = styled.div`
  flex: 1;
  min-height: 48px;
  input {
    width: 100%;
  }
  select option[value=''][disabled] {
    display: none;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 380px;
`;
const LoginLogo = styled.div`
  margin-bottom: 40px;
  text-align: center;
  img {
    width: 140px;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 20px;
`;
