import { useState, useRef, useCallback } from 'react';

import PageLayout from 'components/PageLayout';
import { InfiniteScrollBoardList } from 'api/board';

import IconArrow from 'assets/imgs/icon_arrow.svg';

import styled from '@emotion/styled';
import CommonStyledComponents from 'shared/styles/CommonStyledComponents';
const { Spinners } = CommonStyledComponents;

export default function Faq() {
  const [pageNumber, setPageNumber] = useState(1);
  const [menuOpen, setMenuOpen] = useState('');

  const handleToggle = idx => {
    setMenuOpen(idx);
    if (idx === menuOpen) {
      setMenuOpen('');
    } else {
      setMenuOpen(idx);
    }
  };

  const { lists, hasMore, loading } = InfiniteScrollBoardList({
    bdIdx: 2,
    page: pageNumber,
    take: 10,
  });

  const observer = useRef();
  

  const callbackRef = useCallback(
    node => {
      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber(prevPageNumber => prevPageNumber + 1);
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [hasMore],
  );

  return (
    <PageLayout title="자주 묻는 질문" backBtn>
      {loading ? (
        <Spinners loading></Spinners>
      ) : lists.length === 0 ? (
        <p>게시물이 없습니다.</p>
      ) : (
        lists.map((list, index) => (
          <FaqItem
            key={list.idx}
            ref={callbackRef}
            className={menuOpen === list.idx ? 'active' : null}
          >
            <FaqTitle
              className={menuOpen === list.idx ? 'active' : null}
              onClick={() => {
                handleToggle(list.idx);
              }}
            >
              <FaqTitleInner>{list.title}</FaqTitleInner>
            </FaqTitle>
            <FaqAnswer className={menuOpen === list.idx ? 'active' : null}>
              <FaqAnswerInner
                className={'ck-content'}
                dangerouslySetInnerHTML={{ __html: list.content }}
              ></FaqAnswerInner>
            </FaqAnswer>
          </FaqItem>
        ))
      )}
    </PageLayout>
  );
}

const FaqItem = styled.div`
  position: relative;
  &:first-of-type {
    margin-top: -20px;
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #ddd;
  }
`;
const FaqTitle = styled.div`
  position: relative;
  border-bottom: 1px solid #ddd;
  padding: 20px 20px 20px 0;

  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    display: block;
    width: 12px;
    height: 12px;
    background: url(${IconArrow}) no-repeat center / contain;
  }
  &.active {
    display: block;
  }
`;
const FaqTitleInner = styled.div`
  padding: 0 18px;
  font-size: 16px;
  line-height: 1.5;
`;
const FaqAnswer = styled.div`
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 0;
  background: #f6f8f9;
  transition: max-height 1s cubic-bezier(0, 1, 0, 1);
  &.active {
    display: block;
    max-height: 1000px;
    transition: max-height 1s;
  }
`;

const FaqAnswerInner = styled.div`
  padding: 20px 18px;
  font-size: 16px;
  line-height: 1.5;
  color: #444;
`;
