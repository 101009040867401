import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  name: '',
  selectedValue: '',
};

const selectionModal = createSlice({
  name: 'selectionModal',
  initialState,
  reducers: {
    ChangeData: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { ShowSheet, CloseSheet, ChangeData } = selectionModal.actions;

export default selectionModal.reducer;
