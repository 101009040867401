import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import { AdminGroupOptions } from 'shared/root/optionData';
import { getPlaceList } from 'redux/root/slices/place';
import { getUserDetails } from 'api/user';

import { setOptions } from 'utils';

import { updateUsers } from 'api/user';

import PageLayout from 'root/components/PageLayout';
import TitleContainer from 'root/components/TitleContainer';
import ContentsContainer from 'root/components/ContentsContainer';
import TextInput from 'root/components/Form/TextInput';
import Textarea from 'root/components/Form/Textarea';

import ConfirmModal from 'root/components/Modals/ConfirmModal';
import ToastMessage from 'root/components/Modals/ToastMessage';
import { openModal } from 'redux/common/slices/modals';

import CommonStyledComponents from 'shared/root/styles/CommonStyledComponents';
import { CustomSelect, styleBox } from 'shared/root/styles/common/Select';
import {
  PASSWORD_REGEXP,
  PASSWORD_VALID_MESSAGE,
} from 'shared/constants/constants';
const { Card, WriteForm, Button, ButtonsGrid } = CommonStyledComponents;

export default function MemberUpdate() {
  console.log('components: MemberUpdate.js');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams();
  const { memId } = params;
  const { admin } = useSelector(state => state.rootUser);

  const placeList = useSelector(state => state.place.placeList).results; //현장모든목록
  const isAdmin = params['*'].split('/')[1] === 'admin' ? true : false;

  const [changePassword, setChangePassword] = useState(false);
  const initialValues = {
    id: '',
    name: '',
    status: '2',
    email: '',
    phone: '',
    memo: '',
    password: '',
    type: 'default',
    group: '',
    place_idx: '',
  };

  const {
    register,
    setValue,
    setFocus,
    watch,
    handleSubmit,
    reset,
    control,
    formState: { errors, dirtyFields, isDirty },
  } = useForm({
    defaultValues: initialValues,
  });

  //회원등록
  const onSubmit = data => {
    console.log(' data : ', data);

    submitUsers(data);
  };

  //일반회원 등록
  function submitUsers(data) {
    const {
      idx,
      status,
      group,
      place_idx,
      user_group,
      admin_group,
      updatedAt,
      createdAt,
      ...restData
    } = data;
    const statusValue = String(status);
    const groupValue =
      isAdmin && Object.keys(group).length > 0 ? group.value : group;
    const placeValue = String(
      Object.keys(place_idx).length > 0 ? place_idx.value : place_idx,
    );
    //셀렉트선택 객체 value값 분리
    const newData = {
      ...restData,
      status: statusValue,
      group: groupValue,
      place_idx: placeValue,
    };

    // 탈퇴시에만 leave_reason 담아서 요청보내기
    delete newData.leave_reason;

    dispatch(
      openModal({
        Component: ConfirmModal,
        props: {
          message: '변경된 내용을 저장하시겠습니까?',
          handleModalSubmit: () => {
            updateUsers({
              data: newData,
              id: memId,
              type: isAdmin ? 'admin' : null,
            })
              .then(response => {
                isAdmin
                  ? navigate('/root/member/admin/lists')
                  : navigate('/root/member/basic/lists');
                dispatch(
                  openModal({
                    Component: ToastMessage,
                    props: {
                      iconImg: 'complete',
                      message: '회원정보가 수정되었습니다.',
                    },
                  }),
                );
              })
              .catch(error => {
                dispatch(
                  openModal({
                    Component: ToastMessage,
                    props: {
                      isOpen: false,
                      iconImg: 'fail',
                      message: error.message, //'실패했습니다',
                    },
                  }),
                );
              });
          },
        },
      }),
    );
  }

  const onError = data => {};

  //저장버튼 클릭 form전송
  function handleClickSubmit(data) {
    onSubmit(data);
  }
  //회원 비밀번호 수정
  function handleChangePassword() {
    dispatch(
      openModal({
        Component: ConfirmModal,
        props: {
          message: '비밀번호를 변경하시겠습니까?',
          handleModalSubmit: () => {
            setChangePassword(true);
          },
        },
      }),
    );
  }

  useEffect(() => {
    if (!isAdmin) {
      //관리자 담당현장 항목 리셋
      setValue('place_idx', '');
    } else {
      //현장항목 데이터 불러오기
      dispatch(getPlaceList(1));
    }
    getUserDetails({ id: memId, type: isAdmin ? 'admin' : null }).then(res => {
      if (res === undefined || res.status === 0) {
        navigate('/root/notfound', { replace: true });
      } else {
        reset(res);
        if (isAdmin) {
          // 그룹명
          setValue(
            'group',
            AdminGroupOptions[
              AdminGroupOptions.findIndex(
                obj =>
                  obj.value ===
                  String(isAdmin ? res.admin_group.idx : res.user_group.idx),
              )
            ],
          );
        } else {
          setValue('group', '3');
        }
      }
    });
  }, [dispatch]);

  //비밀번호 변경 input 포커스
  useEffect(() => {
    if (changePassword === true) {
      setFocus('password');
    }
  }, [changePassword, setFocus]);

  return (
    <PageLayout>
      <TitleContainer>
        <h2>
          <span>회원관리</span>
          <span>{isAdmin ? '관리자' : '일반'} 회원목록</span>
          <b>상세</b>
        </h2>
        <ButtonsGrid>
          <Button
            type="submit"
            theme={'point'}
            size={'md'}
            onClick={handleSubmit(d => handleClickSubmit(d))}
            status={Object.keys(dirtyFields).length > 0 ? '' : 'disabled'}
          >
            저장
          </Button>
        </ButtonsGrid>
      </TitleContainer>

      <ContentsContainer>
        <Card.Layout>
          <Card.Header>
            <Card.HeaderTitle>
              {isAdmin ? '관리자 회원정보' : '일반 회원정보'}
            </Card.HeaderTitle>
            <Card.HeaderRequired>필수항목</Card.HeaderRequired>
          </Card.Header>
          <Card.Body>
            <WriteForm.Layout
              method="post"
              onSubmit={handleSubmit(onSubmit, onError)}
            >
              <WriteForm.Fieldset>
                <WriteForm.Label>그룹명</WriteForm.Label>
                {isAdmin ? (
                  /*
                    최고관리자(idx:1)만 그룹 수정이 가능함.
                    관리자 로그인 막을경우 위의 예외처리 불필요
                  */
                  admin && admin.admin_group.idx === 1 ? (
                    <WriteForm.Inputs>
                      <Controller
                        name="group"
                        control={control}
                        render={({ field }) => (
                          <CustomSelect
                            {...field}
                            styles={styleBox}
                            width={'400px'}
                            isSearchable={false}
                            options={AdminGroupOptions}
                            defaultValue={
                              AdminGroupOptions[
                                AdminGroupOptions.findIndex(
                                  obj => obj.value === 2, // 2 = 초기값 일반사용자로 설정
                                )
                              ]
                            }
                          />
                        )}
                      />
                    </WriteForm.Inputs>
                  ) : (
                    <WriteForm.Text>{watch().group.label}</WriteForm.Text>
                  )
                ) : (
                  <WriteForm.Text>일반회원</WriteForm.Text>
                )}
              </WriteForm.Fieldset>
              <WriteForm.Fieldset>
                <WriteForm.Label required>회원 아이디</WriteForm.Label>
                <WriteForm.Inputs>
                  <TextInput
                    register={register('id', {
                      required: '필수 입력입니다.',
                      maxLength: {
                        value: 16,
                        message: '16자 이하로만 작성이가능합니다.',
                      },
                    })}
                    errors={errors}
                    placeholder="회원 아이디를 입력하세요"
                    maxWidth={'400px'}
                    disabled
                    readOnly
                  />
                </WriteForm.Inputs>
              </WriteForm.Fieldset>
              <WriteForm.Fieldset>
                <WriteForm.Label required>회원 비밀번호</WriteForm.Label>
                <WriteForm.Inputs>
                  {!changePassword ? (
                    <Button
                      type="button"
                      theme={'outline-default'}
                      size={'md'}
                      onClick={handleChangePassword}
                    >
                      비밀번호 변경
                    </Button>
                  ) : (
                    <TextInput
                      register={register('password', {
                        required: '필수 입력입니다.',
                        minLength: {
                          value: 8,
                          message: PASSWORD_VALID_MESSAGE,
                        },
                        maxLength: {
                          value: 12,
                          message: PASSWORD_VALID_MESSAGE,
                        },
                        pattern: {
                          value: PASSWORD_REGEXP,
                          message: PASSWORD_VALID_MESSAGE,
                        },
                      })}
                      errors={errors}
                      placeholder={
                        changePassword ? '회원 비밀번호를 입력하세요' : ''
                      }
                      maxWidth={'400px'}
                      disabled={!changePassword ? true : false}
                    />
                  )}
                </WriteForm.Inputs>
              </WriteForm.Fieldset>
              <WriteForm.Fieldset>
                <WriteForm.Label>담당현장</WriteForm.Label>
                <WriteForm.Inputs>
                  <Controller
                    name="place_idx"
                    control={control}
                    render={({ field }) => (
                      <CustomSelect
                        {...field}
                        styles={styleBox}
                        width={'400px'}
                        isSearchable={false}
                        isDisabled={
                          //일반회원 선택 시 disabled 해제
                          isAdmin ? true : false
                        }
                        options={setOptions(placeList)}
                        value={
                          watch().place_idx && placeList
                            ? setOptions(placeList)[
                                setOptions(placeList).findIndex(
                                  obj => obj.value === watch().place_idx,
                                )
                              ]
                            : null
                        }
                        placeholder="-"
                      />
                    )}
                  />
                </WriteForm.Inputs>
              </WriteForm.Fieldset>
              <WriteForm.Fieldset>
                <WriteForm.Label required>회원 이름</WriteForm.Label>
                <WriteForm.Inputs>
                  <TextInput
                    register={register('name', {
                      required: '필수 입력입니다.',
                    })}
                    errors={errors}
                    placeholder="회원 이름을 입력하세요"
                    maxWidth={'400px'}
                  />
                </WriteForm.Inputs>
              </WriteForm.Fieldset>
              <WriteForm.Fieldset>
                <WriteForm.Label required>이메일</WriteForm.Label>
                <WriteForm.Inputs>
                  <TextInput
                    register={register('email', {
                      required: '필수 입력입니다.',
                      pattern: {
                        value:
                          /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/,
                        message: '올바른 이메일 주소만 입력 가능합니다',
                      },
                    })}
                    errors={errors}
                    placeholder="이메일을 입력하세요"
                    maxWidth={'400px'}
                  />
                </WriteForm.Inputs>
              </WriteForm.Fieldset>
              <WriteForm.Fieldset>
                <WriteForm.Label>연락처</WriteForm.Label>
                <WriteForm.Inputs>
                  <TextInput
                    register={register('phone', {
                      pattern: {
                        value: /^[0-9]*$/,
                        message: '숫자만 입력 가능합니다',
                      },
                    })}
                    errors={errors}
                    placeholder="연락처를 입력하세요"
                    maxWidth={'400px'}
                  />
                </WriteForm.Inputs>
              </WriteForm.Fieldset>
              <WriteForm.Fieldset>
                <WriteForm.Label>메모</WriteForm.Label>
                <WriteForm.Inputs>
                  <Textarea
                    register={register('memo')}
                    errors={errors}
                    placeholder="메모를 입력하세요"
                  />
                </WriteForm.Inputs>
              </WriteForm.Fieldset>
            </WriteForm.Layout>
          </Card.Body>
        </Card.Layout>
      </ContentsContainer>
    </PageLayout>
  );
}
