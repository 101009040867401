import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from 'axios';

import { API_PLACE, TOKEN } from 'shared/constants/constants';
import { getLocalStorage } from 'utils';

// 관리자_현장 리스트
export const getPlaceList = createAsyncThunk(
  'auth/getPlaceList',
  async (PageData, thunkAPI) => {
    const { take, page } = PageData;
    try {
      const response = await axios.get(
        `${API_PLACE}?take=${take}&page=${page}`
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

// 관리자_현장 정보
export const getPlaceDetails = createAsyncThunk(
  'getPlaceDetails',
  async (placeIdx, thunkAPI) => {
    try {
      const response = await axios.get(`${API_PLACE}/${placeIdx}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

const placeSlice = createSlice({
  name: 'place',
  initialState: {
    placeList: [],
    placeDetails: [],
  },
  reducers: {},
  extraReducers: {
    [getPlaceList.fulfilled]: (state, action) => {
      state.placeList = action.payload;
    },
    [getPlaceDetails.fulfilled]: (state, action) => {
      state.placeDetails = action.payload;
    },
    [getPlaceList.rejected]: (state, action) => {},
  },
});

export default placeSlice.reducer;
