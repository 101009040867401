import styled from '@emotion/styled';
import { theme } from 'shared/styles/theme';
import CommonStyledComponents from 'shared/styles/CommonStyledComponents';
import Modal from 'react-modal';

const { Button } = CommonStyledComponents;

export default function PermissionModal({ isOpen, modalClose, modalSubmit }) {
  return (
    <Modal
      isOpen
      style={customStyles}
      onRequestClose={modalClose}
      shouldCloseOnOverlayClick={false}
    >
      <StyledPermissionModal>
        <div>
          <h2>
            소소하자 이용을 위해
            <br />
            접근권한의 허용이 필요합니다.
          </h2>
          <dl>
            <dt>
              카메라<span>(선택)</span>
            </dt>
            <dd>하자 등록 시 사진 촬영</dd>
          </dl>
          <dl>
            <dt>
              사진<span>(선택)</span>
            </dt>
            <dd>하자 등록 시 사진 선택 및 저장</dd>
          </dl>
          <p>
            사진 전체 접근을 허용해야 정보가 입력된 사진을 저장할 수 있습니다.
            허용하지 않아도 소소하자 서비스를 이용할 수 있으나 일부 기능이
            제한됩니다.
          </p>
        </div>
        <Button
          type="submit"
          size={'full-md'}
          theme={'point'}
          style={{
            borderRadius: '0px',
          }}
          onClick={() => {
            modalSubmit();
          }}
        >
          확인
        </Button>
      </StyledPermissionModal>
    </Modal>
  );
}

const customStyles = {
  overlay: {
    position: 'fixed',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: '10000',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: 'calc(100% - 30px)',
    maxWidth: '290px',
    padding: '0',
    borderRadius: '4px',
    transform: 'translate(-50%, -50%)',
    border: 'none',
  },
};

const StyledPermissionModal = styled.div`
  div {
    padding: 38px 30px 22px;
  }
  h2 {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 24px;
    text-align: center;
  }
  dl {
    display: flex;
    font-size: 14px;
    line-height: 1.57;
    & + dl {
      margin-top: 10px;
    }
    dt {
      flex: none;
      font-weight: 500;
      margin-right: 6px;
      span {
        color: ${theme.colors.sub};
        margin-left: 2px;
      }
    }
  }
  p {
    font-size: 12px;
    line-height: 1.5;
    margin-top: 20px;
    padding-top: 25px;
    border-top: 1px solid ${theme.colors.divider};
    color: ${theme.colors.sub};
  }
`;
