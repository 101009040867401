import PageLayout from 'components/PageLayout';

import { useLiveQuery } from 'dexie-react-hooks';

import { useDispatch, useSelector } from 'react-redux';

import styled from '@emotion/styled';
import { theme } from 'shared/styles/theme';
import CommonStyledComponents from 'shared/styles/CommonStyledComponents';

import { useNavigate, useParams } from 'react-router-dom';

import { openModal } from 'redux/common/slices/modals';
import ConfirmModal from 'components/Modals/ConfirmModal';

import InfoTable from './components/PhotoInfoTable';
import DetailsTable from './components/DetailsTable';

import { db } from 'db';

const { Area } = CommonStyledComponents;
const { defects } = db;

export default function DefectDetails() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const dftIdx = Number(params.dftIdx);
  const userPlaceDetails = useSelector(state => state.place.placeDetails.place);

  // 현재 id에 해당하는 하자 정보
  const dftInfo = useLiveQuery(
    () => defects.where('id').equals(dftIdx).toArray(),
    [],
  );

  const dft_data = dftInfo && dftInfo[0].data;
  const dft_submit_status = dftInfo && dftInfo[0].submit_status;
  const dft_id = dftInfo && dftInfo[0].id;

  // 하자 개별 삭제
  const deleteDefectItem = async (id, submit_status) => {
    if (submit_status) {
      // 전송완료 항목인 경우
      defects.delete(id);
      navigate(-1);
    } else {
      // 전송실패 항목인 경우
      dispatch(
        openModal({
          Component: ConfirmModal,
          props: {
            message: '전송되지 않은 내용입니다.\n정말로 삭제하시겠습니까?',
            buttonText: {
              cancel: '취소',
              submit: '삭제',
            },
            handleModalSubmit: () => {
              navigate(-1);
              defects.delete(id);
            },
          },
        }),
      );
    }
  };

  return (
    <PageLayout
      title="상세보기"
      backBtn
      deleteBtn={() => deleteDefectItem(dft_id, dft_submit_status)}
    >
      {dftInfo && dft_data ? (
        <Contents>
          <Area>
            <SubmitStatus>
              {dftInfo[0].submit_status === 0 ? (
                <Fail>전송 실패</Fail>
              ) : (
                <p>전송 완료</p>
              )}
            </SubmitStatus>
            <SiteName>{userPlaceDetails && userPlaceDetails.name}</SiteName>
          </Area>

          <DetailsTable data={dft_data} />

          <Area>
            <PhotoItem>
              <PhotoTitle>사진</PhotoTitle>
              <img src={dft_data.base64} alt="" />
              {dft_data.photourl && (
                <InfoTable
                  placeName={userPlaceDetails && userPlaceDetails.name}
                  watchData={dft_data}
                  resize={true}
                />
              )}
            </PhotoItem>
          </Area>
        </Contents>
      ) : (
        <Area>해당 하자 정보가 없습니다.</Area>
      )}
    </PageLayout>
  );
}

const Contents = styled.div`
  padding-bottom: 60px;
`;
const SubmitStatus = styled.div`
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  color: ${theme.colors.point};
`;
const Fail = styled.p`
  color: ${theme.colors.valid};
`;
const SiteName = styled.h1`
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.25;
`;
const PhotoItem = styled.div`
  position: relative;
  padding-top: 20px;
  min-height: 220px;
  img {
    display: block;
    width: 100%;
  }
`;
const PhotoTitle = styled.p`
  margin-bottom: 10px;
  font-size: 15px;
  color: #444;
`;
