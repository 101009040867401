import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { getSettings } from 'redux/root/slices/settings';

import CommonStyledComponents from 'shared/styles/CommonStyledComponents';

import PageLayout from 'components/PageLayout';

const { Area } = CommonStyledComponents;

export default function Tos() {
  const dispatch = useDispatch();
  const { terms_tos } = useSelector(state => state.settings.settingInfo);

  useEffect(() => {
    dispatch(getSettings('terms'));
  }, [dispatch]);

  return (
    <PageLayout title="이용약관" backBtn>
      <Area>
        {terms_tos ? (
          <div
            className={'ck-content'}
            dangerouslySetInnerHTML={{ __html: terms_tos.value }}
          ></div>
        ) : null}
      </Area>
    </PageLayout>
  );
}
