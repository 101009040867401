import styled from '@emotion/styled';
import { theme } from 'shared/styles/theme';
import CommonStyledComponents from 'shared/styles/CommonStyledComponents';
const { ErrorMessage } = CommonStyledComponents;

export default function RadioInputs(props) {
  const name = props.register.name;

  return (
    <>
      <StyledRadioInputs>
        {props.data.map((item, index) => {
          return (
            <label key={index}>
              <input
                type="radio"
                value={item.value}
                name={name}
                {...props.register}
              />
              {item.label}
            </label>
          );
        })}
      </StyledRadioInputs>
      {props.errors && props.errors[name] && props.errors[name].message && (
        <ErrorMessage>{props.errors[name].message}</ErrorMessage>
      )}
    </>
  );
}

const StyledRadioInputs = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: auto;
  margin: 6px -8px;
  label {
    display: inline-flex;
    align-items: center;
    padding: 6px 8px;
    cursor: pointer;
  }
  input[type='radio'] {
    position: relative;
    border: 1px solid ${theme.colors.placeholder};
    width: 20px;
    height: 20px;
    margin-right: 6px;
    border-radius: 50%;
    cursor: pointer;
    &:checked {
      background-color: ${theme.colors.point};
      border-color: ${theme.colors.point};
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #fff;
        transform: translate(-50%, -50%);
      }
    }
  }
`;
