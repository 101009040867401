import Modal from 'react-modal';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

import ConfirmModal from 'root/components/Modals/ConfirmModal';
import ToastMessage from 'root/components/Modals/ToastMessage';
import { openModal } from 'redux/common/slices/modals';

import { addDefectPlace, getDefectPlace, UpdateDefectPlace } from 'api/defect';

import closeIcon from 'root/assets/imgs/icon_close.svg';

import styled from '@emotion/styled';
import CommonStyledComponents from 'shared/root/styles/CommonStyledComponents';
import TextInput from 'root/components/Form/TextInput';
import Textarea from 'root/components/Form/Textarea';

const { Button } = CommonStyledComponents;

export default function AddDefectModal({
  modalClose,
  modalSubmit,
  buttonText,
  placeIdx,
  defectIdx,
}) {
  const dispatch = useDispatch();
  const isUpdate = defectIdx ? true : false;

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      sort1: '',
      sort2: '',
      sort3: '',
      place: '',
      place_idx: placeIdx,
      defect_idx: defectIdx,
    },
  });

  const onSubmitAdd = data => {
    addDefectPlace(data)
      .then(response => {
        dispatch(
          openModal({
            Component: ToastMessage,
            props: {
              iconImg: 'complete',
              message: '하자등록 항목이 추가되었습니다.',
            },
          }),
          modalSubmit(),
        );
      })
      .catch(error => {
        console.log(error);
        dispatch(
          openModal({
            Component: ToastMessage,
            props: {
              iconImg: 'fail',
              message: error.message, //'실패했습니다',
            },
          }),
        );
      });
  };

  const onSubmitUpdate = data => {
    UpdateDefectPlace(data)
      .then(response => {
        dispatch(
          openModal({
            Component: ToastMessage,
            props: {
              iconImg: 'complete',
              message: '하자등록 항목이 수정되었습니다.',
            },
          }),
          modalSubmit(),
        );
      })
      .catch(error => {
        dispatch(
          openModal({
            Component: ToastMessage,
            props: {
              iconImg: 'fail',
              message: error.message, //'실패했습니다',
            },
          }),
        );
      });
  };

  function handleClickSubmit(data) {
    if (isUpdate) {
      onSubmitUpdate(data, placeIdx);
    } else {
      onSubmitAdd(data);
    }
  }

  function handleClickCloseConfirm() {
    if (isUpdate && isDirty) {
      dispatch(
        openModal({
          Component: ConfirmModal,
          props: {
            message:
              '지금 나가시면 변경사항이 저장되지 않습니다. 그만하시겠습니까?',
            handleModalSubmit: () => {
              modalClose();
            },
          },
        }),
      );
    } else {
      modalClose();
    }
  }

  //수정 시 기존 데이터 불러오기
  useEffect(() => {
    if (isUpdate) {
      getDefectPlace(defectIdx)
        .then(response => {
          setValue('sort1', response.sort1);
          setValue('sort2', response.sort2);
          setValue('sort3', response.sort3);
          setValue('place_idx', response.place_idx);
          setValue('defect_idx', response.idx);
        })
        .catch(error => {
          dispatch(
            openModal({
              Component: ToastMessage,
              props: {
                iconImg: 'fail',
                message: 'error.message', //'실패했습니다',
              },
            }),
          );
        });
    }
  }, [defectIdx, dispatch, isUpdate, setValue]);

  return (
    <Modal isOpen style={customStyles}>
      <StyleConfirmModal>
        <ModalHeader>
          <ModalTitle>추가하기</ModalTitle>
          <CloseButton type="button" onClick={handleClickCloseConfirm}>
            <img src={closeIcon} alt="닫기" />
          </CloseButton>
        </ModalHeader>
        <ModalBody>
          <form method="post" onSubmit={handleSubmit(handleClickSubmit)}>
            <ModalFormField>
              <ModalFormFieldLabel>동</ModalFormFieldLabel>
              <ModalFormFieldContainer>
                <TextInput
                  register={register('sort1', {
                    required: '필수 입력입니다.',
                  })}
                  errors={errors}
                  placeholder="동을 입력하세요"
                />
              </ModalFormFieldContainer>
            </ModalFormField>
            <ModalFormField>
              <ModalFormFieldLabel>호수</ModalFormFieldLabel>
              <ModalFormFieldContainer>
                <TextInput
                  register={register('sort2', {
                    required: '필수 입력입니다.',
                    // pattern: {
                    //   value: /^[0-9]*$/,
                    //   message: '숫자만 입력 가능합니다.',
                    // },
                  })}
                  errors={errors}
                  placeholder="호수를 입력하세요"
                />
              </ModalFormFieldContainer>
            </ModalFormField>
            <ModalFormField>
              <ModalFormFieldLabel>위치</ModalFormFieldLabel>
              <ModalFormFieldContainer>
                <Textarea
                  register={register('sort3', {
                    required: '필수 입력입니다.',
                  })}
                  errors={errors}
                  placeholder="예시 : 공간1, 공간2, 공간3 ( , 로 구분)"
                  minHeight="140px"
                />
              </ModalFormFieldContainer>
            </ModalFormField>
            <Buttons>
              <Button
                type="button"
                size={'lg'}
                theme={'outline-default'}
                onClick={handleClickCloseConfirm}
              >
                {buttonText && buttonText.cancel ? buttonText.cancel : '취소'}
              </Button>
              <Button
                type="submit"
                size={'lg'}
                theme={'point'}
                status={isValid ? null : 'disabled'}
                onClick={handleSubmit(d => handleClickSubmit(d))}
              >
                {buttonText && buttonText.submit ? buttonText.submit : '확인'}
              </Button>
            </Buttons>
          </form>
        </ModalBody>
      </StyleConfirmModal>
    </Modal>
  );
}

const customStyles = {
  overlay: {
    position: 'fixed',
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    zIndex: '10000',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: 'calc(100% - 30px)',
    minWidth: '280px',
    maxWidth: '550px',
    padding: '0',
    borderRadius: '4px',
    transform: 'translate(-50%, -50%)',
    border: 'none',
  },
};

const StyleConfirmModal = styled.div`
  padding: 30px;
`;

const Buttons = styled.div`
  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(auto, 0));
  justify-content: center;
`;

const ModalHeader = styled.div`
  margin-bottom: 35px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #222;
`;
const ModalTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #222;
`;
const CloseButton = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  cursor: pointer;
`;
const ModalBody = styled.div``;

const ModalFormField = styled.div`
  margin-bottom: 23px;
`;
const ModalFormFieldLabel = styled.div`
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  color: #323232;
`;
const ModalFormFieldContainer = styled.div``;
