import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'shared/styles/theme';

const Layout = styled.form`
  padding: 10px 0;
`;
const Fieldset = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
`;
const Label = styled.label`
  flex: none;
  display: flex;
  align-items: center;
  width: 157px;
  padding: 0 10px 0 25px;
  font-size: 14px;
  font-weight: 500;
  color: #323232;
  ${props =>
    props.required &&
    css`
      &::after {
        content: '';
        flex: none;
        display: inline-block;
        width: 6px;
        height: 6px;
        margin-left: 5px;
        margin-bottom: 3px;
        background-color: ${theme.colors.point};
        border-radius: 50%;
        vertical-align: middle;
      }
    `}
`;
const FormContent = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  gap: 9px;
  [class*='-indicatorContainer'] {
    // 화살표
    width: 34px !important;
    padding: 0;
  }
`;

const DataPickerTab = styled.div`
  display: flex;
  border-top: 1px solid #e2e5ec;
  border-left: 1px solid #e2e5ec;
  border-radius: 2px;
`;

const DataPickerBtn = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 36px;
  font-size: 14px;
  border-right: 1px solid #e2e5ec;
  border-bottom: 1px solid #e2e5ec;
  cursor: pointer;
  transition: all 0.2s;
  &:first-of-type {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  &:last-of-type {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
  &:hover {
    background-color: #f3f6fb;
  }
  ${props =>
    props.active === 'true' &&
    css`
      color: ${theme.colors.point};
      &::after {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        width: 100%;
        height: 100%;
        border: 1px solid ${theme.colors.point};
        box-sizing: content-box;
      }
    `}
`;

export const SearchCard = {
  Layout,
  Fieldset,
  Label,
  FormContent,
  DataPickerTab,
  DataPickerBtn,
};
