import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';

import { ThemeProvider } from '@emotion/react';
import GlobalStyle from 'shared/styles/GlobalStyle';
import { theme } from 'shared/styles/theme';
import useAndroidBackButton from 'shared/hooks/capacitor/useAndroidBackButton';

import { getUserProfile } from 'api/user';
import { SET_TOKEN, DELETE_TOKEN } from 'redux/slices/token';
import { SET_USER } from 'redux/slices/user';

import Landing from 'pages/Landing';
import Login from 'pages/Login/Login';
import DefectList from 'pages/Defect/DefectList';
import DefectWrite from 'pages/Defect/DefectWrite';
import DefectDetails from 'pages/Defect/DefectDetails';
import NoticeList from 'pages/Board/Notice/NoticeList';
import NoticeDetails from 'pages/Board/Notice/NoticeDetails';
import Faq from 'pages/Board/Faq/FaqList';
import Terms from 'pages/Terms/Terms';
import Tos from 'pages/Terms/Tos';
import Privacy from 'pages/Terms/Privacy';
import Settings from 'pages/Settings';
import GuidePage from 'pages/GuidePage';
import NotFound from 'pages/NotFound';
import Modal from 'pages/Modal';
import FloorImgPage from 'pages/FloorImgPage';

import ProtectedRoutes from 'components/Route/User/ProtectedRoutes';
import PublicRoutes from 'components/Route/User/PublicRoutes';

import ModalsProvider from 'components/Modals/ModalsProvider';

import styled from '@emotion/styled';
import Signup from 'pages/Login/Signup';
import FindPassword from 'pages/Login/FindPassword';
import FindAccount from 'pages/Login/FindAccount';
import UserInfo from 'pages/UserInfo';
import ChangePassword from 'pages/Login/ChangePassword';
import LeaveAccount from 'pages/Login/LeaveAccount';

export default function User() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = localStorage.access_token;
  const { authenticated } = useSelector(state => state.token);

  useAndroidBackButton();

  useEffect(() => {
    //인증되지는 않았지만 기존 토큰이 존재하는 경우
    if (!authenticated) {
      if (localStorage.access_token) {
        dispatch(SET_TOKEN(localStorage.access_token));

        getUserProfile()
          .then(response => {
            dispatch(SET_USER(response));
            localStorage.setItem('placeIdx', response.place_idx);
          })
          .catch(e => {
            // 토큰 만료 시 로그인 화면으로 이동
            console.log('authenticated 에러', e);
            dispatch(DELETE_TOKEN());
            localStorage.removeItem('access_token');
            localStorage.removeItem('placeIdx');
            localStorage.removeItem('prevInfo');
            localStorage.removeItem('uuid');
            return navigate('/');
          });
      }
    }
  }, [authenticated, dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <BodyScroll>
        <Routes>
          <Route path="/" element={<ProtectedRoutes token={token} />}>
            <Route path="/" element={<DefectList />} />
            <Route path="/login" element={<Navigate to="/" />} />
            <Route path="/defectwrite" element={<DefectWrite />} />
            <Route path="/defect/details/:dftIdx" element={<DefectDetails />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/notice" element={<NoticeList />} />
            <Route path="/notice/details/:bcIdx" element={<NoticeDetails />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/user-info" element={<UserInfo />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/leave-account" element={<LeaveAccount />} />
            <Route path="/terms">
              <Route index element={<Terms />} />
              <Route path="privacy" element={<Privacy />} />
              <Route path="tos" element={<Tos />} />
            </Route>
            <Route path="/modal" element={<Modal />} />
            <Route path="/guide" element={<GuidePage />} />
            <Route path="/floor" element={<FloorImgPage />} />
          </Route>
          <Route path="login" element={<PublicRoutes token={token} />}>
            <Route path="/login" element={<Login />} />
          </Route>
          <Route path="/signup" element={<Signup />} />
          <Route path="/find-account" element={<FindAccount />} />
          <Route path="/find-password" element={<FindPassword />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BodyScroll>
      <ModalsProvider />
    </ThemeProvider>
  );
}

const BodyScroll = styled.div`
  position: relative;
  min-width: 320px;
  max-width: 414px;
  min-height: 100vh;
`;
