import styled from '@emotion/styled';
import { theme } from 'shared/styles/theme';
import CommonStyledComponents from 'shared/styles/CommonStyledComponents';
const { ErrorMessage } = CommonStyledComponents;

export default function CheckboxInputs(props) {
  const name = props.register.name;

  return (
    <>
      <StyledCheckboxInputs>
        {props.data.map((item, index) => {
          return (
            <label key={index}>
              <input
                type="radio"
                value={item.value}
                name={name}
                {...props.register}
              />
              {item.label}
            </label>
          );
        })}
      </StyledCheckboxInputs>
      {props.errors && props.errors[name] && props.errors[name].message && (
        <ErrorMessage>{props.errors[name].message}</ErrorMessage>
      )}
    </>
  );
}

const StyledCheckboxInputs = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: auto;
  margin: 6px -8px;

  label {
    display: inline-flex;
    align-items: center;
    padding: 6px 8px;
    font-size: 16px;
    color: #000;
    font-weight: 500;
    cursor: pointer;
  }
  input[type='radio'] {
    position: relative;
    border: 2px solid ${theme.colors.divider};
    width: 26px;
    height: 26px;
    margin-right: 12px;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    &:checked {
      background-color: ${theme.colors.white};
      border-color: ${theme.colors.point};
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 18px;
        height: 18px;
        background-color: ${theme.colors.point};
        transform: translate(-50%, -50%);
        border-radius: 2px;
      }
    }
  }
`;
