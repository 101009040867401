import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { getYear, getMonth } from 'date-fns';
import { css } from '@emotion/react';
import ko from 'date-fns/locale/ko';
import styled from '@emotion/styled';

import 'shared/root/styles/common/datepicker.scss';

import IconArrow from 'assets/imgs/select_icon_arrow.svg';

import CommonStyledComponents from 'shared/root/styles/CommonStyledComponents';
const { customScroll } = CommonStyledComponents;

export function DatePicker({ selected, onChange, ...props }) {
  registerLocale('ko', ko); // date-picker 언어설정
  const _ = require('lodash');
  const years = _.range(1990, getYear(new Date()) + 1, 1); // 수정
  const months = [
    '1월',
    '2월',
    '3월',
    '4월',
    '5월',
    '6월',
    '7월',
    '8월',
    '9월',
    '10월',
    '11월',
    '12월',
  ];

  return (
    <DatePickerStyled
      isSelected={props.isSelected}
      selectsEnd={props.selectsEnd}
    >
      <ReactDatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <CustomHeader
            style={{
              margin: 10,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <PrevButton
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            />
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) => {
                changeMonth(months.indexOf(value));
              }}
            >
              {months.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <NextButton
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            />
          </CustomHeader>
        )}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        locale="ko"
        dropdownMode="select"
        selected={selected}
        dateFormat="yyyy-MM-dd"
        onChange={onChange}
        {...props}
      />
    </DatePickerStyled>
  );
}

const DatePickerStyled = styled.div`
  ${props =>
    props.isSelected &&
    css`
    `}
  ${props =>
    props.selectsEnd &&
    css`
      .react-datepicker__day {
        &--selected {
          &[aria-selected='true']:not(.react-datepicker__day--in-selecting-range) {
            background-color: #fff;
            color: #323232;
          }
        }
      }
      .react-datepicker__day--in-selecting-range {
        border-radius: 0;
      }
      .react-datepicker__day--selecting-range-start,
      .react-datepicker__day--selecting-range-end {
        border-radius: 50%;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 50%;
          height: 100%;
          background-color: rgba(169, 205, 247, 0.2);
          z-index: -2;
        }
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background-color: #0b6fe8;
          z-index: -1;
        }
      }
      .react-datepicker__day--selecting-range-end {
        &::before {
          left: 0;
        }
      }
    `}
`;

const CustomHeader = styled.div`
  margin: 10;
  display: flex;
  justify-content: center;
  button {
    width: 30px;
  }
  select {
    border: none;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.47;
    margin: 0 2px;
    ${customScroll('#8087af', '#f6f7f9', '3px', '3px')}
    option {
      font-size: 14px;
    }
  }
`;

const PrevButton = styled.button`
  width: 34px;
  height: 34px;
  border-radius: 4px;
  margin-right: auto;
  background-image: url(${IconArrow});
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  transform: rotate(180deg);
  cursor: pointer;
  &:hover {
    background-color: #f3f6fb;
  }
`;
const NextButton = styled.button`
  width: 34px;
  height: 34px;
  border-radius: 4px;
  margin-left: auto;
  background-image: url(${IconArrow});
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  cursor: pointer;
  &:hover {
    background-color: #f3f6fb;
  }
`;
