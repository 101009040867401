import Modal from 'react-modal';

import styled from '@emotion/styled';
import CommonStyledComponents from 'shared/root/styles/CommonStyledComponents';

const { Button } = CommonStyledComponents;

export default function ConfirmModal({
  modalClose,
  modalSubmit,
  message,
  buttonText,
  onRequestClose,
  buttonCancelRemove,
}) {
  return (
    <Modal
      isOpen
      style={customStyles}
      onRequestClose={onRequestClose ? modalClose : null}
    >
      <StyleConfirmModal>
        <Message>{message}</Message>
        <Buttons>
          {!buttonCancelRemove && (
            <Button
              type="button"
              size={'lg'}
              theme={'outline-default'}
              onClick={modalClose}
            >
              {buttonText && buttonText.cancel ? buttonText.cancel : '취소'}
            </Button>
          )}
          <Button
            type="submit"
            size={'lg'}
            theme={'point'}
            onClick={modalSubmit}
          >
            {buttonText && buttonText.submit ? buttonText.submit : '확인'}
          </Button>
        </Buttons>
      </StyleConfirmModal>
    </Modal>
  );
}

const customStyles = {
  overlay: {
    position: 'fixed',
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    zIndex: '10000',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: 'calc(100% - 30px)',
    maxWidth: '380px',
    padding: '0',
    borderRadius: '4px',
    transform: 'translate(-50%, -50%)',
    border: 'none',
  },
};

const StyleConfirmModal = styled.div`
  padding: 30px;
`;

const Message = styled.div`
  padding: 40px 0;
  font-size: 16px;
  line-height: 1.5;
  white-space: pre-wrap;
  text-align: center;
`;

const Buttons = styled.div`
  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(auto, 0));
  justify-content: center;
`;
