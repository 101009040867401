import React from 'react';
import { Link } from 'react-router-dom';

import styled from '@emotion/styled';
import { theme } from 'shared/styles/theme';

import Button from 'shared/styles/common/Button';

export default function FloorButton() {
  return (
    <Link to="/floor">
      <CustomButton size="sm" theme="point3-blue">
        도면보기
      </CustomButton>
    </Link>
  );
}

const CustomButton = styled(Button)`
  width: 72px;

  background-color: ${theme.colors.point3};
  color: ${theme.colors.point};
`;
