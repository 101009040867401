import { useTheme, Global, css } from '@emotion/react';
import Reset from './base/reset.css';
import Font from './base/font.css';

import CommonStyledComponents from 'shared/root/styles/CommonStyledComponents';
const { customScroll } = CommonStyledComponents;

const GlobalStyle = () => {
  const theme = useTheme();

  return (
    <Global
      styles={css`
        ${Reset}
        ${Font}
        html {
          background-color: #f6f7f9;
        }
        body {
          position: relative;
          margin: 0 auto;
          font-family: ${theme.fonts.pretendard};
          color: ${theme.colors.font};
          background-color: #fff;
          overflow: hidden;
        }
      `}
    />
  );
};

export default GlobalStyle;
