import Modal from 'react-modal';

import { API_URL } from 'shared/constants/constants';

import closeIcon from 'root/assets/imgs/icon_close.svg';
import IconDownload from 'root/assets/imgs/icon-download.svg';

import styled from '@emotion/styled';
import CommonStyledComponents from 'shared/root/styles/CommonStyledComponents';
const { Button } = CommonStyledComponents;

export default function DefectImageModal({ modalClose, buttonText, placeIdx }) {
  return (
    <Modal isOpen style={customStyles}>
      <StyleConfirmModal>
        <ModalHeader>
          <ModalTitle>전체 사진 다운로드</ModalTitle>
          <CloseButton type="button" onClick={modalClose}>
            <img src={closeIcon} alt="닫기" />
          </CloseButton>
        </ModalHeader>
        <ModalBody>
          <LinkButtonWrap>
            <LinkButton
              href={`${API_URL}/file/downloads/info/${placeIdx} `}
              download
            >
              정보가 표시된 사진
              <Button theme={'outline-default'} size={'md'}>
                <img src={IconDownload} alt="" />
                정보 표시 사진 다운로드
              </Button>
            </LinkButton>
            <LinkButton
              href={`${API_URL}/file/downloads/origin/${placeIdx} `}
              download
            >
              원본 사진
              <Button theme={'outline-default'} size={'md'}>
                <img src={IconDownload} alt="" />
                원본 사진 다운로드
              </Button>
            </LinkButton>
          </LinkButtonWrap>
          <Buttons>
            <Button
              type="button"
              size={'lg'}
              theme={'outline-default'}
              onClick={modalClose}
            >
              {buttonText && buttonText.cancel ? buttonText.cancel : '취소'}
            </Button>
          </Buttons>
        </ModalBody>
      </StyleConfirmModal>
    </Modal>
  );
}

const customStyles = {
  overlay: {
    position: 'fixed',
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    zIndex: '10000',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: 'calc(100% - 30px)',
    minWidth: '280px',
    maxWidth: '450px',
    padding: '0',
    borderRadius: '4px',
    transform: 'translate(-50%, -50%)',
    border: 'none',
  },
};

const StyleConfirmModal = styled.div`
  padding: 30px;
`;

const Buttons = styled.div`
  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(auto, 0));
  justify-content: center;
`;

const ModalHeader = styled.div`
  margin-bottom: 35px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #222;
`;
const ModalTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.22;
  color: #222;
`;
const CloseButton = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  cursor: pointer;
`;
const ModalBody = styled.div``;

const LinkButtonWrap = styled.div`
  margin-bottom: 40px;
  border: 1px solid #e3e4eb;
`;

const LinkButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 13px 20px;
  & + & {
    border-top: 1px solid #e3e4eb;
  }
`;
