import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { App as CapacitorApp } from '@capacitor/app';

import checkMobileApp from 'shared/libraries/checkMobileApp';

export default function useAndroidBackButton() {
  const navigate = useNavigate();
  const isMobileApp = checkMobileApp();

  useEffect(() => {
    if (!isMobileApp || !CapacitorApp) {
      return;
    }

    const handleBackButton = ({ canGoBack }) => {
      if (!canGoBack) {
        CapacitorApp.exitApp();
      } else {
        navigate(-1);
      }
    };

    CapacitorApp.addListener('backButton', handleBackButton);

    return () => {
      CapacitorApp.removeListener('backButton', handleBackButton);
    };
  }, [isMobileApp, navigate]);

  return null;
}
