import axios from 'axios';
import { useEffect, useState, useRef } from 'react';

import { useDispatch } from 'react-redux';

import html2canvas from 'html2canvas';

// styles
import styled from '@emotion/styled';
import { theme } from 'shared/styles/theme';
import { openModal } from 'redux/common/slices/modals';
import ToastMessage from 'components/Modals/ToastMessage';

import InfoTable from 'pages/Defect/components/PhotoInfoTable';

import { getOptionObj, urltoFile, reorderObject } from 'utils';
import { DefectTypeData, WorkStatusData } from 'shared/root/defectData';

import { patchDefect } from 'api/defect';

export default function DefectsDetailsCanvas({
  placeData,
  dftData,
  originImgUrl,
  formData,
}) {
  // console.log('★DefectsDetailsCanvas', dftData, originImgUrl);
  const canvasSubmitRef = useRef();
  const infos = useRef();
  const dispatch = useDispatch();

  // (1)  캔버스DOM canvasElem에 담기 ===================================================================
  const [canvasElem, setCanvasElem] = useState(null);
  useEffect(() => {
    if (!originImgUrl) return;
    setCanvasElem(canvasSubmitRef.current);
  }, [originImgUrl]);

  // (2) 캔버스DOM이 있으면, <img>객체를 만들고, img src에 원본 주소를 넣는다. ============================
  const [imgObj, setImgObj] = useState(null);
  const [drawingContext, setDrawingContext] = useState();
  useEffect(() => {
    if (!canvasElem) return;

    const image = new Image();
    image.onload = () => {
      setImgObj(image);
    };
    image.src = originImgUrl;
    image.crossOrigin = 'Anonymous'; //외부 이미지의 CORS(cross origin)문제로 image의 crossOrigin값을 설정

    const getContextResult = canvasElem.getContext('2d', {
      willReadFrequently: true,
    });
    setDrawingContext(getContextResult);
  }, [canvasElem]);

  // (3) 이미지객체가 생기면, 이미지의 가로세로를 측정해서, 가로세로중 긴쪽이 1200사이즈인 캔버스를 만든다.=======
  const [isVertical, setIsVertical] = useState();
  useEffect(() => {
    if (!imgObj) return;
    const isVerticalData = imgObj.width < imgObj.height; // 세로 이미지 여부 체크
    setIsVertical(isVerticalData);

    // 생성된 이미지객체를 기준으로 캔버스(canvasElem)의 사이즈를 정한다
    if (isVertical) {
      canvasElem.width = (imgObj.width * 1200) / imgObj.height;
      canvasElem.height = 1200;
    } else {
      canvasElem.width = 1200;
      canvasElem.height = (imgObj.height * 1200) / imgObj.width;
    }

    if (canvasElem.width === 0 || canvasElem.height === 0) {
      alert('수정이 불가능합니다.');
    } else {
      // 캔버스위에 그린다.
      drawInfoOnPhoto();
    }
    console.log('★★★', isVertical);
  }, [imgObj]);

  // (4) 캔버스위에 이미지와 정보표시테이블을 그린다. =====================================================
  const [canvasValue, setCanvasValue] = useState();
  const drawInfoOnPhoto = () => {
    // 정보표시테이블 DOM html을 canvas형태로 생성
    html2canvas(infos.current, {
      scale: 3,
      backgroundColor: 'rgba(0, 0, 0, 0)',
      removeContainer: true,
      allowTaint: true,
    }).then(function (canvasText) {
      const custom_width = 280; // 이 값으로 그려지는 테이블이 너비가 결정된다.

      // 이미지를 캔버스에 그린다
      drawingContext?.drawImage(
        imgObj,
        0,
        0,
        canvasElem.width,
        canvasElem.height,
      );

      // 정보표시 테이블을 캔버스에 그린다.
      drawingContext?.drawImage(
        canvasText,
        0,
        canvasElem.height - (custom_width - 1),
        (custom_width * infos.current.clientWidth) / infos.current.clientHeight,
        custom_width,
      );

      setCanvasValue(canvasElem?.toDataURL('image/jpg', 1));
    });
  };

  //form submit 데이터 담기
  const [submitData, setSubmitData] = useState(dftData);
  useEffect(() => {
    if (formData) {
      setSubmitData(formData);
    }
    if (!formData && dftData) {
      setSubmitData(dftData);
    }
  }, [formData, dftData]);

  //form submit 데이터 있을때 캔버스에 그리기
  useEffect(() => {
    drawInfoOnPhoto();
  }, [submitData]);

  //캔버스에 그려진 경우
  const [infoFile, setInfoFile] = useState(null);
  useEffect(() => {
    if (submitData && canvasValue) {
      //네이밍지정
      const filetitle = `${submitData.sort1}_${submitData.sort2}_${
        submitData.sort3
      }_${getOptionObj(DefectTypeData, submitData.type, true)}_${getOptionObj(
        WorkStatusData,
        submitData.status,
        true,
      )}.jpg`;
      
      const info_file = urltoFile(canvasValue, filetitle);
      // setValue('info_img', info_file);
      setInfoFile(info_file);
    }
  }, [canvasValue]);

  //infoFile 새로 담긴경우
  useEffect(() => {
    if (infoFile && Object.keys(submitData).length > 0) {
      const data = {
        dft_info_img: infoFile,
        ...submitData,
      };
      patchDefect(data)
        .then(response => {
          window.location.reload();
        })
        .catch(error => {
          console.log(error);
          dispatch(
            openModal({
              Component: ToastMessage,
              props: {
                iconImg: 'fail',
                message: error.message, //'실패했습니다',
              },
            }),
          );
        });
    }
  }, [infoFile]);

  return (
    <>
      <ThumbContainer>
        <canvas id="canvas" ref={canvasSubmitRef}></canvas>
        <InfoTable
          ref={infos}
          placeName={placeData?.name}
          watchData={submitData}
        />
      </ThumbContainer>
    </>
  );
}

const ThumbContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  min-height: 220px;
  img {
    display: block;
  }
  table {
    z-index: -1;
  }
  canvas {
    width: 100%;
  }
`;
