import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useForm } from 'react-hook-form';

import ReactMoment from 'react-moment';

import { getPlaceDefectList } from 'redux/root/slices/defect';
import { deletePlace } from 'api/place';

import { SortNumOptions } from 'shared/root/optionData';

import PageLayout from 'root/components/PageLayout';
import TitleContainer from 'root/components/TitleContainer';
import ContentsContainer from 'root/components/ContentsContainer';
import NoResult from 'root/components/NoResult';

import ConfirmModal from 'root/components/Modals/ConfirmModal';
import ToastMessage from 'root/components/Modals/ToastMessage';
import { openModal } from 'redux/common/slices/modals';

import usePagination from 'hooks/usePagination';

import CommonStyledComponents from 'shared/root/styles/CommonStyledComponents';
import { CustomSelect, styleDefault } from 'shared/root/styles/common/Select';

const { Card, Table, Input, Button } = CommonStyledComponents;

export default function DefectsByPlaceList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [sortNumValue, setSortNumValue] = useState(SortNumOptions[0]);

  const tableLists = useSelector(state => state.rootDefect.placeDefectList);

  // 페이지네이션
  const {
    paginationData,
    PaginationComponent,
    handleChangeSorting,
    setPaginationIndex,
  } = usePagination({
    target: tableLists,
  });

  const initialValues = {
    status: '',
    checkedLists: [],
  };

  const { register, reset, watch, setValue, getValues } = useForm({
    defaultValues: {
      initialValues,
    },
  });

  // 현장별 하자 항목 가져오기
  const setTableLists = useCallback(
    data => {
      dispatch(
        getPlaceDefectList({
          take: data.sorting,
          page: data.page,
        }),
      );
    },
    [dispatch],
  );

  // 체크박스 전체 선택
  // 데이터 개수와 체크된 아이템의 개수가 다를 경우 선택 해제 (하나라도 해제 시 선택 해제)
  const handleAllCheckedList = checked => {
    if (checked) {
      const arr = [];
      tableLists.results.forEach(el => arr.push(String(el.idx)));
      setValue('checkedLists', arr);
    } else {
      setValue('checkedLists', []);
    }
  };

  // 선택삭제
  function handleDelete() {
    dispatch(
      openModal({
        Component: ConfirmModal,
        props: {
          message: '선택하신 항목을 삭제하시겠습니까?',
          handleModalSubmit: () => {
            const { status, ...deleteIdxs } = getValues();
            if (deleteIdxs.checkedLists.length !== 0) {
              deletePlace(deleteIdxs.checkedLists)
                .then(response => {
                  setTableLists(paginationData);
                  reset();
                  dispatch(
                    openModal({
                      Component: ToastMessage,
                      props: {
                        iconImg: 'complete',
                        message: '삭제되었습니다',
                      },
                    }),
                  );
                })
                .catch(error => {
                  console.log(error);
                  reset();
                  dispatch(
                    openModal({
                      Component: ToastMessage,
                      props: {
                        iconImg: 'fail',
                        message: '실패했습니다',
                      },
                    }),
                  );
                });
            }
          },
        },
      }),
    );
  }

  // 테이블 리스트 불러오기
  useEffect(() => {
    setTableLists(paginationData);
  }, [paginationData.page, paginationData.sorting]);

  return (
    <PageLayout>
      <TitleContainer>
        <h2>
          <span>하자관리</span>
          <b>현장별 하자관리</b>
        </h2>
      </TitleContainer>

      <ContentsContainer>
        <Card.Layout>
          <Card.Header>
            <Card.HeaderTitle>현장별 하자관리</Card.HeaderTitle>
            <Card.HeaderCount>
              총 <em>{tableLists.total ? tableLists.total : 0}</em>개
            </Card.HeaderCount>
            <Card.HeaderSorting>
              <CustomSelect
                name="sortNum"
                styles={styleDefault}
                options={SortNumOptions}
                value={sortNumValue}
                isSearchable={false}
                onChange={e => {
                  handleChangeSorting(e);
                  setSortNumValue(e);
                }}
              />
            </Card.HeaderSorting>
          </Card.Header>
          <Card.Body>
            <Table.Options>
              <Button
                type="button"
                size={'md'}
                theme={'point2'}
                status={
                  watch().checkedLists && watch().checkedLists.length > 0
                    ? ''
                    : 'disabled'
                }
                onClick={handleDelete}
              >
                선택삭제
              </Button>
            </Table.Options>
            <Table.Container type={'scroll'}>
              <Table.Layout>
                <colgroup>
                  <col style={{ width: '80px' }} />
                  <col style={{ width: '80px' }} />
                  <col style={{ width: '51.25%' }} />
                  <col style={{ width: '51.25%' }} />
                  <col style={{ width: '51%' }} />
                  <col style={{ width: '20%' }} />
                </colgroup>
                <thead>
                  <Table.Tr>
                    <Table.Th type={'checkbox'}>
                      <Input.Checkbox>
                        <input
                          type="checkbox"
                          onChange={e => handleAllCheckedList(e.target.checked)}
                          checked={
                            paginationData.sorting &&
                            tableLists.pageTotal &&
                            watch().checkedLists &&
                            (watch().checkedLists.length ===
                              paginationData.sorting ||
                              watch().checkedLists.length >=
                                tableLists.pageTotal)
                              ? true
                              : false
                          }
                        />
                      </Input.Checkbox>
                    </Table.Th>
                    <Table.Th>번호</Table.Th>
                    <Table.Th>현장명</Table.Th>
                    <Table.Th>하자개수</Table.Th>
                    <Table.Th>등록일</Table.Th>
                    <Table.Th type={'more'}>관리</Table.Th>
                  </Table.Tr>
                </thead>
                <tbody>
                  {tableLists.length === 0 ? (
                    <Table.Tr noHover>
                      <Table.Td colSpan="6">
                        <NoResult />
                      </Table.Td>
                    </Table.Tr>
                  ) : (
                    tableLists.results.map((item, itemIdx) => (
                      <Table.Tr key={item.idx}>
                        <Table.Td type={'checkbox'}>
                          <Input.Checkbox>
                            <input
                              type="checkbox"
                              value={item.idx}
                              {...register('checkedLists')}
                            />
                          </Input.Checkbox>
                        </Table.Td>
                        <Table.Td key={item.column}>
                          {setPaginationIndex(itemIdx)}
                        </Table.Td>
                        <Table.Td
                          type={'link'}
                          onClick={() => {
                            navigate(`/root/defectsbyplace/${item.idx}/lists`);
                          }}
                        >
                          {item.name}
                        </Table.Td>
                        <Table.Td>{item.defect_cnt}건</Table.Td>
                        <Table.Td>
                          <ReactMoment
                            date={item.createdAt}
                            format="YYYY-MM-DD&nbsp;&nbsp;HH:mm:ss"
                          />
                        </Table.Td>
                        <Table.Td type={'more'}>
                          <Button
                            theme={'underline'}
                            size={'sm'}
                            onClick={() => {
                              navigate(
                                `/root/defectsbyplace/${item.idx}/lists`,
                              );
                            }}
                          >
                            상세보기
                          </Button>
                        </Table.Td>
                      </Table.Tr>
                    ))
                  )}
                </tbody>
              </Table.Layout>
            </Table.Container>
            {paginationData.total ? (
              <Table.Pagination>
                <PaginationComponent />
              </Table.Pagination>
            ) : null}
          </Card.Body>
        </Card.Layout>
      </ContentsContainer>
    </PageLayout>
  );
}
