import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'shared/styles/theme';
import CommonStyledComponents from 'shared/styles/CommonStyledComponents';

const { ErrorMessage } = CommonStyledComponents;

export default function TextareaInput(props) {
  const name = props.register.name;
  const watchName = props.watch && props.watch(name);
  const hasError = !!props.errors?.[name];

  return (
    <TextareaInputContainer>
      {props.suffix && (
        <SuffixTextarea>
          <Textarea
            type={props.type ? props.type : 'text'}
            name={props.name ? props.name : ''}
            placeholder={props.placeholder}
            size={props.size}
            {...props}
            {...props.register}
          />
          <div>{props.suffix}</div>
        </SuffixTextarea>
      )}
      {!props.suffix && (
        <Textarea
          type={props.type ? props.type : 'text'}
          placeholder={props.placeholder}
          err={props.errors && props.errors[name] ? true : false}
          size={props.size}
          {...props}
          {...props.register}
        />
      )}
      <CharCount hasError={hasError}>
        {watchName ? watchName.length : 0}/{props.maxLength}자
      </CharCount>
      {!props.customErrorMessage &&
        props.errors &&
        props.errors[name] &&
        props.errors[name].message && (
          <ErrorMessage>{props.errors[name].message}</ErrorMessage>
        )}
    </TextareaInputContainer>
  );
}

const TextareaInputContainer = styled.div`
  position: relative;
`;

const Textarea = styled.textarea`
  width: 100%;
  min-height: 125px;
  padding: 15px 20px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.divider};
  font-size: 16px;
  color: ${theme.colors.font};
  &::placeholder {
    font-size: 16px;
    color: ${theme.colors.placeholder};
  }
  &:focus {
    border-color: ${theme.colors.point};
  }
  ${props =>
    props.err === true &&
    css`
      border-color: ${theme.colors.valid} !important;
    `}
`;

const SuffixTextarea = styled.div`
  position: relative;
  input[type='text'] {
    padding: 15px 30px 15px 10px;
  }
  div {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 16px;
    color: ${theme.colors.placeholder};
  }
`;

const CharCount = styled.p`
  position: absolute;
  right: 0;
  bottom: -25px;
  color: var(--gray_200, #94999a);
  text-align: right;
  /* 13_regular */
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 115%; /* 14.95px */
  ${({ hasError }) =>
    hasError &&
    css`
      color: ${theme.colors.valid};
    `}
`;
