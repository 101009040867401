const fonts = {
  pretendard: `'Pretendard', sans-serif`,
  dotum: '"Dotum", sans-serif',
};

const colors = {
  font: '#323232',
  point: '#0b6fe8',
  point2: '#e6f0fd',
  divider: '#e3e4eb',
  divider2: '#e2e5ec',
  valid: '#e53935',
  placeholder: '#94999a',
};

const size = {
  sm: '544px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1400px',
};

const mq = {
  sm: `@media (min-width: ${size.sm})`,
  md: `@media (min-width: ${size.md})`,
  lg: `@media (min-width: ${size.lg})`,
  xl: `@media (min-width: ${size.xl})`,
  xxl: `@media (min-width: ${size.xxl})`,
};

const gutter = {
  mobile: '16px',
  pc: '50px',
};

export const theme = {
  fonts,
  colors,
  mq,
  gutter,
};
