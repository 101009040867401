import styled from '@emotion/styled';
import { deleteUser } from 'api/user';
import TextareaInput from 'components/Form/TextareaInput';
import ToastMessage from 'components/Modals/ToastMessage';
import PageLayout from 'components/PageLayout';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { openModal } from 'redux/common/slices/modals';
import { DELETE_TOKEN } from 'redux/slices/token';
import CommonStyledComponents from 'shared/styles/CommonStyledComponents';

const { Button, Area, FormField, FieldContainer, FieldLabel } =
  CommonStyledComponents;

const initialValues = {
  user_leave_reason: '',
};

export default function LeaveAccount() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector(state => state.user);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: initialValues,
  });

  const handleLogout = () => {
    dispatch(DELETE_TOKEN());
    localStorage.removeItem('access_token');
    localStorage.removeItem('placeIdx');
    localStorage.removeItem('prevInfo');
    localStorage.removeItem('uuid');
    return navigate('/');
  };

  const handleClickSubmit = async data => {
    try {
      const res = await deleteUser(user.idx, {
        leave_reason: data.user_leave_reason,
      });
      dispatch(
        openModal({
          Component: ToastMessage,
          props: {
            iconImg: 'complete',
            message: '회원탈퇴를 완료했습니다.',
          },
        }),
      );
      handleLogout();
    } catch (error) {
      dispatch(
        openModal({
          Component: ToastMessage,
          props: {
            iconImg: 'fail',
            message: error.response.data.message,
          },
        }),
      );
    }
  };

  return (
    <LeaveAccountContainer>
      <PageLayout title="회원탈퇴" closeBtn>
        <Area>
          <FormField align="vertical">
            <FieldLabel>탈퇴 사유</FieldLabel>
            <FieldContainer>
              <TextareaInput
                register={register('user_leave_reason', {
                  maxLength: {
                    value: 100,
                    message: '100자 이하로 작성해주세요.',
                  },
                })}
                maxLength={100}
                watch={watch}
                errors={errors}
                placeholder="회원탈퇴 사유를 작성해주세요."
              />
            </FieldContainer>
          </FormField>

          <ButtonWrapper>
            <Button
              type="button"
              size={'full-xl'}
              theme={'point'}
              onClick={handleSubmit(d => handleClickSubmit(d))}
            >
              회원탈퇴
            </Button>
          </ButtonWrapper>
        </Area>
      </PageLayout>
    </LeaveAccountContainer>
  );
}

const LeaveAccountContainer = styled.div``;

const ButtonWrapper = styled.div`
  margin-top: 55px;
`;
