import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import styled from '@emotion/styled';
import { theme } from 'shared/styles/theme';

import { openModal } from 'redux/common/slices/modals';
import PermissionModal from 'components/Modals/PermissionModal';
import GuideModal from 'components/Modals/GuideModal';
import {
  PermissionModalConfirm,
  GuideModalConfirm,
} from 'redux/slices/permission';

import HeaderContainer from './HeaderContainer';
import Footer from 'components/Footer';

export default function PageLayout({
  title,
  backBtn,
  showFloorBtn,
  deleteBtn,
  closeBtn,
  children,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { authenticated } = useSelector(state => state.token);
  const { permissionModalVisible } = useSelector(state => state.permission);
  const { guideModalVisible } = useSelector(state => state.permission);

  const footerShowPathnames = ['/settings'];

  useEffect(() => {
    if (authenticated && permissionModalVisible && guideModalVisible) {
      dispatch(
        openModal({
          Component: PermissionModal,
          props: {
            handleModalSubmit: () => {
              dispatch(PermissionModalConfirm());
              dispatch(
                openModal({
                  Component: GuideModal,
                  props: {
                    handleModalClose: () => {
                      dispatch(GuideModalConfirm());
                    },
                    handleModalSubmit: () => {
                      dispatch(GuideModalConfirm());
                      navigate('/guide');
                    },
                  },
                }),
              );
            },
          },
        }),
      );
    }
  }, [dispatch]);

  return (
    <PageLayoutContainer>
      {title && (
        <HeaderContainer
          title={title}
          backBtn={backBtn}
          showFloorBtn={showFloorBtn}
          deleteBtn={deleteBtn}
          closeBtn={closeBtn}
        />
      )}
      <PageContainer title={title}>{children}</PageContainer>
      {footerShowPathnames.includes(location.pathname) && <Footer />}
    </PageLayoutContainer>
  );
}
const PageLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
`;

const PageContainer = styled.div`
  padding: ${props => (props.title ? '20px 0 0;' : '0')};
  padding-bottom: ${theme.notch.bottom};
`;
