import { forwardRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { getOptionObj } from 'utils';
import { WorkStatusData, DefectTypeData } from 'shared/root/defectData';

//사용자,관리자 동일학 사용됨
//dft_ 의경우 관리자에서 넘어오는 데이터
const InfoTable = forwardRef(({ placeName, watchData, resize }, ref) => {
  if (!watchData.shooting_day && !watchData.dft_shooting_day) {
    return;
  }

  const date = (watchData.shooting_day || watchData.dft_shooting_day).split(
    ' ',
  )[0];

  return (
    <InfoTableContainer ref={ref} resize={resize}>
      <tbody>
        <tr>
          <InfoTitle>현장</InfoTitle>
          <InfoDesc>{placeName}</InfoDesc>
        </tr>
        <tr>
          <InfoTitle>동</InfoTitle>
          <InfoDesc>{watchData.sort1 || watchData.dft_sort1}</InfoDesc>
        </tr>
        <tr>
          <InfoTitle>호수</InfoTitle>
          <InfoDesc>{watchData.sort2 || watchData.dft_sort2}</InfoDesc>
        </tr>
        <tr>
          <InfoTitle>위치</InfoTitle>
          <InfoDesc>{watchData.sort3 || watchData.dft_sort3}</InfoDesc>
        </tr>
        <tr>
          <InfoTitle>작업상태</InfoTitle>
          <InfoDesc>
            {(watchData.status || watchData.dft_status) &&
              getOptionObj(
                WorkStatusData,
                watchData.status || watchData.dft_status,
                true,
              )}
          </InfoDesc>
        </tr>
        <tr>
          <InfoTitle>하자유형</InfoTitle>
          <InfoDesc>
            {(watchData.type || watchData.dft_type) &&
              getOptionObj(
                DefectTypeData,
                watchData.type || watchData.dft_type,
                true,
              )}
          </InfoDesc>
        </tr>
        <tr>
          <InfoTitle>내용</InfoTitle>
          <InfoDesc>{watchData.content || watchData.dft_content}</InfoDesc>
        </tr>
        <tr>
          <InfoTitle>촬영일자</InfoTitle>
          {/*
          촬영 시간제외한 날짜만 표기 substr(0, 11)
          */}
          <InfoDesc>{date}</InfoDesc>
        </tr>
      </tbody>
    </InfoTableContainer>
  );
});

const InfoTableContainer = styled.table`
  position: absolute;
  bottom: 0;
  left: 0;
  display: inline-block;
  border: 1px solid #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.1;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
  transform-origin: left bottom;
  table-layout: fixed;
  text-shadow: 0px 0px 2px #000;
  ${props =>
    props.resize === true &&
    css`
      transform: scale(0.75);
      td {
        padding: 1px 4px;
      }
    `}
  tr {
    position: relative;
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
    &:last-child {
      border-bottom: 0;
    }
  }
`;
const InfoTitle = styled.td`
  padding: 0 4px 2px; // 캔버스 축소로 인해 line-height를 조절해도 중간정렬이 되지않아서 bottom에 px 추가됨.
  border-right: 1px solid #fff;
`;
const InfoDesc = styled.td`
  padding: 0 4px 2px; // 캔버스 축소로 인해 line-height를 조절해도 중간정렬이 되지않아서 bottom에 px 추가됨.
  white-space: nowrap;
`;

export default InfoTable;
