import { createSlice } from '@reduxjs/toolkit';

export const rootAuthSlice = createSlice({
  name: 'authToken',
  initialState: {
    authenticated: false,
    accessToken: null,
  },
  reducers: {
    SET_TOKEN: (state, action) => {
      state.authenticated = true;
      state.accessToken = action.payload;
    },
    DELETE_TOKEN: state => {
      state.authenticated = false;
      state.accessToken = null;
      localStorage.removeItem('admin_access_token');
      localStorage.removeItem('placeIdx');
      localStorage.removeItem('prevInfo');
      localStorage.removeItem('uuid');
    },
  },
});

export const { SET_TOKEN, DELETE_TOKEN } = rootAuthSlice.actions;

export default rootAuthSlice.reducer;
