import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useForm } from 'react-hook-form';

import { getSettings } from 'redux/root/slices/settings';

import { postSettings } from 'api/settings';

import PageLayout from 'root/components/PageLayout';
import TitleContainer from 'root/components/TitleContainer';
import ContentsContainer from 'root/components/ContentsContainer';

import FormCkEditor from 'root/components/FormCkEditor';

import ToastMessage from 'root/components/Modals/ToastMessage';
import { openModal } from 'redux/common/slices/modals';

import CommonStyledComponents from 'shared/root/styles/CommonStyledComponents';
const { Card, WriteForm, Button, ButtonsGrid } = CommonStyledComponents;

export default function Terms() {
  const dispatch = useDispatch();
  const { terms_privacy, terms_tos } = useSelector(
    state => state.settings.settingInfo,
  );

  const {
    setValue,
    handleSubmit,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      terms_tos: '',
      terms_privacy: '',
    },
  });

  useEffect(() => {
    dispatch(getSettings('terms'));
  }, [dispatch]);

  function onSubmit(data) {
    postSettings(data);
    dispatch(
      openModal({
        Component: ToastMessage,
        props: {
          iconImg: 'complete',
          message: '저장되었습니다.',
        },
      }),
    );
  }

  return (
    <PageLayout>
      <TitleContainer>
        <h2>
          <span>환경설정</span>
          <b>약관 설정</b>
        </h2>
        <ButtonsGrid>
          <Button
            type="submit"
            theme={'point'}
            size={'md'}
            status={Object.keys(dirtyFields).length > 0 ? '' : 'disabled'}
            onClick={handleSubmit(onSubmit)}
          >
            저장
          </Button>
        </ButtonsGrid>
      </TitleContainer>

      <ContentsContainer>
        <Card.Layout>
          <Card.Header>
            <Card.HeaderTitle>이용약관</Card.HeaderTitle>
          </Card.Header>
          <Card.Body>
            <WriteForm.Layout>
              <FormCkEditor
                data={terms_tos && terms_tos.value}
                onChange={data => {
                  setValue('terms_tos', data, {
                    shouldDirty: true,
                  });
                }}
              />
            </WriteForm.Layout>
          </Card.Body>
        </Card.Layout>

        <Card.Layout>
          <Card.Header>
            <Card.HeaderTitle>개인정보처리방침</Card.HeaderTitle>
          </Card.Header>
          <Card.Body>
            <WriteForm.Layout>
              <FormCkEditor
                data={terms_privacy && terms_privacy.value}
                onChange={data => {
                  setValue('terms_privacy', data, {
                    shouldDirty: true,
                  });
                }}
              />
            </WriteForm.Layout>
          </Card.Body>
        </Card.Layout>
      </ContentsContainer>
    </PageLayout>
  );
}
