import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useForm } from 'react-hook-form';

import PageLayout from 'root/components/PageLayout';
import TitleContainer from 'root/components/TitleContainer';
import ContentsContainer from 'root/components/ContentsContainer';

import { getSettings } from 'redux/root/slices/settings';

import { postSettings } from 'api/settings';

import ToastMessage from 'root/components/Modals/ToastMessage';
import { openModal } from 'redux/common/slices/modals';

import CommonStyledComponents from 'shared/root/styles/CommonStyledComponents';
import TextInput from 'root/components/Form/TextInput';
const { Card, WriteForm, Button, ButtonsGrid } = CommonStyledComponents;

export default function Settings() {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      link_appstore: '',
      link_playstore: '',
    },
  });

  useEffect(() => {
    dispatch(getSettings('link')).then(res => {
      setValue('link_appstore', res.payload.link_appstore.value);
      setValue('link_playstore', res.payload.link_playstore.value);
    });
  }, []);

  function onSubmit(data) {
    postSettings(data);
    dispatch(
      openModal({
        Component: ToastMessage,
        props: {
          iconImg: 'complete',
          message: '저장되었습니다.',
        },
      }),
    );
  }

  function onError(error) {
    console.log(error);
  }

  return (
    <PageLayout>
      <TitleContainer>
        <h2>
          <span>환경설정</span>
          <b>기본정보 설정</b>
        </h2>
        <ButtonsGrid>
          <Button
            type="submit"
            theme={'point'}
            size={'md'}
            onClick={handleSubmit(onSubmit)}
            status={isDirty ? '' : 'disabled'}
          >
            저장
          </Button>
        </ButtonsGrid>
      </TitleContainer>

      <ContentsContainer>
        <Card.Layout>
          <Card.Header>
            <Card.HeaderTitle>앱정보 링크</Card.HeaderTitle>
          </Card.Header>
          <Card.Body>
            <WriteForm.Layout
              method="post"
              onSubmit={handleSubmit(onSubmit, onError)}
            >
              <WriteForm.Fieldset>
                <WriteForm.Label>앱스토어</WriteForm.Label>
                <WriteForm.Inputs>
                  <TextInput
                    register={register('link_appstore')}
                    placeholder="앱스토어 주소를 입력해주세요."
                    errors={errors}
                    maxWidth={'400px'}
                  />
                </WriteForm.Inputs>
              </WriteForm.Fieldset>

              <WriteForm.Fieldset>
                <WriteForm.Label>플레이스토어</WriteForm.Label>
                <WriteForm.Inputs>
                  <TextInput
                    register={register('link_playstore')}
                    placeholder="플레이스토어 주소를 입력해주세요."
                    errors={errors}
                    maxWidth={'400px'}
                  />
                </WriteForm.Inputs>
              </WriteForm.Fieldset>
            </WriteForm.Layout>
          </Card.Body>
        </Card.Layout>
      </ContentsContainer>
    </PageLayout>
  );
}
