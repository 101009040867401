export const SortNumOptions = [
  { value: 10, label: '10개씩 정렬' },
  { value: 50, label: '50개씩 정렬' },
  { value: 100, label: '100개씩 정렬' },
  { value: 200, label: '200개씩 정렬' },
  { value: 500, label: '500개씩 정렬' },
];

export const SortOrderOptions = [
  { value: '1', order: 'createdAt', sort: 'desc', label: '등록일 최신순' },
  { value: '2', order: 'createdAt', sort: 'asc', label: '등록일 오래된순' },
  { value: '3', order: 'shooting_day', sort: 'desc', label: '촬영일 최신순' },
  { value: '4', order: 'shooting_day', sort: 'asc', label: '촬영일 오래된순' },
  { value: '5', order: 'filename', sort: 'desc', label: '파일명순' },
];

export const PlaceStatusOptions = [
  { value: '1', label: '예정' },
  { value: '2', label: '진행중' },
  { value: '3', label: '종료' },
];

//회원등록_회원정보_담당현장
export const PlaceGroupOptions = [
  { value: '0', label: '-' },
  { value: '1', label: '서울아파트' },
  { value: '2', label: '대구아파트' },
  { value: '3', label: '경기도아파트' },
  { value: '35', label: '테스트' },
];

//회원등록_회원정보_그룹명
export const MemberGroupOptions = [
  { value: '1', label: '최고관리자' },
  { value: '2', label: '관리자' },
  { value: '3', label: '일반회원' },
  { value: '4', label: '현장관리자' },
];

//회원등록_회원정보_관리자그룹명
export const AdminGroupOptions = [
  { value: '1', label: '최고관리자' },
  { value: '2', label: '관리자' },
  { value: '4', label: '현장관리자' },
];

//게시판_게시글_상태
export const BoardStatusOptions = [
  // { value: '0', label: '삭제' },
  { value: '1', label: '미등록' },
  { value: '2', label: '등록' },
];

//게시판_게시글_타입
export const BoardTypeOptions = [
  // { value: '0', label: '삭제' },
  { value: '1', label: '공지글' },
  { value: '2', label: '일반글' },
];

// 사용자_하자리스트 정렬
export const UserDefectSortOptions = [
  { value: 'create_day', label: '업로드 최신순' },
  { value: 'filename', label: '파일명순' },
];
