import styled from '@emotion/styled';

export default function Footer() {
  return (
    <FooterContainer>
      <FooterItem>주식회사 한솔데코</FooterItem>
      <FooterItem>대표자: 황순영</FooterItem>
      <FooterItem>사업자등록번호: 113-86-86986</FooterItem>
      <FooterItem>고객센터: 02-868-5110</FooterItem>
      <FooterItem>이메일: hansoldeco1@naver.com</FooterItem>
      <FooterItem>서울시 송파구 백제고분로 295 JS빌딩 5층</FooterItem>
      <FooterItem>Copyright 소소하자. All Rights Reserved.</FooterItem>
    </FooterContainer>
  );
}

const FooterContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
  margin-top: auto;
  padding: 20px 16px 40px 16px;
  background-color: #f6f8f9;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  color: #94999a;
`;

const FooterItem = styled.div``;
