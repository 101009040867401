import CommonStyledComponents from 'shared/styles/CommonStyledComponents';
import { useSelector } from 'react-redux';

import PageLayout from 'components/PageLayout';

const { Area } = CommonStyledComponents;

export default function FloorImgPage() {
  const { content } = useSelector(state => state.place.placeDetails.place);

  return (
    <PageLayout title="도면보기" backBtn>
      <Area>
        {content ? (
          <div
            className={'ck-content'}
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        ) : null}
      </Area>
    </PageLayout>
  );
}
