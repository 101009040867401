import styled from '@emotion/styled';
import { getVerifyId, patchUsers } from 'api/user';
import TextInput from 'components/Form/TextInput';
import ToastMessage from 'components/Modals/ToastMessage';
import PageLayout from 'components/PageLayout';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { openModal } from 'redux/common/slices/modals';
import CommonStyledComponents from 'shared/styles/CommonStyledComponents';

const { Button, Area, FormField, FieldContainer, FieldLabel } =
  CommonStyledComponents;

const initialValues = {
  id: '',
  name: '',
  email: '',
};

export default function UserInfo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector(state => state.user);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    mode: 'all',
    defaultValues: initialValues,
  });

  useEffect(() => {
    if (!user) {
      return;
    }
    setValue('id', user.id);
    setValue('name', user.name);
    setValue('email', user.email);
  }, [user, setValue]);

  const onSubmit = async data => {
    const newData = {
      name: data.name,
      email: data.email,
    };

    try {
      const res = await patchUsers(user.idx, newData);

      if (res) {
        dispatch(
          openModal({
            Component: ToastMessage,
            props: {
              iconImg: 'complete',
              message: '회원정보가 등록되었습니다.',
            },
          }),
        );
        navigate('/');
      }
    } catch (error) {
      dispatch(
        openModal({
          Component: ToastMessage,
          props: {
            iconImg: 'fail',
            message: error.response.data.message,
          },
        }),
      );
    }
  };

  //저장버튼 클릭 form전송
  function handleClickSubmit(data) {
    onSubmit(data);
  }

  return (
    <UserInfoContainer>
      <PageLayout title="회원정보수정" backBtn>
        <Area>
          <FormField align="vertical">
            <FieldLabel>아이디</FieldLabel>
            <FieldContainer>
              <TextInput
                register={register('id', {
                  required: '아이디를 입력해주세요.',
                  maxLength: {
                    value: 16,
                    message: '아이디는 16자 이하로만 작성이가능합니다.',
                  },
                })}
                errors={errors}
                placeholder="아이디"
                disabled
              />
            </FieldContainer>
          </FormField>

          <FormField align="vertical">
            <FieldLabel>이름</FieldLabel>
            <FieldContainer>
              <TextInput
                register={register('name', {
                  required: '이름을 입력해주세요.',
                })}
                errors={errors}
                placeholder="이름"
              />
            </FieldContainer>
          </FormField>

          <FormField align="vertical">
            <FieldLabel>이메일</FieldLabel>
            <FieldContainer>
              <TextInput
                register={register('email', {
                  required: '이메일을 입력해주세요.',
                  pattern: {
                    value:
                      /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/,
                    message: '올바른 이메일 주소만 입력 가능합니다',
                  },
                })}
                errors={errors}
                placeholder="이메일"
              />
            </FieldContainer>
          </FormField>

          <FormField align="vertical">
            <FieldLabel>비밀번호</FieldLabel>
            <Button
              type="button"
              size={'full-xl'}
              theme={'point3-blue'}
              onClick={() => navigate('/change-password')}
            >
              비밀번호 변경
            </Button>
          </FormField>

          <ButtonWrapper>
            <Button
              type="button"
              size="sm"
              theme="trans-gray"
              onClick={() => navigate('/leave-account')}
            >
              회원탈퇴
            </Button>
          </ButtonWrapper>

          <ButtonWrapper>
            <Button
              type="button"
              size={'full-xl'}
              theme={'point'}
              status={isDirty ? '' : 'disabled'}
              onClick={handleSubmit(d => handleClickSubmit(d))}
            >
              수정
            </Button>
          </ButtonWrapper>
        </Area>
      </PageLayout>
    </UserInfoContainer>
  );
}

const UserInfoContainer = styled.div``;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;
