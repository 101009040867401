import { useEffect, useState } from 'react';
import axios from 'axios';

import { API_BOARD, API_BOARD_ADMIN } from 'shared/constants/constants';

/*
|--------------------------------------------------------------------------
| 관리자 API
|--------------------------------------------------------------------------
*/
// 관리자 게시글 생성
export async function addBoardContent(data) {
  const updateData = {
    status: data.status.value,
    type: data.type.value,
    bd_idx: String(data.bd_idx),
    admin_idx: String(data.admin_idx),
    write_name: data.write_name,
    title: data.title,
    link_status: '0',
    link: '',
    content: data.content,
    category: [],
  };
  const response = await axios.post(`${API_BOARD_ADMIN}`, updateData);

  return response.updateData;
}

// 관리자 게시글 수정
export async function UpdateBoardContent(data) {
  const updateData = {
    status: data.status.value,
    type: data.type.value,
    bd_idx: String(data.bd_idx),
    write_name: data.write_name,
    title: data.title,
    content: data.content,
    category: [],
  };
  const response = await axios.patch(
    `${API_BOARD_ADMIN}/${data.idx}`,
    updateData,
  );

  return response.updateData;
}

// 관리자 게시글 상태 일괄 변경 (0: 삭제, 1:미등록 2: 등록)
export async function StatusChange(data) {
  const response = await axios.post(`${API_BOARD_ADMIN}/status-change`, data);

  return response.New;
}

/*
|--------------------------------------------------------------------------
| 사용자 API
|--------------------------------------------------------------------------
*/
// Infinite Scroll 게시글 불러오기
export function InfiniteScrollBoardList({ bdIdx, page, take = 3 }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [lists, setLists] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  const getLists = async () => {
    //axios를 이용하여 API호출
    await axios
      .get(`${API_BOARD}/${bdIdx}`, {
        params: { category: 0, page: page, take: take },
      })
      .then(res => {
        setLists(prevLists => {
          //중복배열제거
          return [...new Set([...prevLists, ...res.data.results.map(l => l)])];
        });
        setHasMore(res.data.results.length > 0); //데이터 여부에 따른 상태 전환
        setLoading(false); // 로딩중이지 않은 상태로 전환
      })
      .catch(error => {
        setError(true); //에러표시
      });
  };

  useEffect(() => {
    setLoading(true); //로딩중인 상태로 전환
    setError(false);
  }, []);

  useEffect(() => {
    getLists(); //리스트호출
  }, [page]);

  return { loading, error, lists, hasMore };
}
