import styled from '@emotion/styled';
import CheckboxInputs from 'components/Form/CheckboxInputs';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CommonStyledComponents from 'shared/styles/CommonStyledComponents';
import IconArrowRight from 'assets/imgs/icon_arrow_right_16_2.svg';
import IconCheck from 'assets/imgs/icon_check_26_1.svg';
import IconCheckActive from 'assets/imgs/icon_check_active_26_1.svg';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from 'redux/common/slices/modals';
import Paragraph from './Paragraph';
import { getSettings } from 'redux/root/slices/settings';
import TermModal from 'components/Modals/TermModal';
const { Button } = CommonStyledComponents;

// 하자 작업상태
export const agreeOption = [{ value: 'agree', label: '모두 동의합니다.' }];

export default function SignupAgree({ handleNext }) {
  const dispatch = useDispatch();
  const [agree, setAgree] = useState([false, false]);

  const { terms_privacy, terms_tos } = useSelector(
    state => state.settings.settingInfo,
  );

  useEffect(() => {
    dispatch(getSettings('terms'));
  }, [dispatch]);

  const {
    register,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      agree: null,
    },
  });

  const agreeCheck = watch('agree');

  const nextStepHandler = () => {
    handleNext('field');
  };

  useEffect(() => {
    if (!agree.includes(false)) {
      setValue('agree', 'agree', { shouldValidate: true, shouldDirty: true });
      return;
    } else {
      setValue('agree', null);
    }
  }, [agree, setValue]);

  useEffect(() => {
    if (agreeCheck === 'agree') {
      setAgree([true, true]);
    }
  }, [agreeCheck]);

  const agreeHandler = (text, idx) => {
    dispatch(
      openModal({
        Component: TermModal,
        props: {
          message: text,
          buttonText: {
            cancel: '취소',
          },
          handleModalCancel: () => {
            setAgree(prev => {
              let copy = [...prev];
              copy[idx] = false;
              return copy;
            });
          },
          handleModalSubmit: () => {
            setAgree(prev => {
              let copy = [...prev];
              copy[idx] = true;
              return copy;
            });
          },
        },
      }),
    );
  };

  return (
    <SignupAgreeContainer>
      <Paragraph>서비스 이용을 위한 약관 동의</Paragraph>
      <CheckboxWrapper>
        <CheckboxInputs
          register={register('agree', {
            required: '전체 동의는 필수입니다.',
          })}
          rules={{ required: true }}
          errors={errors}
          data={agreeOption}
        />
      </CheckboxWrapper>

      <div>
        <AgreeFeatureList>
          <AgreeFeatureItem onClick={() => agreeHandler(terms_tos.value, 0)}>
            <span>
              <img src={agree[0] ? IconCheckActive : IconCheck} alt="icon" />
              서비스 이용 동의 (필수)
            </span>
            <img src={IconArrowRight} alt="icon" />
          </AgreeFeatureItem>
          <AgreeFeatureItem
            onClick={() => agreeHandler(terms_privacy.value, 1)}
          >
            <span>
              <img src={agree[1] ? IconCheckActive : IconCheck} alt="icon" />
              개인정보 수집 및 이용 동의 (필수)
            </span>
            <img src={IconArrowRight} alt="icon" />
          </AgreeFeatureItem>
        </AgreeFeatureList>
      </div>
      <ButtonWrapper>
        <Button
          type="button"
          size={'full-xl'}
          theme={'point'}
          status={!isValid && 'disabled'}
          onClick={nextStepHandler}
        >
          다음
        </Button>
      </ButtonWrapper>
    </SignupAgreeContainer>
  );
}

const SignupAgreeContainer = styled.div``;

const CheckboxWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
`;

const AgreeFeatureList = styled.ul`
  margin-top: 12px;
`;
const AgreeFeatureItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:not(:nth-last-of-type(1)) {
    margin-bottom: 10px;
  }
  span {
    display: flex;
    align-items: center;
    img {
      margin-right: 8px;
    }
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 30px;
`;
