import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  current: {},
  openedModals: [],
};

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openModal: (state, { payload }) => {
      const modals = {
        ...state,
        current: payload,
        openedModals: state.openedModals.concat({
          Component: payload.Component,
          message: payload.message,
          buttonText: payload.buttonText,
          props: payload.props,
        }),
      };

      return modals;
    },
    closeModal: (state, { payload }) => {
      const modals = {
        ...state,
        current: {},
        openedModals: state.openedModals.filter(
          modal => modal.Component !== payload.Component,
        ),
      };
      return modals;
    },
  },
});

export const { openModal, closeModal } = modalsSlice.actions;

export default modalsSlice.reducer;
