import { useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { getPlaceList } from 'redux/root/slices/place';
import { getUsersList } from 'redux/root/slices/user';
import { deleteUsers } from 'api/user';
import { setListIndex } from 'utils';

import { SortNumOptions } from 'shared/root/optionData';

import PageLayout from 'root/components/PageLayout';
import TitleContainer from 'root/components/TitleContainer';
import ContentsContainer from 'root/components/ContentsContainer';
import BasicPagination from 'root/components/Pagination';
import NoResult from 'root/components/NoResult';

import ConfirmModal from 'root/components/Modals/ConfirmModal';
import ToastMessage from 'root/components/Modals/ToastMessage';
import { openModal } from 'redux/common/slices/modals';

import CommonStyledComponents from 'shared/root/styles/CommonStyledComponents';
import { CustomSelect, styleDefault } from 'shared/root/styles/common/Select';

const { Card, Table, Input, Button } = CommonStyledComponents;

export default function MemberList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tableLists = useSelector(state => state.rootUser.usersList);
  const placeList = useSelector(state => state.place.placeList);

  const takeNum = 10; // 한페이지 게시물수
  const [totalNum, setTotalNum] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [sortingNum, setSortingNum] = useState(takeNum);

  const {
    register,
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      checkedIdxs: [],
    },
  });

  const setTableLists = useCallback(
    (takeNum, activePage) => {
      dispatch(
        getUsersList({
          takeNum: takeNum,
          activePage: activePage,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(getPlaceList(1));
    setTableLists(sortingNum, 1);
  }, [dispatch]);

  useEffect(() => {
    setTotalNum(tableLists.total);
  }, [tableLists.total]);

  // 체크박스 전체 선택
  const handleAllCheckedList = checked => {
    if (checked) {
      const arr = [];
      tableLists.results.forEach(el => arr.push(el.id));
      setValue('checkedIdxs', arr);
    } else {
      setValue('checkedIdxs', []);
    }
  };

  // 선택삭제
  const onSubmit = data => {
    const deleteId = data.checkedIdxs;
    dispatch(
      openModal({
        Component: ConfirmModal,
        props: {
          message: '선택하신 항목을 삭제하시겠습니까?',
          handleModalSubmit: () => {
            deleteUsers({ data: deleteId })
              .then(response => {
                setTableLists(sortingNum, activePage);
                reset();
                dispatch(
                  openModal({
                    Component: ToastMessage,
                    props: {
                      iconImg: 'complete',
                      message: '삭제되었습니다',
                    },
                  }),
                );
              })
              .catch(error => {
                reset();
                dispatch(
                  openModal({
                    Component: ToastMessage,
                    props: {
                      iconImg: 'fail',
                      message: '실패했습니다 (' + error.message + ')',
                    },
                  }),
                );
              });
          },
        },
      }),
    );
  };

  // 현장 idx로 현장이름 가져오기
  function getPlaceName(idx) {
    const currentPlace = placeList.results.filter(
      (val, index, source) => val.idx === idx,
    );
    return currentPlace[0] ? currentPlace[0].name : '-'; //담당현장이 삭제되거나 찾을 수 없을경우 - 로 출력
  }

  const handleClickPage = num => {
    setActivePage(num);
    setTableLists(sortingNum, num);
    reset();
  };

  const handleSorting = e => {
    setSortingNum(e.value);
    setTableLists(e.value, 1);
    reset();
  };

  return (
    <PageLayout>
      <TitleContainer>
        <h2>
          <span>회원관리</span>
          <b>일반 회원목록</b>
        </h2>
      </TitleContainer>

      <ContentsContainer>
        <Card.Layout>
          <Card.Header>
            <Card.HeaderTitle>일반 회원목록</Card.HeaderTitle>
            <Card.HeaderCount>
              총 <em>{tableLists.total ? tableLists.total : 0}</em>명
            </Card.HeaderCount>
            <Card.HeaderSorting>
              <CustomSelect
                name="sortNum"
                styles={styleDefault}
                options={SortNumOptions}
                isSearchable={false}
                defaultValue={SortNumOptions[0]}
                onChange={handleSorting}
              />
            </Card.HeaderSorting>
          </Card.Header>
          <Card.Body>
            <form method="post" onSubmit={handleSubmit(onSubmit)}>
              <Table.Options>
                <div>
                  <Button
                    type="submit"
                    size={'md'}
                    theme={'point2'}
                    status={watch().checkedIdxs.length > 0 ? '' : 'disabled'}
                    onClick={onSubmit}
                  >
                    선택삭제
                  </Button>
                </div>
              </Table.Options>
              <Table.Container layout={'fixed'}>
                <Table.Layout>
                  <colgroup>
                    <col style={{ width: '80px' }} />
                    <col style={{ width: '80px' }} />
                    <col style={{ width: '76.75%' }} />
                    <col style={{ width: '76.75%' }} />
                    <col style={{ width: '20%' }} />
                  </colgroup>
                  <thead>
                    <Table.Tr>
                      <Table.Th type={'checkbox'}>
                        <Input.Checkbox>
                          <input
                            type="checkbox"
                            onChange={e =>
                              handleAllCheckedList(e.target.checked)
                            }
                            checked={
                              sortingNum &&
                              tableLists.pageTotal &&
                              (watch().checkedIdxs.length === sortingNum ||
                                watch().checkedIdxs.length >=
                                  tableLists.pageTotal)
                                ? true
                                : false
                            }
                          />
                        </Input.Checkbox>
                      </Table.Th>
                      <Table.Th>번호</Table.Th>
                      <Table.Th>아이디</Table.Th>
                      <Table.Th>담당현장</Table.Th>
                      <Table.Th type={'more'}>관리</Table.Th>
                    </Table.Tr>
                  </thead>
                  <tbody>
                    {tableLists.length === 0 ? (
                      <Table.Tr noHover>
                        <Table.Td colSpan="6">
                          <NoResult />
                        </Table.Td>
                      </Table.Tr>
                    ) : (
                      tableLists.results.map((item, itemIdx) => (
                        <Table.Tr key={item.idx}>
                          <Table.Td type={'checkbox'}>
                            <Input.Checkbox>
                              <input
                                type="checkbox"
                                value={item.id}
                                {...register('checkedIdxs')}
                              />
                            </Input.Checkbox>
                          </Table.Td>
                          <Table.Td>
                            {setListIndex(
                              itemIdx,
                              activePage,
                              sortingNum,
                              totalNum,
                            )}
                          </Table.Td>
                          <Table.Td
                            type={'link'}
                            onClick={() => {
                              navigate(`/root/member/basic/details/${item.id}`);
                            }}
                          >
                            {item.id}
                          </Table.Td>
                          <Table.Td>
                            {item.place_idx && placeList.results
                              ? getPlaceName(item.place_idx)
                              : '-'}
                          </Table.Td>
                          <Table.Td type={'more'}>
                            <Button
                              theme={'underline'}
                              size={'sm'}
                              onClick={() => {
                                navigate(
                                  `/root/member/basic/details/${item.id}`,
                                );
                              }}
                            >
                              상세보기
                            </Button>
                          </Table.Td>
                        </Table.Tr>
                      ))
                    )}
                  </tbody>
                </Table.Layout>
              </Table.Container>
              <Table.Options>
                <div>
                  <Button
                    type="submit"
                    size={'md'}
                    theme={'point2'}
                    status={watch().checkedIdxs.length > 0 ? '' : 'disabled'}
                    onClick={onSubmit}
                  >
                    선택삭제
                  </Button>
                </div>
              </Table.Options>
            </form>
            {totalNum ? (
              <Table.Pagination>
                <BasicPagination
                  total={totalNum}
                  limit={sortingNum}
                  activePage={activePage}
                  handleClick={handleClickPage}
                />
              </Table.Pagination>
            ) : null}
          </Card.Body>
        </Card.Layout>
      </ContentsContainer>
    </PageLayout>
  );
}
