import { useState, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';

import ReactMoment from 'react-moment';

import PageLayout from 'components/PageLayout';
import { InfiniteScrollBoardList } from 'api/board';

import styled from '@emotion/styled';
import { theme } from 'shared/styles/theme';
import CommonStyledComponents from 'shared/styles/CommonStyledComponents';
const { Area, Spinners } = CommonStyledComponents;

export default function Notice() {
  const [pageNumber, setPageNumber] = useState(1);

  const observer = useRef();
  const { lists, hasMore, loading } = InfiniteScrollBoardList({
    bdIdx: 1,
    page: pageNumber,
    take: 10,
  });

  const callbackRef = useCallback(
    node => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber(prevPageNumber => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore],
  );

  return (
    <PageLayout title="공지사항" backBtn>
      {loading ? (
        <Spinners loading></Spinners>
      ) : lists.length === 0 ? (
        <div>게시글이 없습니다.</div>
      ) : (
        lists.map((list, index) => (
          <BoardList
            ref={callbackRef}
            key={list.idx}
            to={`/notice/details/${list.idx}`}
            state={list.bd_idx}
          >
            <Area>
              <BoardListTitle>{list.title}</BoardListTitle>
              <BoardListDate>
                <ReactMoment date={list.createdAt} format="YYYY-MM-DD" />
              </BoardListDate>
            </Area>
          </BoardList>
        ))
      )}
    </PageLayout>
  );
}

const BoardList = styled(Link)`
  display: block;
  padding: 22px 0 20px;
  border-bottom: 1px solid #ddd;
  &:first-of-type {
    margin-top: -20px;
  }
`;
const BoardListTitle = styled.div`
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 1.5;
  span {
    display: block;
    margin-bottom: 2px;
    font-size: 14px;
    color: ${theme.colors.point};
  }
`;
const BoardListDate = styled.div`
  font-size: 13px;
  color: #94999a;
`;
