import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'shared/styles/theme';

export default function Button({ size, theme, children, ...props }) {
  return (
    <StyledButton size={size} theme={theme} {...props}>
      {children}
    </StyledButton>
  );
}

// styled-components
const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 7px;
  border: none;
  border-radius: 4px;
  line-height: 1.4;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  -webkit-appearance: none;
  outline: none;
  transition: all 0.1s linear;
  cursor: pointer;

  /* theme */
  ${props =>
    props.theme === 'point' &&
    css`
      background-color: ${theme.colors.point};
      color: #fff;
      ${props.status === 'disabled' &&
      css`
        color: #fff;
        background-color: #bfe1f3;
        pointer-events: none;
      `}
    `}
  ${props =>
    props.theme === 'point3-fff' &&
    css`
      background-color: ${theme.colors.point3};
      color: #fff;
    `}
  ${props =>
    props.theme === 'point3-blue' &&
    css`
      background-color: ${theme.colors.point3};
      color: ${theme.colors.point};
      ${props.status === 'disabled' &&
      css`
        color: #fff;
        background-color: #bfe1f3;
        pointer-events: none;
      `}
    `}
  ${props =>
    props.theme === 'trans-gray' &&
    css`
      background-color: rgba(0, 0, 0, 0.6);
      color: #fff;
    `}
 

  /* size */
  ${props =>
    props.size === 'xs' &&
    css`
      width: 48px;
      height: 30px;
      font-size: 14px;
    `}
  ${props =>
    props.size === 'sm' &&
    css`
      width: auto;
      height: 30px;
      font-size: 14px;
      padding: 7px 12px;
    `}
  ${props =>
    props.size === 'full-md' &&
    css`
      width: 100%;
      height: 48px;
      font-size: 14px;
    `}
  ${props =>
    props.size === 'full-lg' &&
    css`
      width: 100%;
      height: 48px;
      font-size: 16px;
    `}
  ${props =>
    props.size === 'full-xl' &&
    css`
      width: 100%;
      height: 56px;
      font-size: 18px;
    `}
`;
