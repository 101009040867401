import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isNavShow: false,
};

const rootNavSlice = createSlice({
  name: 'nav',
  initialState,
  reducers: {
    navToggle: (state, actions) => {
      state.isNavShow = actions.payload;
    },
  },
});

export const { navToggle } = rootNavSlice.actions;

export default rootNavSlice.reducer;
