import Modal from 'react-modal';

import styled from '@emotion/styled';
import CommonStyledComponents from 'shared/styles/CommonStyledComponents';

const { Button } = CommonStyledComponents;

export default function AlertModal({
  modalClose,
  modalSubmit,
  message,
  buttonText,
  onRequestClose,
}) {
  return (
    <Modal
      isOpen
      style={customStyles}
      onRequestClose={onRequestClose ? modalClose : null}
    >
      <StyleAlertModal>
        <Message>{message}</Message>
        <Buttons>
          <Button
            type="submit"
            size={'full-md'}
            theme={'point'}
            onClick={modalSubmit}
          >
            {buttonText && buttonText.submit ? buttonText.submit : '확인'}
          </Button>
        </Buttons>
      </StyleAlertModal>
    </Modal>
  );
}

const customStyles = {
  overlay: {
    position: 'fixed',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: '10000',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: 'calc(100% - 30px)',
    maxWidth: '290px',
    padding: '0',
    borderRadius: '4px',
    transform: 'translate(-50%, -50%)',
    border: 'none',
  },
};

const StyleAlertModal = styled.div`
  padding: 10px;
`;

const Message = styled.div`
  padding: 30px 0;
  font-size: 16px;
  line-height: 1.5;
  white-space: pre-wrap;
  text-align: center;
`;

const Buttons = styled.div`
  
`;
