import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { getSettings } from 'redux/root/slices/settings';

import CommonStyledComponents from 'shared/styles/CommonStyledComponents';

import PageLayout from 'components/PageLayout';

const { Area } = CommonStyledComponents;

export default function Privacy() {
  const dispatch = useDispatch();
  const { terms_privacy } = useSelector(state => state.settings.settingInfo);

  useEffect(() => {
    dispatch(getSettings('terms'));
  }, []);

  return (
    <PageLayout title="개인정보처리방침" backBtn>
      <Area>
        {terms_privacy ? (
          <div
            className={'ck-content'}
            dangerouslySetInnerHTML={{ __html: terms_privacy.value }}
          ></div>
        ) : null}
      </Area>
    </PageLayout>
  );
}
