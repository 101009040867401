export const BoardListsData = {
  1: {
    title: '공지사항',
    write: 'y',
    sorting: [{ title: '개수정렬', column: 'sorting_num' }],
    options: [
      { title: '상태변경', column: 'status' },
      { title: '선택삭제', column: 'delete' },
    ],
    table_list: [
      { title: '선택', column: 'checkbox', width: '80px' },
      { title: '번호', column: 'idx', width: '80px' },
      { title: '제목', column: 'title', width: '90%' },
      { title: '작성일', column: 'createdAt', width: '30%' },
      { title: '게시 상태', column: 'status', width: '20%' },
      { title: '작성자', column: 'write_name', width: '20%' },
      { title: '조회수', column: 'count', width: '15%' },
    ],
  },
  2: {
    title: '자주하는질문',
    write: 'y',
    sorting: [{ title: '개수정렬', column: 'sorting_num' }],
    options: [
      { title: '상태변경', column: 'status' },
      { title: '선택삭제', column: 'delete' },
    ],
    table_list: [
      { title: '선택', column: 'checkbox', width: '80px' },
      { title: '번호', column: 'idx', width: '80px' },
      { title: '제목', column: 'title', width: '*' },
      { title: '작성일', column: 'createdAt', width: '228px' },
      { title: '게시 상태', column: 'status', width: '160px' },
    ],
  },
};

export const BoardWriteData = {
  1: {
    title: '공지사항',
    write_list: [
      // {
      //   title: '게시 타입',
      //   column: 'type',
      //   required: true,
      //   data: ['일반글', '공지글'],
      // },
      {
        title: '게시 상태',
        column: 'status',
        required: true,
        data: ['미등록', '등록'],
      },
      {
        title: '제목',
        column: 'title',
        required: true,
        placeholder: '제목을 입력하세요',
      },
      {
        title: '내용',
        column: 'content',
        required: true,
        placeholder: '제목을 입력하세요',
      },
    ],
    info_list_show: true,
    info_list: [
      {
        title: '작성자 이름',
        column: 'write_name',
        required: true,
        placeholder: '작성자 이름을 입력하세요',
      },
    ],
  },
  2: {
    title: '자주하는질문',
    write_list: [
      {
        title: '게시 상태',
        column: 'status',
        required: true,
        data: ['미등록', '등록'],
      },
      {
        title: '제목',
        column: 'title',
        required: true,
        placeholder: '제목을 입력하세요',
      },
      {
        title: '내용',
        column: 'content',
        required: true,
        placeholder: '제목을 입력하세요',
      },
    ],
    info_list_show: false,
    info_list: [
      {
        title: '작성자 이름',
        column: 'write_name',
        required: true,
        placeholder: '작성자 이름을 입력하세요',
      },
      // {
      //   title: '등록일',
      //   column: 'createdAt',
      //   required: true,
      //   placeholder: '등록일을 입력하세요',
      // },
    ],
  },
};
