import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'shared/root/styles/theme';

const Layout = styled.div`
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(35, 44, 81, 0.05);
  background-color: #fff;
  min-width: 984px; // 반응형
  & + & {
    margin-top: 25px;
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  min-height: 60px;
  padding: 12px 25px;
  //셀렉트박스 예외처리(select)
  [class*='-singleValue'] {
    font-weight: 700;
  }
`;
const HeaderTitle = styled.div`
  flex: none;
  font-size: 16px;
  line-height: 1.38;
  font-weight: 700;
  color: #323232;
`;
const HeaderCount = styled.div`
  flex: none;
  margin-left: 15px;
  font-size: 14px;
  color: #6c7293;
  em {
    color: #0b6fe8;
    font-weight: 500;
  }
`;
const HeaderRequired = styled.div`
  flex: none;
  position: relative;
  margin-left: 15px;
  padding-left: 10px;
  font-size: 14px;
  color: #0b6fe8;
  &::before {
    content: '';
    position: absolute;
    top: 6px;
    left: 0;
    width: 5px;
    height: 5px;
    background-color: #0b6fe8;
    border-radius: 50%;
  }
`;
const Body = styled.div`
  border-top: 1px solid #e3e4eb;
`;
const HeaderButtons = styled.div`
  flex: none;
  display: grid;
  justify-content: end;
  grid-template-columns: repeat(auto-fit, minmax(74px, auto));
  column-gap: 8px;
  row-gap: 8px;
  width: 70%;
  margin-left: auto;
`;
const HeaderSorting = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
`;

export const Card = {
  Layout,
  Header,
  HeaderTitle,
  HeaderCount,
  HeaderRequired,
  HeaderButtons,
  HeaderSorting,
  Body,
};
