import styled from '@emotion/styled';
import React from 'react';
import Paragraph from './Paragraph';
import CommonStyledComponents from 'shared/styles/CommonStyledComponents';
import TextInput from 'components/Form/TextInput';
import { useForm } from 'react-hook-form';
import { getVerifyId, postUsersSignup } from 'api/user';
import { openModal } from 'redux/common/slices/modals';
import { useDispatch } from 'react-redux';
import ToastMessage from 'components/Modals/ToastMessage';
import { PASSWORD_REGEXP, PASSWORD_VALID_MESSAGE } from 'shared/constants/constants';

const { Button, FormField, FieldContainer, FieldLabel } =
  CommonStyledComponents;

const initialValues = {
  id: '',
  name: '',
  status: '2',
  email: '',
  phone: '',
  memo: '',
  password: '',
  type: 'default',
  group: '3',
  place_idx: '',
  isAdmin: false,
};

export const SignupField = ({ handleNext }) => {
  const dispatch = useDispatch();

  const {
    register,
    watch,
    setError,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: initialValues,
  });

  //회원등록
  const onSubmit = async data => {
    const {
      idx,
      status,
      group,
      place_idx,
      user_group,
      admin_group,
      updatedAt,
      createdAt,
      isAdmin,
      ...restData
    } = data;
    const statusValue = String(status);
    const newData = {
      ...restData,
      status: statusValue,
      group: '3',
    };
    delete newData.camparePassword;

    try {
      const verifyId = await getVerifyId(newData.id);
      if (verifyId) {
        // 성공 시 아이디 중복 조회된다는 뜻
        dispatch(
          openModal({
            Component: ToastMessage,
            props: {
              iconImg: 'fail',
              message: '이미 사용중인 아이디입니다.',
            },
          }),
        );
        setError('id', { message: '이미 사용중인 아이디입니다.' });
        return;
      }
    } catch (error) {}

    try {
      const res = await postUsersSignup(newData);

      if (res) {
        dispatch(
          openModal({
            Component: ToastMessage,
            props: {
              iconImg: 'complete',
              message: '회원정보가 등록되었습니다.',
            },
          }),
        );
        handleNext('complete');
      }
    } catch (error) {
      dispatch(
        openModal({
          Component: ToastMessage,
          props: {
            iconImg: 'fail',
            message: error.response.data.message,
          },
        }),
      );
    }
  };

  //저장버튼 클릭 form전송
  function handleClickSubmit(data) {
    onSubmit(data);
  }

  return (
    <SignupFieldContainer>
      <Paragraph>회원정보를 입력해주세요.</Paragraph>
      <FormField align="vertical">
        <FieldLabel>아이디</FieldLabel>
        <FieldContainer>
          <TextInput
            register={register('id', {
              required: '아이디를 입력해주세요.',
              maxLength: {
                value: 16,
                message: '아이디는 16자 이하로만 작성이가능합니다.',
              },
            })}
            errors={errors}
            placeholder="아이디"
          />
        </FieldContainer>
      </FormField>

      <FormField align="vertical">
        <FieldLabel>이름</FieldLabel>
        <FieldContainer>
          <TextInput
            register={register('name', {
              required: '이름을 입력해주세요.',
            })}
            errors={errors}
            placeholder="이름"
          />
        </FieldContainer>
      </FormField>

      <FormField align="vertical">
        <FieldLabel>이메일</FieldLabel>
        <FieldContainer>
          <TextInput
            register={register('email', {
              required: '이메일을 입력해주세요.',
              pattern: {
                value:
                  /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/,
                message: '올바른 이메일 주소만 입력 가능합니다',
              },
            })}
            errors={errors}
            placeholder="이메일"
          />
        </FieldContainer>
      </FormField>

      <FormField align="vertical">
        <FieldLabel>비밀번호</FieldLabel>
        <FieldContainer>
          <TextInput
            type="password"
            register={register('password', {
              required: '비밀번호를 입력해주세요.',
              minLength: {
                value: 8,
                message: PASSWORD_VALID_MESSAGE,
              },
              maxLength: {
                value: 12,
                message: PASSWORD_VALID_MESSAGE,
              },
              pattern: {
                value: PASSWORD_REGEXP,
                message: PASSWORD_VALID_MESSAGE,
              },
            })}
            errors={errors}
            placeholder="비밀번호"
          />
        </FieldContainer>
      </FormField>

      <FormField align="vertical">
        <FieldLabel>비밀번호 확인</FieldLabel>
        <FieldContainer>
          <TextInput
            type="password"
            register={register('camparePassword', {
              required: '비밀번호를 입력해주세요.',
              validate: value =>
                value === watch('password') || '비밀번호가 일치하지 않습니다.',
            })}
            errors={errors}
            placeholder="비밀번호 확인"
          />
        </FieldContainer>
      </FormField>

      <ButtonWrapper>
        <Button
          type="button"
          size={'full-xl'}
          theme={'point'}
          status={!isValid && 'disabled'}
          onClick={handleSubmit(d => handleClickSubmit(d))}
        >
          다음
        </Button>
      </ButtonWrapper>
    </SignupFieldContainer>
  );
};

const SignupFieldContainer = styled.div``;

const ButtonWrapper = styled.div`
  margin-top: 30px;
`;
