import styled from '@emotion/styled';
import React from 'react';

export default function Description({ children }) {
  return <DescriptionContainer>{children}</DescriptionContainer>;
}

const DescriptionContainer = styled.p`
  color: var(--gray_300, #444);

  /* 14_regular */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
`;
