import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'shared/styles/theme';
import CommonStyledComponents from 'shared/styles/CommonStyledComponents';

const { ErrorMessage } = CommonStyledComponents;

export default function TextInput(props) {
  const name = props.register.name;

  return (
    <>
      {props.suffix && (
        <SuffixInput>
          <Input
            type={props.type ? props.type : 'text'}
            name={props.name ? props.name : ''}
            placeholder={props.placeholder}
            size={props.size}
            {...props}
            {...props.register}
          />
          <div>{props.suffix}</div>
        </SuffixInput>
      )}
      {!props.suffix && (
        <Input
          type={props.type ? props.type : 'text'}
          placeholder={props.placeholder}
          err={props.errors && props.errors[name] ? true : false}
          size={props.size}
          {...props}
          {...props.register}
        />
      )}
      {!props.customErrorMessage &&
        props.errors &&
        props.errors[name] &&
        props.errors[name].message && (
          <ErrorMessage>{props.errors[name].message}</ErrorMessage>
        )}
    </>
  );
}

const Input = styled.input`
  width: 100%;
  height: 48px;
  padding: 0 10px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.divider};
  font-size: 16px;
  color: ${theme.colors.font};
  &::placeholder {
    font-size: 16px;
    color: ${theme.colors.placeholder};
  }
  &:focus {
    border-color: ${theme.colors.point};
  }
  ${props =>
    props.err === true &&
    css`
      border-color: ${theme.colors.valid} !important;
    `}
`;

const SuffixInput = styled.div`
  position: relative;
  input[type='text'] {
    padding: 15px 30px 15px 10px;
  }
  div {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 16px;
    color: ${theme.colors.placeholder};
  }
`;
