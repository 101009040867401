import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { getSettings } from 'redux/root/slices/settings';

import CommonStyledComponents from 'shared/styles/CommonStyledComponents';

import PageLayout from 'components/PageLayout';

const { Area } = CommonStyledComponents;

export default function GuidePage() {
  const dispatch = useDispatch();
  const { guide } = useSelector(state => state.settings.settingInfo);

  useEffect(() => {
    dispatch(getSettings('guide'));
  }, []);

  return (
    <PageLayout title="사용 설명서" backBtn>
      <Area>
        {guide ? (
          <div
            className={'ck-content'}
            dangerouslySetInnerHTML={{ __html: guide.value }}
          ></div>
        ) : null}
      </Area>
    </PageLayout>
  );
}
