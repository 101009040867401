import Modal from 'react-modal';
import { useState, useEffect } from 'react';

import styled from '@emotion/styled';
import { css } from '@emotion/react';

import IconClose from 'assets/imgs/icon-close.svg';
import IconComplete from 'assets/imgs/icon-complete.svg';
import IconFail from 'assets/imgs/icon-fail.svg';

export default function ToastMessage({ modalClose, iconImg, message }) {
  const [modalShow, setModalShow] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setModalShow(false);
    }, 500);
  }, []);

  return (
    <Modal
      style={customStyles}
      isOpen={modalShow}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={1000}
      onAfterClose={modalClose}
    >
      <StyledToastMessage>
        <Message>
          <MessageIcon iconImg={iconImg} />
          {message}
        </Message>
        <CloseButton onClick={modalClose} />
      </StyledToastMessage>
    </Modal>
  );
}

const customStyles = {
  overlay: {
    position: 'fixed',
    backgroundColor: 'transparent',
    transition: 'opacity 0.5s',
    pointerEvents: 'none',
    zIndex: '10000',
  },
  content: {
    top: '60px',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: 'calc(100% - 30px)',
    maxWidth: '400px',
    padding: '0',
    borderRadius: '4px',
    border: 'none',
    transform: 'translateX(-50%)',
    backgroundColor: 'rgba(35, 44, 81, 0.7)',
    backdropFilter: 'blur(1px)',
    pointerEvents: 'visible',
  },
};

const StyledToastMessage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Message = styled.div`
  position: relative;
  padding: 17px 60px 17px 46px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.86;
  white-space: pre-wrap;
  color: #fff;
`;
const MessageIcon = styled.i`
  position: absolute;
  top: 21px;
  left: 17px;
  display: inline-block;
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  ${props =>
    props.iconImg === 'complete' &&
    css`
      background-image: url(${IconComplete});
    `}
  ${props =>
    props.iconImg === 'fail' &&
    css`
      background-image: url(${IconFail});
    `}
`;

const CloseButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 44px;
  height: 44px;
  background-image: url(${IconClose});
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  cursor: pointer;
`;
