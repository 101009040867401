import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from 'axios';

import {
  API_PLACE,
  API_DEFECT,
  API_DEFECT_PLACE,
} from 'shared/constants/constants';

// 관리자_현장별하자관리_리스트
export const getPlaceDefectList = createAsyncThunk(
  'auth/getPlaceDefectList',
  async (data, thunkAPI) => {
    const { take, page } = data;
    try {
      const response = await axios.get(
        `${API_PLACE}/defect?take=${take}&page=${page}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

// 관리자_하자관리_현장 리스트(하자등록 항목)
export const getDefectPlaceList = createAsyncThunk(
  'auth/getDefectPlaceList',
  async (data, thunkAPI) => {
    try {
      const { place, take, page } = data;
      const response = await axios.get(
        `${API_DEFECT_PLACE}?place=${place}&take=${take}&page=${page}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

// 관리자_하자관리_현장별 하자목록
export const getDefectList = createAsyncThunk(
  'auth/getDefectList',
  async (data, thunkAPI) => {
    const { placeIdx, take, page, order, sort, search } = data;

    let send_url = `${API_DEFECT}?place=${placeIdx}&take=${take}&page=${page}&order=${order}&sort=${sort}`;

    if (search) {
      for (const key in search) {
        if (search[key]) {
          send_url = send_url + `&search=${key}:${search[key]}`;
        }
      }
    }

    try {
      const response = await axios.get(`${send_url}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

// 관리자_하자관리_하자 상세
export const getDefectDetails = createAsyncThunk(
  'auth/getDefectDetails',
  async (dftIdx, thunkAPI) => {
    try {
      const response = await axios.get(`${API_DEFECT}/${dftIdx}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

const defectSlice = createSlice({
  name: 'defect',
  initialState: {
    placeDefectList: [],
    defectList: [],
    defectPlaceList: [],
    defectDetails: [],
  },
  reducers: {},
  extraReducers: {
    [getPlaceDefectList.fulfilled]: (state, action) => {
      state.placeDefectList = action.payload;
    },
    [getDefectList.fulfilled]: (state, action) => {
      state.defectList = action.payload;
    },
    [getDefectDetails.fulfilled]: (state, action) => {
      state.defectDetails = action.payload;
    },
    [getDefectPlaceList.fulfilled]: (state, action) => {
      state.defectPlaceList = action.payload;
    },
    [getDefectList.rejected]: (state, action) => {},
  },
});

export default defectSlice.reducer;
