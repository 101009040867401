import Modal from 'react-modal';

import { ChangeData } from 'redux/slices/selectionModal'; //function

import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { theme } from 'shared/styles/theme';

import closeIcon from 'assets/imgs/nav_icon_close.svg';

export default function SelectionModal({ modalClose, modalSubmit, data }) {
  const dispatch = useDispatch();
  const { name, title, item, defaultChecked } = data;
  return (
    <Modal isOpen style={customStyles} onRequestClose={modalClose}>
      <ModalselectionModal>
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
          <CloseButton onClick={modalClose}>
            <img src={closeIcon} alt="닫기" />
          </CloseButton>
        </ModalHeader>
        <ModalBody>
          <ScrollArea>
            <StyledRadioInputs>
              {item &&
                item.map((elem, index) => {
                  return (
                    <label key={index}>
                      <input
                        type="radio"
                        value={elem.value}
                        name={name}
                        autoFocus={elem.value === defaultChecked ? true : false}
                        defaultChecked={
                          elem.value === defaultChecked ? true : false
                        }
                        onClick={() => {
                          modalSubmit(name);
                          dispatch(
                            ChangeData({
                              selectedValue: {
                                name: name,
                                value: elem.value,
                              },
                            }),
                          );
                          modalClose();
                        }}
                      />
                      {elem.label}
                    </label>
                  );
                })}
            </StyledRadioInputs>
          </ScrollArea>
        </ModalBody>
      </ModalselectionModal>
    </Modal>
  );
}

const customStyles = {
  overlay: {
    position: 'fixed',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: '10000',
  },
  content: {
    top: 'auto',
    bottom: '0',
    right: 'auto',
    left: '50%',
    width: '100%',
    maxWidth: '414px',
    minHeight: '60vh',
    maxHeight: 'calc(100% - 95px)',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
    marginRight: '-50%',
    borderRadius: '4px',
    transform: 'translate(-50%, 0)',
    border: 'none',
  },
};

const ModalselectionModal = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 414px;
  min-height: 60vh;
  max-height: calc(100% - 95px);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #fff;
  transform: translateX(-50%);
`;
const ModalHeader = styled.div`
  flex: none;
  position: relative;
  height: 44px;
  padding: 0 ${theme.gutter.mobile};
`;
const ModalTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 14px;
  line-height: 1.5;
  color: #444;
`;
const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: 44px;
  height: 44px;
  cursor: pointer;
`;
const ModalBody = styled.div`
  flex: 1 1 100%;
  overflow-y: auto;
  padding-bottom: ${theme.notch.bottom};
`;
const ScrollArea = styled.div`
  padding: 10px ${theme.gutter.mobile} 44px;
`;

//임시추가
const StyledRadioInputs = styled.div`
  margin: -15px 0;
  label {
    display: flex;
    align-items: center;
    padding: 15px 0;
    cursor: pointer;
    font-size: 18px;
  }
  input[type='radio'] {
    position: relative;
    border: 1px solid ${theme.colors.placeholder};
    width: 20px;
    height: 20px;
    margin-right: 6px;
    border-radius: 50%;
    cursor: pointer;
    &:checked {
      background-color: ${theme.colors.point};
      border-color: ${theme.colors.point};
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #fff;
        transform: translate(-50%, -50%);
      }
    }
  }
`;
