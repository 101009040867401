import { useEffect, useState, useRef, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, Link } from 'react-router-dom';

import ReactMoment from 'react-moment';

import { getBoardLists } from 'redux/root/slices/board';
import { StatusChange } from 'api/board';

import { BoardListsData } from 'shared/root/boardSettingsData';
import { SortNumOptions, BoardStatusOptions } from 'shared/root/optionData';

import PageLayout from 'root/components/PageLayout';
import TitleContainer from 'root/components/TitleContainer';
import ContentsContainer from 'root/components/ContentsContainer';
import NoResult from 'root/components/NoResult';

import ConfirmModal from 'root/components/Modals/ConfirmModal';
import ToastMessage from 'root/components/Modals/ToastMessage';
import { openModal } from 'redux/common/slices/modals';

import usePagination from 'hooks/usePagination';

import CommonStyledComponents from 'shared/root/styles/CommonStyledComponents';
import { CustomSelect, styleDefault } from 'shared/root/styles/common/Select';

const { Card, Table, Input, Button, ButtonsGrid } = CommonStyledComponents;

export default function BoardList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const selectInputRef = useRef();
  const [statusValue, setStatusValue] = useState('');
  const [sortNumValue, setSortNumValue] = useState(SortNumOptions[0]);

  const tableLists = useSelector(state => state.rootBoard.boardLists);
  const boardSettingsData = BoardListsData[params.bdIdx];

  // 페이지네이션
  const {
    paginationData,
    PaginationComponent,
    handleChangeSorting,
    setPaginationIndex,
  } = usePagination({
    target: tableLists,
  });

  const initialValues = {
    status: '',
    checkedLists: [],
  };

  const { register, handleSubmit, control, reset, watch, setValue } = useForm({
    defaultValues: initialValues,
  });

  const setTableLists = useCallback(
    data => {
      dispatch(
        getBoardLists({
          bdIdx: params.bdIdx, //고정값
          take: data.sorting,
          page: data.page,
        }),
      );
    },
    [dispatch, params],
  );

  // 체크박스 전체 선택
  const handleAllCheckedList = checked => {
    if (checked) {
      const arr = [];
      tableLists.results.forEach(el => arr.push(String(el.idx)));
      setValue('checkedLists', arr);
    } else {
      setValue('checkedLists', []);
    }
  };

  // 상태변경
  const onSubmit = (data, status) => {
    console.log(status);
    if (data.checkedLists.length !== 0) {
      dispatch(
        openModal({
          Component: ConfirmModal,
          props: {
            message:
              status === '삭제'
                ? '선택하신 항목을 삭제하시겠습니까?'
                : `선택하신 항목을 ${status}(으)로 변경하시겠습니까?`,
            handleModalSubmit: () => {
              StatusChange({
                status: data.status,
                bc_idxs: data.checkedLists,
              })
                .then(response => {
                  setTableLists(paginationData);
                  dispatch(
                    openModal({
                      Component: ToastMessage,
                      props: {
                        iconImg: 'complete',
                        message: '게시글이 수정되었습니다.',
                      },
                    }),
                  );
                })
                .catch(error => {
                  console.log(error);
                  dispatch(
                    openModal({
                      Component: ToastMessage,
                      props: {
                        iconImg: 'fail',
                        message: error.message, //'실패했습니다',
                      },
                    }),
                  );
                });
              reset(initialValues);
            },
            handleModalClose: () => {
              handleSelectReset();
            },
          },
        }),
      );
    } else {
      dispatch(
        openModal({
          Component: ToastMessage,
          props: {
            iconImg: 'fail',
            message: '항목을 선택해 주세요.',
          },
        }),
      );
      handleSelectReset();
    }
  };

  // 삭제
  const handleClickDelete = e => {
    onSubmit(watch(), '삭제');
  };

  // 개별 상태변경(셀렉트박스)
  function changeSingleStatus(value, action, idx) {
    console.log(value, action, idx);
    StatusChange({
      status: value.value,
      bc_idxs: [idx],
    })
      .then(response => {
        setTableLists(paginationData);
        dispatch(
          openModal({
            Component: ToastMessage,
            props: {
              iconImg: 'complete',
              message: '게시글이 수정되었습니다.',
            },
          }),
        );
      })
      .catch(error => {
        dispatch(
          openModal({
            Component: ToastMessage,
            props: {
              iconImg: 'fail',
              message: '실패했습니다',
            },
          }),
        );
      });
  }

  // 일괄 상태변경(체크박스)
  const changeMultiStatus = e => {
    setStatusValue(e);
    setValue('status', e.value);

    const data =
      BoardStatusOptions[
        BoardStatusOptions.findIndex(obj => obj.value === `${e.value}`)
      ].label;

    onSubmit(watch(), data);
  };

  // 상태 리셋
  const handleStateReset = e => {
    reset(initialValues);
  };

  // 셀렉트박스 리셋
  const handleSelectReset = e => {
    setStatusValue('');
    setSortNumValue(SortNumOptions[0]);
  };

  // 테이블 리스트 불러오기
  useEffect(() => {
    setTableLists(paginationData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, paginationData.page, paginationData.sorting]);

  // 게시판 진입, 페이지 네이션 클릭 시 리셋함수 호출
  useEffect(() => {
    handleStateReset();
    handleSelectReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, paginationData.page]);

  return (
    <PageLayout>
      <TitleContainer>
        <h2>
          <span>게시판</span>
          <b>{boardSettingsData.title}</b>
        </h2>
        <ButtonsGrid>
          <Link to={`/root/board/write/${params.bdIdx}`}>
            <Button theme={'point'} size={'md'}>
              추가하기
            </Button>
          </Link>
        </ButtonsGrid>
      </TitleContainer>

      <ContentsContainer>
        <Card.Layout>
          <Card.Header>
            <Card.HeaderTitle>게시물 목록</Card.HeaderTitle>
            <Card.HeaderCount>
              총 <em>{tableLists.total ? tableLists.total : 0}</em>개
            </Card.HeaderCount>
            <Card.HeaderSorting>
              <CustomSelect
                name="sortNum"
                styles={styleDefault}
                options={SortNumOptions}
                value={sortNumValue}
                isSearchable={false}
                onChange={e => {
                  handleChangeSorting(e);
                  setSortNumValue(e);
                  handleStateReset();
                }}
              />
            </Card.HeaderSorting>
          </Card.Header>
          <Card.Body>
            <form method="post" onSubmit={handleSubmit(onSubmit)}>
              {boardSettingsData.options ? (
                <Table.Options>
                  <div>
                    {boardSettingsData.options.map((item, itemIdx) => {
                      if (item.column === 'status') {
                        return (
                          <CustomSelect
                            key={item.column}
                            ref={selectInputRef}
                            name="status"
                            placeholder={'게시상태 변경'}
                            onChange={changeMultiStatus}
                            styles={styleDefault}
                            value={statusValue}
                            isSearchable={false}
                            options={BoardStatusOptions}
                          />
                        );
                      } else if (item.column === 'delete') {
                        return (
                          <Button
                            key={item.column}
                            type="button"
                            size={'md'}
                            theme={'point2'}
                            status={
                              watch().checkedLists.length > 0 ? '' : 'disabled'
                            }
                            onClick={handleClickDelete}
                          >
                            선택삭제
                          </Button>
                        );
                      } else {
                        return <></>;
                      }
                    })}
                  </div>
                </Table.Options>
              ) : null}
              <Table.Container layout={'fixed'}>
                <Table.Layout>
                  <colgroup>
                    {boardSettingsData.table_list.map((col, colIdx) => {
                      return (
                        <col
                          key={colIdx}
                          style={{ width: col.width ? col.width : '*' }}
                        />
                      );
                    })}
                  </colgroup>
                  <thead>
                    <Table.Tr>
                      {boardSettingsData.table_list.map((th, thIdx) => {
                        if (th.column === 'checkbox') {
                          return (
                            <Table.Th key={th.column} type={th.column}>
                              <Input.Checkbox>
                                <input
                                  type="checkbox"
                                  onChange={e =>
                                    handleAllCheckedList(e.target.checked)
                                  }
                                  checked={
                                    paginationData.sorting &&
                                    tableLists.pageTotal &&
                                    (watch().checkedLists.length ===
                                      paginationData.sorting ||
                                      watch().checkedLists.length >=
                                        tableLists.pageTotal)
                                      ? true
                                      : false
                                  }
                                />
                              </Input.Checkbox>
                            </Table.Th>
                          );
                        } else {
                          return (
                            <Table.Th key={th.column} type={th.column}>
                              {th.title}
                            </Table.Th>
                          );
                        }
                      })}
                    </Table.Tr>
                  </thead>
                  <tbody>
                    {Array.isArray(tableLists.results) &&
                    tableLists.results.length === 0 ? (
                      <Table.Tr noHover>
                        <Table.Td colSpan={boardSettingsData.table_list.length}>
                          <NoResult />
                        </Table.Td>
                      </Table.Tr>
                    ) : (
                      Array.isArray(tableLists.results) &&
                      tableLists.results.map((item, itemIdx) => (
                        <Table.Tr key={itemIdx} type={'link'}>
                          {boardSettingsData.table_list.map((td, tdIdx) => {
                            if (td.column === 'checkbox') {
                              return (
                                <Table.Td key={td.column} type={td.column}>
                                  <Input.Checkbox>
                                    <input
                                      type="checkbox"
                                      value={item.idx}
                                      {...register('checkedLists')}
                                    />
                                  </Input.Checkbox>
                                </Table.Td>
                              );
                            } else if (td.column === 'idx') {
                              return (
                                <Table.Td key={td.column} type={td.column}>
                                  {setPaginationIndex(itemIdx)}
                                </Table.Td>
                              );
                            } else if (td.column === 'title') {
                              return (
                                <Table.Td
                                  key={td.column}
                                  type={td.column}
                                  onClick={() => {
                                    navigate(
                                      `/root/board/details/${item.bd_idx}/${item.idx}`,
                                    );
                                  }}
                                >
                                  <span>{item.title}</span>
                                </Table.Td>
                              );
                            } else if (td.column === 'createdAt') {
                              return (
                                <Table.Td key={td.column} type={td.column}>
                                  <ReactMoment
                                    date={item.createdAt}
                                    format="YYYY-MM-DD&nbsp;&nbsp;HH:mm:ss"
                                  />
                                </Table.Td>
                              );
                            } else if (td.column === 'status') {
                              return (
                                <Table.Td key={td.column} type={'statusSelect'}>
                                  <Controller
                                    name=""
                                    control={control}
                                    defaultValue={
                                      BoardStatusOptions[
                                        BoardStatusOptions.findIndex(
                                          obj => obj.value === `${item.status}`,
                                        )
                                      ]
                                    }
                                    render={({ field }) => (
                                      <CustomSelect
                                        {...field}
                                        styles={styleDefault}
                                        isSearchable={false}
                                        options={BoardStatusOptions}
                                        value={
                                          BoardStatusOptions[
                                            BoardStatusOptions.findIndex(
                                              obj =>
                                                obj.value === `${item.status}`,
                                            )
                                          ]
                                        }
                                        onChange={(
                                          val,
                                          action,
                                          idx = `${item.idx}`,
                                        ) =>
                                          changeSingleStatus(val, action, idx)
                                        }
                                        menuPortalTarget={document.querySelector(
                                          'body',
                                        )}
                                      />
                                    )}
                                  />
                                </Table.Td>
                              );
                            } else {
                              return (
                                <Table.Td key={td.column} type={td.column}>
                                  {item[td.column]}
                                </Table.Td>
                              );
                            }
                          })}
                        </Table.Tr>
                      ))
                    )}
                  </tbody>
                </Table.Layout>
              </Table.Container>
              {boardSettingsData.options ? (
                <Table.Options>
                  <div>
                    {boardSettingsData.options.map((item, itemIdx) => {
                      if (item.column === 'status') {
                        return (
                          <CustomSelect
                            name="status"
                            key={item.column}
                            ref={selectInputRef}
                            styles={styleDefault}
                            options={BoardStatusOptions}
                            value={statusValue}
                            placeholder={'게시상태 변경'}
                            isSearchable={false}
                            onChange={changeMultiStatus}
                          />
                        );
                      } else if (item.column === 'delete') {
                        return (
                          <Button
                            key={item.column}
                            type="button"
                            size={'md'}
                            theme={'point2'}
                            status={
                              watch().checkedLists.length > 0 ? '' : 'disabled'
                            }
                            onClick={handleClickDelete}
                          >
                            선택삭제
                          </Button>
                        );
                      } else {
                        return <></>;
                      }
                    })}
                  </div>
                </Table.Options>
              ) : null}
            </form>
            {paginationData.total ? (
              <Table.Pagination>
                <PaginationComponent />
              </Table.Pagination>
            ) : null}
          </Card.Body>
        </Card.Layout>
      </ContentsContainer>
    </PageLayout>
  );
}
