import axios from 'axios';
import { Navigate, Outlet } from 'react-router-dom';

const useAuth = () => {
  const token = localStorage.getItem('admin_access_token');
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return true;
  } else {
    return false;
  }
};

export default function ProtectedRoutes() {
  const auth = useAuth();

  return auth ? <Outlet /> : <Navigate to="/root/login" />;
  //Outlet을 통해서 상위의 컴포넌트를 레이아웃화
}
