import styled from '@emotion/styled';
import { theme } from 'shared/root/styles/theme';

import { useDispatch, useSelector } from 'react-redux';
import { navToggle } from 'redux/root/slices/nav';
import IconHamburger from 'root/assets/imgs/icon_hamburger.svg';
import IconArrow from 'root/assets/imgs/select_icon_arrow.svg';

export default function TitleContainer({ children }) {
  const dispatch = useDispatch();
  const { isNavShow } = useSelector(state => state.rootSideNav);

  const handleClickNavToggle = () => {
    if (isNavShow) {
      dispatch(navToggle(false));
    } else {
      dispatch(navToggle(true));
    }
  };

  return (
    <StyledTitleContainer>
      <HamburgerButton onClick={handleClickNavToggle} />
      <Inner>{children}</Inner>
    </StyledTitleContainer>
  );
}

const StyledTitleContainer = styled.div`
  /* position: sticky; */
  position: fixed;
  top: 0;
  width: 100%;
  height: 68px;
  padding-right: 30px;
  padding-left: 68px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(35, 44, 81, 0.03);
  z-index: 1000;
  ${theme.mq.xxl} {
    width: calc(100% - 274px);
    padding-left: 30px;
  }
`;
const HamburgerButton = styled.button`
  position: absolute;
  left: 0;
  top: 0;
  width: 68px;
  height: 68px;
  background-image: url(${IconHamburger});
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: auto;
  cursor: pointer;
  ${theme.mq.xxl} {
    display: none;
  }
`;
const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  //페이지 브레드크럼
  h2 {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #6c7293;
    > * {
      flex: none;
      display: flex;
      align-items: center;
      &::after {
        content: '';
        display: block;
        margin: 0 5px;
        width: 12px;
        height: 12px;
        background-image: url(${IconArrow});
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: auto;
      }
    }
    > span:last-of-type::after {
      display: none;
    }
    b {
      &::before {
        content: '';
        display: block;
        margin: 0 5px;
        width: 12px;
        height: 12px;
        background-image: url(${IconArrow});
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: auto;
      }
    }
    &.active,
    b {
      font-weight: 600;
      color: #323232;
      &::after {
        display: none;
      }
    }
  }
`;
