import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { getBoardDetails } from 'redux/slices/board';

import PageLayout from 'components/PageLayout';

import ReactMoment from 'react-moment';

import styled from '@emotion/styled';

import { theme } from 'shared/styles/theme';

import CommonStyledComponents from 'shared/styles/CommonStyledComponents';

const { Area } = CommonStyledComponents;

export default function NoticeDetails() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [content, setContent] = useState();
  const bc_idx = useParams().bcIdx;

  const boardData = {
    bdIdx: location.state,
    bcIdx: bc_idx,
  };

  useEffect(() => {
    dispatch(getBoardDetails(boardData))
      .then(res => {
        setContent(res.payload);
      })
      .catch(error => {
        console.log(error);
      });
  }, [dispatch]);

  return (
    <PageLayout title="공지사항" backBtn>
      {content && content ? (
        <>
          <TitleItem>
            <Area>
              {content.type === 1 ? <NoticeBadge>공지사항</NoticeBadge> : null}
              <Title>{content.title}</Title>
              <Date>
                <ReactMoment date={content.createdAt} format="YYYY-MM-DD" />
              </Date>
            </Area>
          </TitleItem>
          <ContentsItem>
            <Area>
              <div
                className={'ck-content'}
                dangerouslySetInnerHTML={{ __html: content.content }}
              ></div>
            </Area>
          </ContentsItem>
        </>
      ) : null}
    </PageLayout>
  );
}

const TitleItem = styled.a`
  display: block;
  padding: 20px 0;
  border-bottom: 1px solid #ddd;
  &:first-of-type {
    padding-top: 0;
  }
`;
const NoticeBadge = styled.p`
  display: block;
  margin-bottom: 2px;
  font-size: 14px;
  line-height: 1.5;
  color: ${theme.colors.point};
`;
const Title = styled.div`
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 1.5;
`;
const Date = styled.div`
  font-size: 13px;
  color: #94999a;
`;
const ContentsItem = styled.div`
  padding: 20px 0;
  font-size: 16px;
  line-height: 1.5;
`;
