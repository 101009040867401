import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { API_USERS_ADMIN, TOKEN_ADMIN } from 'shared/constants/constants';

// 일반회원_목록
export const getUsersList = createAsyncThunk(
  'get/UsersList',
  async (PageData, thunkAPI) => {
    const { takeNum, activePage } = PageData;
    try {
      const response = await axios.get(
        `${API_USERS_ADMIN}/?take=${takeNum}&page=${activePage}`);
      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue();
    }
  },
);

// 관리자_목록
export const getAdminUsersList = createAsyncThunk(
  'get/AdminUsersList',
  async (PageData, thunkAPI) => {
    const { takeNum, activePage } = PageData;
    try {
      const response = await axios.get(
        `${API_USERS_ADMIN}/admin?take=${takeNum}&page=${activePage}`);
      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue();
    }
  },
);

const rootUserSlice = createSlice({
  name: 'user',
  initialState: {
    admin: null,
    usersList: [],
    adminUsersList: [],
    adminUserDetails: [],
  },
  reducers: {
    SET_ADMIN: (state, action) => {
      state.admin = action.payload;
    },
  },
  extraReducers: {
    [getUsersList.fulfilled]: (state, action) => {
      state.usersList = action.payload;
    },
    [getAdminUsersList.fulfilled]: (state, action) => {
      state.adminUsersList = action.payload;
    },
  },
});

export const { SET_ADMIN } = rootUserSlice.actions;
export default rootUserSlice.reducer;
