import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { BrowserView, MobileView, isAndroid, isIOS } from 'react-device-detect';

import { windowOpen } from 'utils';

import { DELETE_TOKEN } from 'redux/slices/token';
import { openModal } from 'redux/common/slices/modals';
import { getPlaceDetails } from 'redux/root/slices/place';
import { getSettings } from 'redux/root/slices/settings';

import PageLayout from 'components/PageLayout';
import ConfirmModal from 'components/Modals/ConfirmModal';

import IconLinkArrow from 'assets/imgs/icon_link_arrow.svg';

import styled from '@emotion/styled';
import CommonStyledComponents from 'shared/styles/CommonStyledComponents';

import { db } from 'db';
import { useLiveQuery } from 'dexie-react-hooks';

const { Area, Baseline } = CommonStyledComponents;
const { defects } = db;

export default function Settings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector(state => state.user);
  const userPlaceDetails = useSelector(state => state.place.placeDetails.place);
  const { link_appstore, link_playstore } = useSelector(
    state => state.settings.settingInfo,
  );

  // signup_type : 0 (관리자부여) | 1 (일반회원가입)
  const isApproved = user?.signup_type === 0;

  // 전송 실패 리스트
  const failLists = useLiveQuery(
    () => defects.where('submit_status').equals(0).toArray(),
    [],
  );

  useEffect(() => {
    user && dispatch(getPlaceDetails(user.place_idx));
  }, [dispatch, user]);

  useEffect(() => {
    dispatch(getSettings('link'));
  }, [dispatch]);

  const confirmLogout = () => {
    if (failLists?.length) {
      dispatch(
        openModal({
          Component: ConfirmModal,
          props: {
            message:
              '전송되지 않은 항목이 있습니다.\n로그아웃 시 내용이 삭제될 수 있습니다.\n로그아웃 하시겠습니까?',
            buttonText: {
              cancel: '취소',
              submit: '로그아웃',
            },
            handleModalSubmit: () => {
              handleLogout();
            },
          },
        }),
      );
    } else {
      dispatch(
        openModal({
          Component: ConfirmModal,
          props: {
            message: '로그아웃 하시겠습니까?',
            buttonText: {
              cancel: '취소',
              submit: '로그아웃',
            },
            handleModalSubmit: () => {
              handleLogout();
            },
          },
        }),
      );
    }
  };

  const handleLogout = () => {
    dispatch(DELETE_TOKEN());
    localStorage.removeItem('access_token');
    localStorage.removeItem('placeIdx');
    localStorage.removeItem('prevInfo');
    localStorage.removeItem('uuid');
    return navigate('/');
  };

  return (
    <PageLayout title="설정" backBtn>
      <AccountItem>
        <Area>
          {user && (
            <>
              <AccountId>{user.id}</AccountId>
              {isApproved ? (
                <AccountSite>
                  <b>{userPlaceDetails ? userPlaceDetails.name : null}</b> 담당
                </AccountSite>
              ) : (
                <AccountSite>
                  <a href="/user-info">회원정보수정</a>
                </AccountSite>
              )}
            </>
          )}
        </Area>
      </AccountItem>

      <Baseline />

      <SettingsMenuLink to="/guide" iconarrow="true">
        <Area>사용 설명서</Area>
      </SettingsMenuLink>
      <SettingsMenuLink to="/notice" iconarrow="true">
        <Area>공지사항</Area>
      </SettingsMenuLink>
      <SettingsMenuLink to="/faq" iconarrow="true">
        <Area>자주 묻는 질문</Area>
      </SettingsMenuLink>
      <SettingsMenuLink to="/terms" iconarrow="true">
        <Area>법률 정보 및 이용약관</Area>
      </SettingsMenuLink>

      <SettingsMenuButton
        iconarrow="true"
        onClick={() => {
          BrowserView && windowOpen(link_playstore.value);
          MobileView && isIOS && windowOpen(link_appstore.value);
          MobileView && isAndroid && windowOpen(link_playstore.value);
        }}
      >
        <Area>
          <AppItem>
            <p>앱정보</p>
            <AppVersion>1.0.0</AppVersion>
          </AppItem>
        </Area>
      </SettingsMenuButton>

      <Baseline />

      <SettingsMenuButton onClick={confirmLogout}>
        <Area>로그아웃</Area>
      </SettingsMenuButton>
    </PageLayout>
  );
}

const SettingsMenu = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
  height: 56px;
  padding-right: 16px;
  font-size: 16px;
  color: #000;
  cursor: pointer;
  background: ${props =>
    props.iconarrow
      ? `url(${IconLinkArrow}) no-repeat top 21px right 16px / 12px`
      : 'none'};
`;
const SettingsMenuLink = SettingsMenu.withComponent(Link);
const SettingsMenuButton = SettingsMenu.withComponent('button');

const AccountItem = styled.div`
  padding-bottom: 15px;
`;
const AccountId = styled.p`
  margin-bottom: 6px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.38;
`;
const AccountSite = styled.p`
  font-size: 14px;
  line-height: 1.2;
  color: #94999a;
  a {
    text-decoration: underline;
  }
`;
const AppItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const AppVersion = styled.p`
  color: #0089cf;
  padding: 0 5px;
`;
