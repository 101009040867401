import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'shared/root/styles/theme';
import CommonStyledComponents from 'shared/styles/CommonStyledComponents';

const { ErrorMessage } = CommonStyledComponents;

export default function Textarea(props) {
  const name = props.register.name;

  return (
    <>
      <StyledTextarea
        type={props.type ? props.type : 'text'}
        placeholder={props.placeholder}
        err={props.errors[name] ? true : false}
        {...props}
        {...props.register}
      ></StyledTextarea>
      {props.errors[name] && props.errors[name].message && (
        <ErrorMessage>{props.errors[name].message}</ErrorMessage>
      )}
    </>
  );
}
const StyledTextarea = styled.textarea`
  display: block;
  width: 100%;
  min-height: ${props => (props.minHeight ? props.minHeight : '88px')};
  padding: 4px 10px;
  border-radius: 2px;
  border: 1px solid ${theme.colors.divider2};
  font-size: 14px;
  line-height: 1.86;
  color: ${theme.colors.font};
  resize: none;
  &::placeholder {
    color: ${theme.colors.placeholder};
    font-size: 14px;
  }
  &:focus {
    border-color: ${theme.colors.point};
  }
  ${props =>
    props.err === true &&
    css`
      border-color: ${theme.colors.valid} !important;
    `}
`;
