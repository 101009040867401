import styled from '@emotion/styled';
import { theme } from 'shared/root/styles/theme';

export default function ContentsContainer({ children }) {
  return (
    <StyledContentsContainer>
      <Area>{children}</Area>
    </StyledContentsContainer>
  );
}

const StyledContentsContainer = styled.div`
  position: relative;
  overflow-x: auto;
  overflow-y: scroll;
  top: 68px;
  height: calc(100vh - 68px);
`;
const Area = styled.div`
  min-width: 1024px; // 반응형
  max-width: 1648px; // 반응형
  padding: 25px 20px 60px;
  ${theme.mq.xxl} {
    padding: 25px 50px 60px;
  }
`;
