import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { useDispatch } from 'react-redux';
import { loginUser, getUserProfile } from 'api/user';
import { SET_TOKEN } from 'redux/slices/token';
import { SET_USER } from 'redux/slices/user';

import { setLocalStorage } from 'utils';

import { Device } from '@capacitor/device';
import styled from '@emotion/styled';
import { theme } from 'shared/styles/theme';
import CommonStyledComponents from 'shared/styles/CommonStyledComponents';
import TextInput from 'components/Form/TextInput';
import logoIcon from 'assets/imgs/logo_login.svg';
import {
  PASSWORD_REGEXP,
  PASSWORD_VALID_MESSAGE,
} from 'shared/constants/constants';
const { Area, Button, FormField, FieldContainer, ErrorMessage } =
  CommonStyledComponents;

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {},
  });

  const logDeviceInfo = async () => {
    if (Device) {
      const info = await Device.getId();
      const uuid = info.uuid;

      setLocalStorage('uuid', uuid);
    }
  };
  logDeviceInfo();

  const onSubmit = async ({ id, password }) => {
    // input 태그 값 비우기
    setValue('password', '');

    const accessToken = await loginUser({ id, password });

    if (accessToken) {
      if (accessToken.name === 'AxiosError') {
        setError(
          'all',
          {
            type: 'all',
            message:
              '아이디 또는 비밀번호가 일치하지 않습니다. 다시 입력해주세요.',
          },
          { shouldFocus: true },
        );
      } else {
        localStorage.setItem('access_token', accessToken);
        dispatch(SET_TOKEN(accessToken));
        getUserProfile().then(response => {
          dispatch(SET_USER(response));
          localStorage.setItem('placeIdx', response.place_idx);
        });
        return navigate('/');
      }
    }
  };

  const onError = error => {
    console.log(error);
  };

  return (
    <>
      <LoginContainer>
        <Area>
          <LoginLogo>
            <img src={logoIcon} alt="소소하자" />
          </LoginLogo>
          <IntroTextItem>
            <IntroTitle>
              안녕하세요.
              <br />
              소소하자입니다.
            </IntroTitle>
            <IntroDesc>하자 등록을 위해 로그인 해주세요.</IntroDesc>
          </IntroTextItem>

          <form method="post" onSubmit={handleSubmit(onSubmit, onError)}>
            <FormField gap={'8px'}>
              <FieldContainer>
                <TextInput
                  register={register('id', {
                    required: '아이디를 입력해주세요.',
                    maxLength: {
                      value: 16,
                      message: '아이디는 16자 이하로만 작성이가능합니다.',
                    },
                  })}
                  errors={errors}
                  customErrorMessage
                  placeholder="아이디"
                  onClick={() => clearErrors(['all'])}
                />
              </FieldContainer>
            </FormField>
            <FormField gap={'8px'}>
              <FieldContainer>
                <TextInput
                  type="password"
                  register={register('password', {
                    required: '비밀번호를 입력해주세요.',
                    minLength: {
                      value: 8,
                      message: PASSWORD_VALID_MESSAGE,
                    },
                    maxLength: {
                      value: 12,
                      message: PASSWORD_VALID_MESSAGE,
                    },
                    pattern: {
                      value: PASSWORD_REGEXP,
                      message: PASSWORD_VALID_MESSAGE,
                    },
                  })}
                  errors={errors}
                  customErrorMessage
                  placeholder="비밀번호"
                  onClick={() => clearErrors(['all'])}
                />
              </FieldContainer>
            </FormField>
            {
              //커스텀 에러메세지 표시
              errors &&
                Object.keys(errors).length > 0 &&
                Object.keys(errors).map((key, idx) => (
                  <ErrorMessage custom key={key}>
                    {errors[key].message}
                  </ErrorMessage>
                ))
            }
            <ButtonContainer>
              <Button type="submit" size={'full-xl'} theme={'point'}>
                로그인
              </Button>
              <Button
                type="button"
                onClick={() => navigate('/signup')}
                size={'full-xl'}
                theme={'point3-blue'}
              >
                회원가입
              </Button>
            </ButtonContainer>
            <FindLink>
              <a href="/find-account">아이디 찾기</a>
              <a href="/find-password">비밀번호 재발급</a>
            </FindLink>
          </form>
        </Area>
      </LoginContainer>
    </>
  );
}

const LoginContainer = styled.div`
  padding: calc(50px + ${theme.notch.top}) 0;
`;
const LoginLogo = styled.div``;

const IntroTextItem = styled.h1`
  margin: 35px 0 50px;
`;
const IntroTitle = styled.p`
  margin-bottom: 12px;
  font-size: 26px;
  font-weight: 500;
  line-height: 1.31;
`;
const IntroDesc = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: #94999a;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 20px;
`;

const FindLink = styled.div`
  margin-top: 20px;

  text-align: center;
  color: var(--sub_7D7D87, #7d7d87);

  /* 14_regular */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  align-self: auto;
  a {
    padding: 0 10px;
    &:not(:nth-last-child(1)) {
      border-right: 1px solid ${theme.colors.divider};
    }
  }
`;
