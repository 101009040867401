import styled from '@emotion/styled';
import { useDispatch } from 'react-redux';

import backIcon from 'assets/imgs/nav_icon_back.svg';
import closeIcon from 'assets/imgs/nav_icon_close.svg';
import { useNavigate } from 'react-router-dom';

import ConfirmModal from 'components/Modals/ConfirmModal';
import FloorButton from 'components/FloorButton';
import { openModal } from 'redux/common/slices/modals';
import { theme } from 'shared/styles/theme';

export default function HeaderContainer({
  title,
  backBtn,
  showFloorBtn,
  deleteBtn,
  closeBtn,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const confirmBackButton = () => {
    dispatch(
      openModal({
        Component: ConfirmModal,
        props: {
          message: '작성을 종료하시겠습니까?\n작성한 내용이 저장되지 않습니다.',
          buttonText: {
            cancel: '계속 작성',
            submit: '작성 종료',
          },
          handleModalSubmit: () => {
            handleBackBtn();
          },
        },
      }),
    );
  };

  function handleBackBtn() {
    navigate(-1);
  }
  return (
    <Header>
      <h1 style={{ display: 'none' }}>소소하자</h1>

      <StyledNavbar>
        {backBtn ? (
          <LeftBtn onClick={handleBackBtn}>
            <img src={backIcon} alt="뒤로 이동" />
          </LeftBtn>
        ) : null}
        {closeBtn ? (
          <LeftBtn onClick={confirmBackButton}>
            <img src={closeIcon} alt="작성 취소" />
          </LeftBtn>
        ) : null}
        <TitleText>{title}</TitleText>
        {showFloorBtn && (
          <FloorButtonWrap>
            <FloorButton />
          </FloorButtonWrap>
        )}
        {deleteBtn ? <RightBtn onClick={deleteBtn}>삭제</RightBtn> : null}
      </StyledNavbar>
    </Header>
  );
}
const Header = styled.header`
  position: sticky;
  top: 0;
  padding-top: ${theme.notch.top};
  background-color: #fff;
  z-index: 999;
`;
const StyledNavbar = styled.nav`
  display: flex;
  align-items: center;
  height: 50px;
  font-size: 16px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  background-color: #fff;
`;
const LeftBtn = styled.button`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 44px;
  height: 50px;
  cursor: pointer;
`;
const TitleText = styled.p`
  flex: 1;
  font-size: 18px;
  font-weight: 600;
`;
const RightBtn = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 50px;
  padding-right: 16px;
  text-align: right;
  cursor: pointer;
  color: ${theme.colors.font};
`;

const FloorButtonWrap = styled.div`
  position: absolute;
  right: 17px;
`;

const SubmitButton = styled.button`
  position: absolute;
  bottom: 0;
  right: 0;
  height: 50px;
  padding-right: 16px;
  text-align: right;
  font-weight: 500;
  cursor: pointer;
  color: ${theme.colors.point};
`;
