import styled from '@emotion/styled';
import { theme } from 'shared/styles/theme';

import { getOptionObj } from 'utils';

import {
  WorkStatusData,
  WorkMethodData,
  DefectTypeData,
} from 'shared/root/defectData';

export default function DetailsTable({ data }) {
  return (
    <StyledTable>
      <tbody>
        <tr>
          <th>동</th>
          <td>{data.sort1}</td>
        </tr>
        <tr>
          <th>호수</th>
          <td>{data.sort2}</td>
        </tr>
        <tr>
          <th>위치</th>
          <td>{data.sort3}</td>
        </tr>
        <tr>
          <th>작업상태</th>
          <td>{getOptionObj(WorkStatusData, data.status, true)}</td>
        </tr>
        <tr>
          <th>하자유형</th>
          <td>{getOptionObj(DefectTypeData, data.type, true)}</td>
        </tr>
        <tr>
          <th>내용</th>
          <td>{data.content}</td>
        </tr>
        {data.work_method === 'care' ? (
          <tr>
            <th>작업방법</th>
            <td>{getOptionObj(WorkMethodData, data.work_method, true)}</td>
          </tr>
        ) : null}
        {data.work_method === 'replacement' ? (
          <tr>
            <th>교체면적</th>
            <td>
              {`${data.replacement_square_meter}m / ${data.replacement_sheet}장`}
            </td>
          </tr>
        ) : null}
        <tr>
          <th>촬영일자</th>
          <td>{data.shooting_day.substr(0, 11)}</td>
        </tr>
      </tbody>
    </StyledTable>
  );
}

const StyledTable = styled.table`
  width: 100%;
  border-top: 1px solid ${theme.colors.divider};
  line-height: 1.38;
  tr {
    border-bottom: 1px solid ${theme.colors.divider};
  }
  th {
    width: 104px;
    padding: 12px 16px;
    min-height: 48px;
    font-size: 15px;
    font-weight: 400;
    color: #444;
    background-color: ${theme.colors.bg2};
    border-right: 1px solid ${theme.colors.divider};
    text-align: left;
  }
  td {
    padding: 12px 10px;
    min-height: 48px;
    font-size: 16px;

    color: ${theme.colors.font};
  }
`;
