import PageLayout from 'components/PageLayout';
import CommonStyledComponents from 'shared/styles/CommonStyledComponents';
const { Area } = CommonStyledComponents;

export default function Modal() {
  return (
    <PageLayout title="모달테스트" backBtn>
      <Area>모달테스트</Area>
    </PageLayout>
  );
}
