import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'shared/root/styles/theme';
import CommonStyledComponents from 'shared/styles/CommonStyledComponents';

const { ErrorMessage } = CommonStyledComponents;

export default function TextInput(props) {
  const { register, errors, type, placeholder, suffix } = props;
  return (
    <>
      {register && !suffix && (
        <Input
          type={type ? type : 'text'}
          placeholder={placeholder}
          err={errors[register.name] ? 'y' : 'n'}
          {...props}
          {...register}
        />
      )}
      {register && suffix && (
        <SuffixInput>
          <Input
            type={type ? type : 'text'}
            name={register.name ? register.name : ''}
            placeholder={placeholder}
            {...register}
            {...props}
          />
          <div>{suffix}</div>
        </SuffixInput>
      )}
      {!register && (
        <Input type={'text'} placeholder={placeholder} {...props} />
      )}
      {!props.customErrorMessage &&
        register &&
        errors[register.name] &&
        errors[register.name].message && (
          <ErrorMessage>{errors[register.name].message}</ErrorMessage>
        )}
    </>
  );
}

const Input = styled.input`
  width: ${props => (props.width ? props.width : '100%')};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
  height: 36px;
  padding: 0 10px;
  border-radius: 2px;
  border: 1px solid ${theme.colors.divider2};
  font-size: 14px;
  color: ${theme.colors.font};
  &::placeholder {
    color: ${theme.colors.placeholder};
  }
  &:focus {
    border-color: ${theme.colors.point};
  }
  &:disabled {
    background-color: #f6f7f9;
  }
  ${props =>
    props.err === true &&
    css`
      border-color: ${theme.colors.valid} !important;
    `};
`;

const SuffixInput = styled.div`
  position: relative;
  input[type='text'] {
    padding: 15px 30px 15px 10px;
  }
  div {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 16px;
    color: ${theme.colors.placeholder};
  }
`;
