import Modal from 'react-modal';

import styled from '@emotion/styled';
import CommonStyledComponents from 'shared/styles/CommonStyledComponents';

const { Button } = CommonStyledComponents;

export default function TermModal({
  modalClose,
  modalSubmit,
  modalCancel,
  message,
  buttonText,
  onRequestClose,
}) {
  return (
    <Modal
      isOpen
      style={customStyles}
      onRequestClose={onRequestClose ? modalClose : null}
    >
      <StyleTermModal>
        <Inner>
          <Message dangerouslySetInnerHTML={{ __html: message }} />
        </Inner>
        <Buttons>
          <Button
            type="button"
            size={'full-md'}
            theme={'point3-blue'}
            onClick={modalCancel}
          >
            {buttonText && buttonText.cancel ? buttonText.cancel : '취소'}
          </Button>
          <Button
            type="submit"
            size={'full-md'}
            theme={'point'}
            onClick={modalSubmit}
          >
            {buttonText && buttonText.submit ? buttonText.submit : '확인'}
          </Button>
        </Buttons>
      </StyleTermModal>
    </Modal>
  );
}

const customStyles = {
  overlay: {
    position: 'fixed',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: '10000',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: 'calc(100% - 30px)',
    maxWidth: '550px',
    padding: '0',
    borderRadius: '4px',
    transform: 'translate(-50%, -50%)',
    border: 'none',
  },
};

const StyleTermModal = styled.div`
  padding: 10px;
`;

const Inner = styled.div`
  height: 100%;
  max-height: 450px;
  overflow-y: auto;
  width: 100%;
`;

const Message = styled.div`
  padding: 30px 0;
  font-size: 16px;
  line-height: 1.5;
  white-space: pre-wrap;
  text-align: left;
  /* CK 에디터 초기화 */
  figure {
    width: 100% !important;
  }
`;

const Buttons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 10px;
  padding-top: 10px;
`;
