import { Link } from 'react-router-dom';

import PageLayout from 'components/PageLayout';

import IconLinkArrow from 'assets/imgs/icon_link_arrow.svg';

import styled from '@emotion/styled';

import CommonStyledComponents from 'shared/styles/CommonStyledComponents';

const { Area } = CommonStyledComponents;

export default function Tos() {
  return (
    <PageLayout title="법률 정보 및 이용약관" backBtn>
      <LinkItem to="/terms/tos">
        <Area>이용약관</Area>
      </LinkItem>
      <LinkItem to="/terms/privacy">
        <Area>개인정보처리방침</Area>
      </LinkItem>
    </PageLayout>
  );
}

const LinkItem = styled(Link)`
  display: flex;
  align-items: center;
  height: 56px;
  padding-right: 16px;
  font-size: 16px;
  color: #000;
  background: url(${IconLinkArrow}) no-repeat center right 16px / 12px;
`;
