import { useEffect } from 'react';

import { useForm, Controller, get } from 'react-hook-form';

import { useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { CustomSelect, styleBox } from 'shared/root/styles/common/Select';

import { PlaceStatusOptions } from 'shared/root/optionData';

import { getPlaceDetails } from 'redux/root/slices/place';
import { getDefectPlaceList } from 'redux/root/slices/defect';

import { setPlace, updatePlace, deletePlace } from 'api/place';

import styled from '@emotion/styled';
import { theme } from 'shared/root/styles/theme';

import PageLayout from 'root/components/PageLayout';
import TitleContainer from 'root/components/TitleContainer';
import ContentsContainer from 'root/components/ContentsContainer';
import TextInput from 'root/components/Form/TextInput';
import Textarea from 'root/components/Form/Textarea';
import FormCkEditor from 'root/components/FormCkEditor';

import ConfirmModal from 'root/components/Modals/ConfirmModal';
import ToastMessage from 'root/components/Modals/ToastMessage';
import { openModal } from 'redux/common/slices/modals';

import DefectPlaceLists from './DefectPlaceLists';
import NoResult from 'root/components/NoResult';

import CommonStyledComponents from 'shared/root/styles/CommonStyledComponents';
const { Card, WriteForm, Button, ButtonsGrid, ErrorMessage } =
  CommonStyledComponents;

export default function PlaceWrite() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const currentIdx = params.idx;
  const isUpdate = currentIdx ? true : false;

  //Place
  const initialValues = {
    status: '',
    type: 'apartment',
    name: '',
    addr: '',
    memo: '',
    // content: '',
  };

  const {
    register,
    setValue,
    getValues,
    watch,
    reset,
    control,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
  });

  useEffect(() => {
    if (currentIdx) {
      dispatch(getPlaceDetails(currentIdx)).then(res => {
        const info = res.payload.place;
        reset(info);

        setValue(
          'status',
          PlaceStatusOptions[
            PlaceStatusOptions.findIndex(obj => obj.value === `${info.status}`)
          ],
        );
      });

      dispatch(getDefectPlaceList(currentIdx, 10, 1));
    } else {
      reset(initialValues);
      setValue('status', PlaceStatusOptions[0]);
    }
  }, [dispatch, params]);

  //Defect
  const onSubmit = data => {
    data.status = Number(data.status.value);

    if (currentIdx) {
      updatePlace(data, currentIdx, dirtyFields)
        .then(response => {
          dispatch(
            openModal({
              Component: ToastMessage,
              props: {
                iconImg: 'complete',
                message: '현장정보가 수정되었습니다.',
              },
            }),
          );
          navigate('/root/place/lists');
        })
        .catch(error => {
          console.log(error);
          dispatch(
            openModal({
              Component: ToastMessage,
              props: {
                iconImg: 'fail',
                message: error.message, //'실패했습니다',
              },
            }),
          );
        });
    } else {
      setPlace(data)
        .then(response => {
          dispatch(
            openModal({
              Component: ToastMessage,
              props: {
                iconImg: 'complete',
                message: '현장이 등록되었습니다.',
              },
            }),
          );
          navigate('/root/place/lists');
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  const onError = error => {
    console.log('error', error);
  };

  function handleClickDelete() {
    dispatch(
      openModal({
        Component: ConfirmModal,
        props: {
          message: '현재의 현장정보를 삭제하시겠습니까?',
          handleModalSubmit: () => {
            deletePlace(currentIdx)
              .then(res => {
                dispatch(
                  openModal({
                    Component: ToastMessage,
                    props: {
                      iconImg: 'complete',
                      message: '현장이 삭제되었습니다.',
                    },
                  }),
                );
                navigate('/root/place/lists');
              })
              .catch(error => {
                console.log({ error });
                dispatch(
                  openModal({
                    Component: ToastMessage,
                    props: {
                      iconImg: 'fail',
                      message: '실패했습니다',
                    },
                  }),
                );
              });
          },
        },
      }),
    );
  }

  function handleClickSubmit(data) {
    onSubmit(data);
  }

  return (
    <PageLayout>
      <TitleContainer>
        <h2>
          <span>현장관리</span>
          {currentIdx ? (
            <>
              <span>현장목록</span>
              <b>상세</b>
            </>
          ) : (
            <>
              <b>현장등록</b>
            </>
          )}
        </h2>
        <ButtonsGrid>
          {isUpdate && (
            <Button
              theme={'outline-default'}
              size={'md'}
              onClick={handleClickDelete}
            >
              삭제
            </Button>
          )}
          <Button
            type="submit"
            theme={'point'}
            size={'md'}
            onClick={handleSubmit(d => handleClickSubmit(d))}
            status={Object.keys(dirtyFields).length > 0 ? '' : 'disabled'}
          >
            저장
          </Button>
        </ButtonsGrid>
      </TitleContainer>

      <ContentsContainer>
        <Card.Layout>
          <Card.Header>
            <Card.HeaderTitle>현장정보</Card.HeaderTitle>
            <Card.HeaderRequired>필수항목</Card.HeaderRequired>
          </Card.Header>
          <Card.Body>
            <WriteForm.Layout
              method="post"
              onSubmit={handleSubmit(onSubmit, onError)}
            >
              <WriteForm.Fieldset>
                <WriteForm.Label>진행상태</WriteForm.Label>
                <WriteForm.Inputs>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <CustomSelect
                        {...field}
                        styles={styleBox}
                        width={'400px'}
                        isSearchable={false}
                        options={PlaceStatusOptions}
                      />
                    )}
                  />
                </WriteForm.Inputs>
              </WriteForm.Fieldset>

              <WriteForm.Fieldset>
                <WriteForm.Label required>현장명</WriteForm.Label>
                <WriteForm.Inputs>
                  <FlexItem>
                    <TextInput
                      register={register('name', {
                        required: '필수 입력입니다.',
                        maxLength: {
                          value: 20,
                          message: '20자 이하로만 작성이 가능합니다.',
                        },
                        onChange: e => {
                          const text = e.target.value;

                          if (e.target.value.length >= 20) {
                            e.target.value = text.substr(0, 21);
                          }
                        },
                      })}
                      errors={errors}
                      placeholder="현장명을 입력하세요"
                      maxWidth={'400px'}
                      customErrorMessage
                    />
                    <TextCountItem>
                      (
                      <em>
                        {watch('name') && watch('name').length !== 0
                          ? watch('name').length
                          : 0}
                      </em>
                      /20자)
                    </TextCountItem>
                  </FlexItem>
                  {errors.name && errors.name.type === 'maxLength' && (
                    <ErrorMessage>{errors.name.message}</ErrorMessage>
                  )}
                </WriteForm.Inputs>
              </WriteForm.Fieldset>
              <WriteForm.Fieldset>
                <WriteForm.Label required>주소</WriteForm.Label>
                <WriteForm.Inputs>
                  <TextInput
                    register={register('addr', {
                      required: '필수 입력입니다.',
                    })}
                    errors={errors}
                    placeholder="주소를 입력하세요"
                  />
                </WriteForm.Inputs>
              </WriteForm.Fieldset>
              <WriteForm.Fieldset>
                <WriteForm.Label>내용</WriteForm.Label>
                <WriteForm.Inputs>
                  <Textarea
                    register={register('memo')}
                    errors={errors}
                    placeholder="내용을 입력하세요"
                  />
                </WriteForm.Inputs>
              </WriteForm.Fieldset>
              <WriteForm.Fieldset>
                <WriteForm.Label>호수별 도면사진</WriteForm.Label>
                <WriteForm.Inputs>
                  <FormCkEditor
                    data={getValues('content')}
                    onChange={data => {
                      setValue('content', data, {
                        shouldDirty: true,
                      });
                    }}
                  />
                </WriteForm.Inputs>
              </WriteForm.Fieldset>
            </WriteForm.Layout>
          </Card.Body>
        </Card.Layout>

        {currentIdx ? <DefectPlaceLists currentIdx /> : null}
      </ContentsContainer>
    </PageLayout>
  );
}

const FlexItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
const TextCountItem = styled.span`
  font-size: 13px;
  line-height: 1.62;
  margin-left: 3px;
  color: ${theme.colors.placeholder};
  em {
    color: #0089cf;
  }
`;
