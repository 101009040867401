import styled from '@emotion/styled';
import { theme } from 'shared/root/styles/theme';
import { css } from '@emotion/react';

import Button from './common/Button';
import Spinners from './common/Spinners';
import { Card } from './common/Card';
import { Table } from './common/Table';
import { Input } from './common/Input';
import { WriteForm } from './common/WriteForm';
import { SearchCard } from './common/SearchCard'; // 검색하기 카드영역

// area
const Area = styled.div`
  width: 100%;
  max-width: 414px;
  margin: 0 auto;
  padding: 0 ${theme.gutter.mobile};
`;

// form
const FormField = styled.div`
  display: flex;
  margin-bottom: 15px;
  &.hidden {
    display: none;
  }
  ${props =>
    props.gap &&
    css`
      margin-bottom: ${props.gap};
    `}
`;

const ErrorMessage = styled.p`
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 500;
  color: ${theme.colors.valid};
  ${props =>
    props.custom &&
    css`
      margin: 0;
    `}
`;

//스크롤바커스텀
const customScroll = (
  scrollbar = '#fff',
  track = '#ddd',
  width = '10px',
  height = '10px',
) => css`
  &::-webkit-scrollbar {
    width: ${width};
    height: ${height};
  }
  //Track
  &::-webkit-scrollbar-track {
    -webkit-border-radius: 0;
    border-radius: 0;
    background-color: ${track};
  }
  //Handle
  &::-webkit-scrollbar-thumb {
    border-radius: 0;
    background-color: ${scrollbar};
    // -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, .02);
  }
  // Handle on hover
  &::-webkit-scrollbar-thumb:hover {
    background-color: $g_point-theme-color;
  }
`;

const ButtonsGrid = styled.div`
  flex: none;
  display: grid;
  justify-content: end;
  grid-template-columns: repeat(auto-fit, minmax(74px, auto));
  column-gap: 8px;
  row-gap: 8px;
  width: 70%;
  margin-left: auto;
`;

const CommonStyledComponents = {
  Area,
  Button,
  ButtonsGrid,
  Card,
  Table,
  Input,
  WriteForm,
  SearchCard,
  customScroll,
  Spinners,
  ErrorMessage,
};
export default CommonStyledComponents;
