import axios from 'axios';

import {
  API_PLACE,
  API_DEFECT_PLACE,
  API_DEFECT,
} from 'shared/constants/constants';

/*
|--------------------------------------------------------------------------
| 하자관리 API (하자관리>현장별 하자관리)
|--------------------------------------------------------------------------
*/
// 관리자_하자 일괄 삭제 API
export async function deleteDefect(idxs) {
  const response = await axios.delete(`${API_DEFECT}`, {
    data: { idxs },
  });
  return response.data;
}
/*
|--------------------------------------------------------------------------
| 하자현장 API
|--------------------------------------------------------------------------
*/
// 관리자_하자현장등록 API (하자등록 항목 추가)
export async function addDefectPlace(data) {
  const { defect_idx, ...restData } = data;
  const response = await axios.post(`${API_DEFECT_PLACE}`, restData);

  return response.restData;
}

// 관리자_하자현장삭제 API (하자등록 항목 삭제)
export async function deleteDefectPlace(idxs) {
  const response = await axios.delete(`${API_DEFECT_PLACE}`, {
    data: { idxs },
  });

  return response.data;
}

// 하자현장 정보 API
export async function getDefectPlace(data) {
  const response = await axios.get(`${API_DEFECT_PLACE}/${data}`);
  return response.data;
}

// 하자현장 수정 API
// api확인필요 (401오류)
export async function UpdateDefectPlace(data) {
  const { defect_idx, ...restData } = data;
  const response = await axios.patch(
    `${API_DEFECT_PLACE}/${defect_idx}`,
    restData,
  );

  return response.restData;
}

// 하자현장엑셀 업로드 API
export async function addDefectPlaceExcel(data) {
  const response = await axios.post(
    `${API_DEFECT_PLACE}/excel/${data.place_idx}`,
    { excel: data.excel[0] },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response.data;
}

/*
|--------------------------------------------------------------------------
| 하자 API
|--------------------------------------------------------------------------
*/
// 하자 등록
export async function postDefect(data) {
  delete data.createdate;

  const response = await axios.post(`${API_DEFECT}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data;
}

// 관리자_현장 하자관리 수정 API
export async function patchDefect(data) {
  console.log('확인', data);
  const { idx, ...restData } = data;
  const response = await axios.patch(`${API_DEFECT}/${idx}`, restData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.restData;
}
