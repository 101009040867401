import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'shared/root/styles/theme';

import IconArrow from 'root/assets/imgs/icon_arrow.svg';

const borderColor = '#e3e4eb';

//Scroll
const Container = styled.div`
  overflow-x: auto;
  ${props =>
    props.layout === 'fixed' &&
    css`
      > table {
        table-layout: fixed;
      }
    `}
`;

//Layout
const Layout = styled.table`
  width: 100%;
  table-layout: fixed;
  word-break: break-all;
  ${props =>
    props.layout === 'auto' &&
    css`
      table-layout: auto;
    `}
`;
//Tr
const Tr = styled.tr`
  & [class*='-indicatorContainer'] svg {
    opacity: 0;
    /* transition: opacity 0.2s; */
  }
  &:hover {
    > td {
      background-color: #f3f6fb;
    }
    [class*='-indicatorContainer'] svg {
      opacity: 1;
    }
  }
  th,
  td {
    padding: 0 10px;
    height: 60px;
    font-size: 14px;
    line-height: 1.57;
    text-align: left;
    vertical-align: middle;
  }
  ${props =>
    props.noHover &&
    css`
      &:hover {
        td {
          background-color: #fff;
        }
      }
    `}
  ${props =>
    props.type === 'link' &&
    css`
      /* &:hover {
        td {
          background-color: #f3f6fb;
        }
      } */
    `}
`;
//Th
const Th = styled.th`
  background-color: #fff;
  font-weight: 600;
  color: #323232;
  ${props =>
    props.type === 'num' &&
    css`
      padding-left: 25px !important;
    `}
  ${props =>
    props.type === 'checkbox' &&
    css`
      position: sticky;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    `}
  ${props =>
    props.type === 'more' &&
    css`
      text-align: center !important;
    `}
  ${props =>
    props.type === 'createdAt' &&
    css`
      min-width: 300px !important;
    `}
  //하자등록항목 내부스크롤 테이블 타이틀영역
  ${props =>
    props.hasScroll &&
    css`
      border-left: 1px solid ${borderColor};
      background-image: url(${IconArrow});
      background-repeat: no-repeat;
      background-position: center right;
    `}
`;
//Td
const Td = styled.td`
  border-top: 1px solid ${borderColor};
  background-color: #fff;
  color: #11193b;
  ${props =>
    props.type === 'checkbox' &&
    css`
      position: sticky;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    `}
  ${props =>
    props.type === 'num' &&
    css`
      padding-left: 25px !important;
    `}
    ${props =>
    props.type === 'statusSelect' &&
    css`
      padding: 0 !important;
    `}
    ${props =>
    props.type === 'more' &&
    css`
      width: 160px;
      text-align: center !important;
      a {
        display: inline-block;
        text-decoration: underline;
        color: #0b6fe8;
        &:hover {
          color: #0b6fe8;
          text-decoration: underline;
        }
      }
    `}
    //제목
    ${props =>
    props.type === 'link' &&
    css`
      cursor: pointer;
      span {
        /* display: flex; */
        /* align-items: center; */
        display: -webkit-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
    `}
    //제목
    ${props =>
    props.type === 'title' &&
    css`
      cursor: pointer;
      span {
        /* display: flex; */
        /* align-items: center; */
        display: -webkit-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
    `}
    //빈공간
    ${props =>
    props.type === 'blank' &&
    css`
      position: relative;
      padding: 0 !important;
      border-left: 1px solid ${borderColor};
      border-top: 1px solid ${borderColor};
      background-color: #fff !important;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #fff;
        z-index: 1000;
      }
    `}
    ${props =>
    props.hasTable &&
    css`
      vertical-align: top !important;
      border-top: none !important;
      padding: 0 !important;
      overflow-x: scroll;
      & + td {
        border-left: 1px solid ${borderColor};
      }
      & > table {
        width: auto;
        width: -webkit-fill-available;
        white-space: nowrap;
      }
    `};
`;
//Options
const Options = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 25px;
  border-top: 1px solid ${borderColor};
  &:first-of-type {
    border-top: none;
    border-bottom: 1px solid ${borderColor};
  }
  > * {
    display: flex;
    column-gap: 8px;
    row-gap: 8px;
  }
  > *:last-child {
    margin-left: auto;
  }
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${borderColor};
  padding: 25px 0;
`;

export const Table = {
  Container,
  Layout,
  Tr,
  Th,
  Td,
  Options,
  Pagination,
};
