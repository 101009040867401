import { useTheme, Global, css } from '@emotion/react';
import Reset from './base/reset.css';
import Font from './base/font.css';

const GlobalStyle = () => {
  const theme = useTheme();

  return (
    <Global
      styles={css`
        ${Reset}
        ${Font}
        html {
          background-color: #f8f8f8;
          -webkit-tap-highlight-color: rgba(176, 208, 248, 0.1) !important;
        }
        body {
          position: relative;
          margin: 0 auto;
          min-width: 320px;
          max-width: 414px;
          min-height: 100vh;
          font-family: ${theme.fonts.pretendard};
          color: ${theme.colors.font};
          background-color: #fff;
          canvas {
            width: 100%;
          }
        }
      `}
    />
  );
};

export default GlobalStyle;
