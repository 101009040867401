import axios from 'axios';
import { Navigate, Outlet } from 'react-router-dom';

const useAuth = () => {
  const token = localStorage.getItem('access_token');
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return true;
  } else {
    return false;
  }
};

export default function PublicRoutes() {
  const auth = useAuth();

  return auth ? <Navigate to="/" /> : <Outlet />;
}
