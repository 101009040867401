import { useNavigate, Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { theme } from 'shared/root/styles/theme';

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <StyledNotFound>
      <Inner>
        <Title>
          <h1>404</h1>
          <h2>페이지를 찾을 수 없습니다.</h2>
        </Title>
        <Link to="/root">
          <button type="button" onClick={() => navigate('/')}>
            메인으로 돌아가기
          </button>
        </Link>
      </Inner>
    </StyledNotFound>
  );
}

const StyledNotFound = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: #232c51;
  overflow-x: auto;
`;

const Inner = styled.div`
  /* min-width: 768px; // 반응형 */
  margin: 0 auto;
  text-align: center;
  padding: 0 20px;
  button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 174px;
    height: 50px;
    border: none;
    border-radius: 2px;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    -webkit-appearance: none;
    outline: none;
    transition: all 0.2s;
    cursor: pointer;
    background-color: ${theme.colors.point};
    color: #fff;
    &:hover {
      background-color: #0047d5;
    }
  }
`;
const Title = styled.div`
  position: relative;
  h1 {
    font-size: 315px;
    font-weight: 700;
    color: rgba(108, 114, 147, 0.3);
  }
  h2 {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    font-size: 30px;
    color: #fff;
    transform: translate(-50%, -50%);
  }
`;
