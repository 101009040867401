import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import { MemberGroupOptions } from 'shared/root/optionData';
import { getPlaceList } from 'redux/root/slices/place';

import { setOptions } from 'utils';

import { createUsers } from 'api/user';

import PageLayout from 'root/components/PageLayout';
import TitleContainer from 'root/components/TitleContainer';
import ContentsContainer from 'root/components/ContentsContainer';
import TextInput from 'root/components/Form/TextInput';
import Textarea from 'root/components/Form/Textarea';
import ToastMessage from 'root/components/Modals/ToastMessage';
import { openModal } from 'redux/common/slices/modals';

import CommonStyledComponents from 'shared/root/styles/CommonStyledComponents';
import { CustomSelect, styleBox } from 'shared/root/styles/common/Select';
import {
  PASSWORD_REGEXP,
  PASSWORD_VALID_MESSAGE,
} from 'shared/constants/constants';
const { Card, WriteForm, Button, ButtonsGrid } = CommonStyledComponents;

export default function MemberWrite() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const placeList = useSelector(state => state.place.placeList).results; //현장모든목록

  const { admin } = useSelector(state => state.rootUser);

  const initialValues = {
    id: '',
    name: '',
    status: '2',
    email: '',
    phone: '',
    memo: '',
    password: '',
    type: 'default',
    group: '',
    place_idx: '',
    isAdmin: false,
  };

  const {
    register,
    setValue,
    getValues,
    watch,
    handleSubmit,
    reset,
    control,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: initialValues,
  });

  //회원등록
  const onSubmit = data => {
    const {
      idx,
      status,
      group,
      place_idx,
      user_group,
      admin_group,
      updatedAt,
      createdAt,
      isAdmin,
      ...restData
    } = data;
    const statusValue = String(status);
    const groupValue = Object.keys(group).length > 0 ? group.value : group;
    const placeValue = String(
      Object.keys(place_idx).length > 0 ? place_idx.value : place_idx,
    );
    //셀렉트선택 객체 value값 분리
    const newData = {
      ...restData,
      status: statusValue,
      group: groupValue,
      place_idx: placeValue,
    };

    // 현장정보 없으면 key-value 쌍 자체를 삭제해야 오류나지 않음.
    if (!placeValue) {
      delete newData.place_idx;
    }

    createUsers({
      data: newData,
      isAdmin: getValues('isAdmin'),
    })
      .then(response => {
        getValues('isAdmin');
        // ? navigate('/root/member/admin/lists')
        // : navigate('/root/member/basic/lists');
        dispatch(
          openModal({
            Component: ToastMessage,
            props: {
              iconImg: 'complete',
              message: '회원정보가 등록되었습니다.',
            },
          }),
        );
        navigate(-1);
      })
      .catch(error => {
        console.log('error', error);
        dispatch(
          openModal({
            Component: ToastMessage,
            props: {
              iconImg: 'fail',
              message: error.response.data.message,
            },
          }),
        );
      });
  };

  const onError = data => {};

  //저장버튼 클릭 form전송
  function handleClickSubmit(data) {
    onSubmit(data);
  }

  //관리자그룹 선택 시 담당현장 항목 리셋
  useEffect(() => {
    if (watch('group').value === '3') {
      setValue('isAdmin', false);
    } else {
      setValue('isAdmin', true);
      setValue('place_idx', '');
    }
  }, [watch('group')]);

  //상세페이지 정보 불러오기
  useEffect(() => {
    //현장정보셋팅
    dispatch(getPlaceList(1));
    reset(initialValues);
    // setValue('group', MemberGroupOptions[2]);
  }, [dispatch]);

  useEffect(() => {
    if (!admin) return;

    if (admin.admin_group.idx === 4) {
      setValue('group', MemberGroupOptions[2]);
      setValue('place_idx', setOptions(placeList)[0]);
    }
  }, [admin]);

  console.log(MemberGroupOptions);

  return (
    <PageLayout>
      <TitleContainer>
        <h2>
          <span>회원관리</span>
          <b>회원등록</b>
        </h2>
        <ButtonsGrid>
          <Button
            type="submit"
            theme={'point'}
            size={'md'}
            onClick={handleSubmit(d => handleClickSubmit(d))}
            status={Object.keys(dirtyFields).length > 0 ? '' : 'disabled'}
          >
            저장
          </Button>
        </ButtonsGrid>
      </TitleContainer>

      <ContentsContainer>
        <Card.Layout>
          <Card.Header>
            <Card.HeaderTitle>회원등록</Card.HeaderTitle>
            <Card.HeaderRequired>필수항목</Card.HeaderRequired>
          </Card.Header>
          {admin && (
            <Card.Body>
              <WriteForm.Layout
                method="post"
                onSubmit={handleSubmit(onSubmit, onError)}
              >
                <WriteForm.Fieldset>
                  <WriteForm.Label>그룹명</WriteForm.Label>
                  {MemberGroupOptions && (
                    <WriteForm.Inputs>
                      <Controller
                        name="group"
                        control={control}
                        render={({ field }) => (
                          <CustomSelect
                            {...field}
                            styles={styleBox}
                            width={'400px'}
                            isSearchable={false}
                            options={
                              admin.admin_group.idx === 2
                                ? MemberGroupOptions.filter(
                                    e => e.value !== '1',
                                  )
                                : MemberGroupOptions
                            }
                            placeholder="선택"
                            isDisabled={admin?.admin_group.idx === 4 && true}
                          />
                        )}
                      />
                    </WriteForm.Inputs>
                  )}
                </WriteForm.Fieldset>
                <WriteForm.Fieldset>
                  <WriteForm.Label required>회원 아이디</WriteForm.Label>
                  <WriteForm.Inputs>
                    <TextInput
                      register={register('id', {
                        required: '필수 입력입니다.',
                        maxLength: {
                          value: 16,
                          message: '16자 이하로만 작성이가능합니다.',
                        },
                      })}
                      errors={errors}
                      placeholder="회원 아이디를 입력하세요"
                      maxWidth={'400px'}
                    />
                  </WriteForm.Inputs>
                </WriteForm.Fieldset>
                <WriteForm.Fieldset>
                  <WriteForm.Label required>회원 비밀번호</WriteForm.Label>
                  <WriteForm.Inputs>
                    <TextInput
                      register={register('password', {
                        required: '필수 입력입니다.',
                        minLength: {
                          value: 8,
                          message: PASSWORD_VALID_MESSAGE,
                        },
                        maxLength: {
                          value: 12,
                          message: PASSWORD_VALID_MESSAGE,
                        },
                        pattern: {
                          value: PASSWORD_REGEXP,
                          message: PASSWORD_VALID_MESSAGE,
                        },
                      })}
                      errors={errors}
                      placeholder={'회원 비밀번호를 입력하세요'}
                      maxWidth={'400px'}
                    />
                  </WriteForm.Inputs>
                </WriteForm.Fieldset>
                <WriteForm.Fieldset>
                  <WriteForm.Label>담당현장</WriteForm.Label>
                  <WriteForm.Inputs>
                    <Controller
                      name="place_idx"
                      control={control}
                      render={({ field }) => (
                        <CustomSelect
                          {...field}
                          styles={styleBox}
                          width={'400px'}
                          isSearchable={false}
                          isDisabled={
                            // 최고관리자,관리자는 선택불가능
                            (watch('group').value === '1' ||
                              watch('group').value === '2') &&
                            true
                          }
                          options={setOptions(placeList)}
                          placeholder="선택"
                        />
                      )}
                    />
                  </WriteForm.Inputs>
                </WriteForm.Fieldset>
                <WriteForm.Fieldset>
                  <WriteForm.Label required>회원 이름</WriteForm.Label>
                  <WriteForm.Inputs>
                    <TextInput
                      register={register('name', {
                        required: '필수 입력입니다.',
                      })}
                      errors={errors}
                      placeholder="회원 이름을 입력하세요"
                      maxWidth={'400px'}
                    />
                  </WriteForm.Inputs>
                </WriteForm.Fieldset>
                <WriteForm.Fieldset>
                  <WriteForm.Label required>이메일</WriteForm.Label>
                  <WriteForm.Inputs>
                    <TextInput
                      register={register('email', {
                        required: '필수 입력입니다.',
                        pattern: {
                          value:
                            /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/,
                          message: '올바른 이메일 주소만 입력 가능합니다',
                        },
                      })}
                      errors={errors}
                      placeholder="이메일을 입력하세요"
                      maxWidth={'400px'}
                    />
                  </WriteForm.Inputs>
                </WriteForm.Fieldset>
                <WriteForm.Fieldset>
                  <WriteForm.Label>연락처</WriteForm.Label>
                  <WriteForm.Inputs>
                    <TextInput
                      register={register('phone')}
                      errors={errors}
                      placeholder="연락처를 입력하세요"
                      maxWidth={'400px'}
                    />
                  </WriteForm.Inputs>
                </WriteForm.Fieldset>
                <WriteForm.Fieldset>
                  <WriteForm.Label>메모</WriteForm.Label>
                  <WriteForm.Inputs>
                    <Textarea
                      register={register('memo')}
                      errors={errors}
                      placeholder="메모를 입력하세요"
                    />
                  </WriteForm.Inputs>
                </WriteForm.Fieldset>
              </WriteForm.Layout>
            </Card.Body>
          )}
        </Card.Layout>
      </ContentsContainer>
    </PageLayout>
  );
}
