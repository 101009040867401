import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import axios from 'axios';

import { API_SETTINGS } from 'shared/constants/constants';

// 환경설정 리스트 가져오기
export const getSettings = createAsyncThunk(
  'auth/getSettings',
  async (key, thunkAPI) => {
    try {
      const response = await axios.get(`${API_SETTINGS}/${key}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    settingInfo: [],
  },
  reducers: {},
  extraReducers: {
    [getSettings.fulfilled]: (state, action) => {
      state.settingInfo = action.payload;
    },
    [getSettings.rejected]: (state, action) => {},
  },
});

export default settingsSlice.reducer;
