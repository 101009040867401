import Select from 'react-select';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const styleDefault = {
  control: (provided, state) => ({
    position: 'relative',
    display: 'inline-flex',
    width: 'auto',
    height: 36,
    border: 'none',
    fontSize: '14px',
    fontWeight: '500',
    color: '#323232',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    outline: 'none',
    cursor: 'pointer',
  }),
  //드롭박스
  menu: (provided, state) => ({
    ...provided,
    right: '0',
    minWidth: '80px',
    maxHeight: '200px',
    overflowY: 'auto',
    borderRadius: '2px',
    width: state.selectProps.width,
    color: state.selectProps.menuColor,
    boxShadow: '0 3px 6px 0 rgba(35, 44, 81, 0.15)',
    border: 'solid 1px #e2e5ec',
    padding: 0,
    margin: 0,
  }),
  //드롭박스>메뉴
  menuList: styles => ({
    padding: '0',
  }),
  //드롭박스>메뉴>옵션
  option: (provided, state) => {
    return {
      ...provided,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: 36,
      padding: '0 50px 0 8px',
      fontSize: '14px',
      color: state.isSelected ? '#0b6fe8' : '#323232',
      backgroundColor: state.isSelected ? '#f3f6fb' : '#fff',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
    };
  },
  placeholder: (provided, state) => {
    return {
      color: state.isDisabled ? '#ccc' : '#323232',
    };
  },
};

export const styleBox = {
  control: (provided, state) => ({
    display: 'inline-flex',
    // width: '100%',
    maxWidth: '400px',
    height: 36,
    maxHeight: '220px',
    border: 'solid 1px #e2e5ec',
    fontSize: '14px',
    fontWeight: '500',
    borderRadius: '2px',
    color: '#323232',
    backgroundColor: state.isDisabled ? '#f6f7f9' : 'transparent',
    boxShadow: 'none',
    outline: 'none',
    cursor: 'pointer',
  }),
  //드롭박스
  menu: (provided, state) => ({
    ...provided,
    right: 0,
    minWidth: '80px',
    maxHeight: '200px',
    overflowY: 'auto',
    borderRadius: '2px',
    width: '100%',
    maxWidth: '400px',
    color: state.selectProps.menuColor,
    boxShadow: '0 3px 6px 0 rgba(35, 44, 81, 0.15)',
    border: '1px solid #e2e5ec',
    padding: 0,
    margin: 0,
  }),
  //드롭박스>메뉴
  menuList: styles => ({
    padding: '0',
    // maxHeight: '180px',
    // overflowY: 'scroll',
  }),
  //드롭박스>메뉴>옵션
  option: (provided, state) => {
    return {
      ...provided,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      maxWidth: '400px',
      height: 36,
      padding: '0 10px',
      fontSize: '14px',
      color: state.isSelected ? '#0b6fe8' : '#323232',
      backgroundColor: state.isSelected ? '#f3f6fb' : '#fff',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
    };
  },
  placeholder: (provided, state) => {
    return {
      color: state.isDisabled ? '#ccc' : '#aaa',
    };
  },
};

export const CustomSelect = styled(Select)`
  // 넓이 커스텀 : 컴포넌트에 width="000px" 설정시 적용가능
  ${props =>
    props.width &&
    css`
      width: ${props.width};
      > * {
        width: 100%;
      }
    `}
  [class*='-Input'] {
    display: none;
  }
  [class*='-menu'] {
    flex: none;
  }
  [class*='-ValueContainer'] {
    display: flex;
    align-items: center;
    padding: 2px 10px 2px 10px;
    [class*='-dummyInput-DummyInput'] {
      width: 0;
    }
  }
  [class*='-singleValue'] {
    color: #323232;
    margin: 0;
  }
  [class*='-indicatorSeparator'] {
    // 화살표 왼쪽 바
    display: none;
  }
  [class*='-indicatorContainer'] {
    // 화살표
    width: 15px;
    padding: 0;
    svg {
      width: 15px;
      margin: 0 auto;
      fill: #6c7293;
    }
  }
  [class*='-option'] {
    &:hover {
      color: #0b6fe8;
    }
  }
`;
