import { useSelector } from 'react-redux';

/*
|--------------------------------------------------------------------------
| API URL
|--------------------------------------------------------------------------
*/
export const API_URL = 'https://soso-api.nc136.reconers.com';
// export const API_URL = 'http://sosohaja_api.nc136.reconers.com';
// export const API_URL = 'http://sosohaja_api.nc188.reconers.com'; // 이전 url
// export const API_URL = 'http://127.0.0.1:3011';
// export const API_URL = 'http://10.0.2.2:3011'; // 안드로이드 스튜디오 로컬 테스트용

export const API_BOARD = `${API_URL}/board-contents`;
export const API_BOARD_ADMIN = `${API_URL}/admin/board-contents`;

export const API_USERS = `${API_URL}/users`;
export const API_USERS_PROFILE = `${API_URL}/users/profile`;
export const API_USERS_ADMIN = `${API_URL}/admin-users`;
export const API_USERS_SIGNUP = `${API_URL}/users/signup`;
export const API_USERS_FINDID = `${API_URL}/users/findid`;
export const API_USERS_ID = `${API_URL}/users/`;
export const API_USERS_REPW = `${API_URL}/users/repw`;
export const API_USERS_UPDATE = `${API_URL}/users/`;
export const API_USERS_DELETE = `${API_URL}/users/`;
export const API_USERS_CHANGEPW = `${API_URL}/users/changepw`;

export const API_SETTINGS = `${API_URL}/settings`;

export const API_EXCEL_SAMPLE_DOWN = `${API_URL}/defect-place/sample-excel`;

export const API_PLACE = `${API_URL}/place`;
export const API_DEFECT = `${API_URL}/defect`;
export const API_DEFECT_PLACE = `${API_URL}/defect-place`;

export const API_FILE = `${API_URL}/file`;

/* 비밀번호 정규식 */
export const PASSWORD_REGEXP =
  /^.*(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[~!@#$%^&*()_+|<>?:{}]).*$/;

export const PASSWORD_VALID_MESSAGE =
  '비밀번호는 영어,숫자,특수문자 조합 8~12글자여야 합니다.';
