import styled from '@emotion/styled';
import LandscapeImg from 'assets/imgs/landingpage.png';
import LandscapeBg from 'assets/imgs/landingpage_bg.jpg';
import PortraitImg from 'assets/imgs/landingpage_m.jpg';
import PortraitBg from 'assets/imgs/landingpage_bg_m.jpg';
import { theme } from 'shared/styles/theme';

//Mobile App Landing Page
export default function AppLandingPage() {
  return (
    <LandingPage>
      <LandingPageImg></LandingPageImg>
    </LandingPage>
  );
}

const LandingPage = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: #0b0d28;
  /* background-image: url(${LandscapeImg});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50%; */
  @media (orientation: portrait) {
    /* width: 414px; */
    /* height: 832px; */
    background-color: #0f123b;
    /* background-image: url(${PortraitImg}), url(${PortraitBg});
    background-position: 50% 50%, 50% 50%;
    background-size: auto 100%, auto 100%;
    background-repeat: no-repeat, repeat-x; */
  }
`;

const LandingPageImg = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${LandscapeImg}), url(${LandscapeBg});
  background-repeat: no-repeat;
  background-position: 50% 50%, 50%, 50%;
  background-size: contain, cover;
  @media (orientation: portrait) {
    height: auto;
    background-image: url(${PortraitImg});
    background-position: 0;
    background-size: cover;
    &::after {
      content: '';
      display: block;
      width: 100%;
      padding-top: calc(832 / 414 * 100%);
    }
  }
`;
