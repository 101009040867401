import { createSlice } from '@reduxjs/toolkit';

const permissionModal = JSON.parse(localStorage.getItem('permissionModal'));
const GuideModal = JSON.parse(localStorage.getItem('GuideModal'));
const GuideToolTip = JSON.parse(sessionStorage.getItem('GuideToolTip'));

export const permissionSlice = createSlice({
  name: 'permission',
  initialState: {
    permissionModalVisible: permissionModal === false ? permissionModal : true,
    guideModalVisible: GuideModal === false ? GuideModal : true,
    guideToolTipVisible: GuideToolTip === false ? GuideToolTip : true,
  },
  reducers: {
    PermissionModalConfirm: (state, action) => {
      localStorage.setItem('permissionModal', false);
      state.permissionModalVisible = false;
    },
    GuideModalConfirm: (state, action) => {
      localStorage.setItem('GuideModal', false);
      state.guideModalVisible = false;
    },
    GuideToolTipConfirm: (state, action) => {
      sessionStorage.setItem('GuideToolTip', false);
      state.guideToolTipVisible = false;
    },
  },
});

export const {
  PermissionModalConfirm,
  GuideModalConfirm,
  GuideToolTipConfirm,
} = permissionSlice.actions;

export default permissionSlice.reducer;
