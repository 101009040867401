import styled from '@emotion/styled';
import React from 'react';
import Paragraph from './Paragraph';
import CommonStyledComponents from 'shared/styles/CommonStyledComponents';
import { useNavigate } from 'react-router-dom';
import Description from './Description';

const { Button } = CommonStyledComponents;

export default function SignupComplete() {
  const navigate = useNavigate();
  return (
    <SignupCompleteContainer>
      <Paragraph>회원가입 완료</Paragraph>
      <Description>소소하자에서 하자 등록을 시작해보세요!</Description>

      <ButtonWrapper>
        <Button
          type="button"
          size={'full-xl'}
          theme={'point'}
          onClick={() => navigate('/')}
        >
          메인으로
        </Button>
      </ButtonWrapper>
    </SignupCompleteContainer>
  );
}

const SignupCompleteContainer = styled.div``;

const ButtonWrapper = styled.div`
  margin-top: 30px;
`;
