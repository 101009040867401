import React, { useState } from 'react';

import styled from '@emotion/styled';
import { theme } from 'shared/styles/theme';
import CommonStyledComponents from 'shared/styles/CommonStyledComponents';
import PageLayout from 'components/PageLayout';
import SignupAgree from 'components/signup/SignupAgree';
import { SignupField } from 'components/signup/SignupField';
import SignupComplete from 'components/signup/SignupComplete';

const { Area } = CommonStyledComponents;

/**
 *  회원가입 플로우
 * 'agree' | 'field' | 'complete' 3단계로 구분
 * (약관동의) | 입력 -> 생성 | 완료
 * */
export default function Signup() {
  const [signupState, setSignupState] = useState('agree');
  return (
    <PageLayout title="회원가입" backBtn>
      <SignupContainer>
        <Area>
          {signupState === 'agree' && (
            <SignupAgree handleNext={state => setSignupState(state)} />
          )}
          {signupState === 'field' && (
            <SignupField handleNext={state => setSignupState(state)} />
          )}
          {signupState === 'complete' && <SignupComplete />}
        </Area>
      </SignupContainer>
    </PageLayout>
  );
}

const SignupContainer = styled.div`
  padding: calc(20px + ${theme.notch.top}) 0;
`;
