import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from 'shared/styles/theme';

const Layout = styled.form`
  padding: 25px;
`;
const Fieldset = styled.fieldset`
  display: flex;
  border: 1px solid ${theme.colors.divider};
  & + & {
    margin-top: -1px;
  }
`;
const Label = styled.label`
  flex: none;
  width: 213px;
  padding: 15px 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  color: #444;
  background-color: #f3f6fb;
  border-right: 1px solid ${theme.colors.divider};
  ${props =>
    props.required &&
    css`
      &::after {
        content: '';
        flex: none;
        display: inline-block;
        width: 6px;
        height: 6px;
        margin-left: 5px;
        margin-bottom: 3px;
        background-color: ${theme.colors.point};
        border-radius: 50%;
        vertical-align: middle;
      }
    `}
`;
const Inputs = styled.div`
  flex: 1;
  padding: 8px 10px;
  input,
  textarea {
    width: 100%;
  }
  select option[value=''][disabled] {
    display: none;
  }
  [class*='-indicatorContainer'] {
    // 화살표
    width: 34px !important;
    padding: 0;
  }
  ${props =>
    props.width &&
    css`
      width: ${props.width};
      flex: none;
    `}
`;
const Text = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  font-size: 14px;
  color: ${theme.colors.font};
`;

export const WriteForm = {
  Layout,
  Fieldset,
  Label,
  Inputs,
  Text,
};
