import axios from 'axios';
import {
  API_USERS,
  API_USERS_ADMIN,
  API_USERS_CHANGEPW,
  API_USERS_DELETE,
  API_USERS_FINDID,
  API_USERS_ID,
  API_USERS_PROFILE,
  API_USERS_REPW,
  API_USERS_SIGNUP,
  API_USERS_UPDATE,
} from 'shared/constants/constants';

/*
|--------------------------------------------------------------------------
| 일반 유저 API
|--------------------------------------------------------------------------
*/

// 로그인 API
export async function loginUser(data) {
  return await axios
    .post(`${API_USERS}/login`, data)
    .then(response => {
      const { access_token } = response.data;
      // API 요청하는 콜마다 헤더에 accessToken 담아 보내도록 설정
      axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
      return access_token;
    })
    .catch(error => {
      return error;
    });
}

// 회원 정보 API
export async function getUserProfile() {
  const response = await axios.get(`${API_USERS_PROFILE}`);
  return response.data;
}

// 회원 생성 API
export async function createUsers({ data, isAdmin }) {
  const url = isAdmin ? `${API_USERS_ADMIN}` : `${API_USERS}`;
  const response = await axios.post(url, data);

  return response.data;
}

/**
 * POST /users/signup
 * 일반 회원 생성 API
 */
export async function postUsersSignup(data) {
  const response = await axios.post(`${API_USERS_SIGNUP}`, data);

  return response.data;
}

// 회원 아이디 찾기 API
export async function getFindId(name, email) {
  const params = { name, email };
  const response = await axios.get(API_USERS_FINDID, {
    params,
  });

  return response.data;
}

/**
 * GET /users/{id}
 * 회원 아이디 조회(중복 아이디 판별) API
 */
export async function getVerifyId(id) {
  const response = await axios.get(`${API_USERS_ID}${id}`);

  return response.data;
}

/**
 * PATCH /users/repw
 * 회원 비밀번호 재발급 API
 */
export async function patchUsersRepw(id, email) {
  const response = await axios.patch(`${API_USERS_REPW}`, { id, email });

  return response.data;
}

/**
 * PATCH /users/{idx}
 * 일반 회원 정보 수정 API
 */
export async function patchUsers(idx, data) {
  const response = await axios.patch(`${API_USERS_UPDATE}${idx}`, data);

  return response.data;
}

/**
 * DELETE /users/{idx}
 * 일반 회원 탈퇴 API
 */
export async function deleteUser(idx, data) {
  const response = await axios.delete(`${API_USERS_DELETE}${idx}`, {
    data: data,
  });

  return response.data;
}

/**
 * PATCH /users/changepw
 * 일반 회원 비밀번호 변경 API
 */
export async function patchUsersChangepw(data) {
  const response = await axios.patch(`${API_USERS_CHANGEPW}`, data);

  return response.data;
}

/*
|--------------------------------------------------------------------------
| 관리자 유저 API - 일반유저 공통 사용
|--------------------------------------------------------------------------
*/
// 관리자_로그인 API
export async function loginAdmin(data) {
  return await axios.post(`${API_USERS_ADMIN}/login`, data).then(response => {
    const { access_token } = response.data;
    // API 요청하는 콜마다 헤더에 accessToken 담아 보내도록 설정
    axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
    return access_token;
  });
}

// 관리자_회원상세정보 API
export async function getAdminProfile() {
  const response = await axios.get(`${API_USERS_ADMIN}/profile`);
  return response.data;
}

// 관리자_회원정보삭제 API
export async function deleteUsers({ data, type }) {
  const response = await axios.delete(`${API_USERS_ADMIN}?type=${type}`, {
    // API_URL_admin_users에서 API_URL_users로 변경필요
    data: { user_ids: data },
  });

  return response.data;
}
// 관리자_회원상세정보 API
export async function getUserDetails({ id, type }) {
  try {
    const response = await axios.get(
      `${API_USERS_ADMIN}/admin/${id}?type=${type}`,
    );
    return response.data;
  } catch (error) {}
}

// 관리자_회원정보수정 API
export async function updateUsers({ data, id, type }) {
  const response = await axios.patch(
    `${API_USERS_ADMIN}/admin/${id}?type=${type}`,
    data,
  );

  return response.data;
}
