import { useEffect, useState, useCallback } from 'react';

import { useForm } from 'react-hook-form';

import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { CustomSelect, styleDefault } from 'shared/root/styles/common/Select';

import { SortNumOptions } from 'shared/root/optionData';

import { API_EXCEL_SAMPLE_DOWN } from 'shared/constants/constants';

import { getDefectPlaceList } from 'redux/root/slices/defect';
import { setListIndex } from 'utils';

import { deleteDefectPlace } from 'api/defect';

import IconExcel from 'root/assets/imgs/icon-excel.svg';

import ConfirmModal from 'root/components/Modals/ConfirmModal';
import ToastMessage from 'root/components/Modals/ToastMessage';
import AddDefectModal from 'root/components/Modals/AddDefectModal';
import AddDefectExcelModal from 'root/components/Modals/AddDefectExcelModal';
import { openModal } from 'redux/common/slices/modals';

import BasicPagination from 'root/components/Pagination';

import NoResult from 'root/components/NoResult';
import CommonStyledComponents from 'shared/root/styles/CommonStyledComponents';

const { Card, Table, Input, Button } = CommonStyledComponents;

export default function DefectPlaceLists() {
  const dispatch = useDispatch();
  const params = useParams();
  const currentIdx = params.idx;
  const isUpdate = currentIdx ? true : false;

  //현장정보
  const tableLists = useSelector(state => state.rootDefect.defectPlaceList);

  // 페이지네이션
  const takeNum = 10; // 한 페이지 게시물수
  const [totalNum, setTotalNum] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [sortingNum, setSortingNum] = useState(takeNum);
  const [sortNumValue, setSortNumValue] = useState(SortNumOptions[0]);

  const initialValues = {
    checkedLists: [],
  };

  const { register, setValue, watch, reset, getValues } = useForm({
    defaultValues: initialValues,
  });

  //하자등록 항목 가져오기
  const setTableLists = useCallback(
    (sortingNum, activePage) => {
      dispatch(
        getDefectPlaceList({
          place: currentIdx,
          take: sortingNum,
          page: activePage,
        }),
      );
    },
    [dispatch, currentIdx],
  );

  // 체크박스 전체 선택
  // 데이터 개수와 체크된 아이템의 개수가 다를 경우 선택 해제 (하나라도 해제 시 선택 해제)
  const handleAllCheckedList = checked => {
    if (checked) {
      const arr = [];
      tableLists.results.forEach(el => arr.push(String(el.idx)));
      setValue('checkedLists', arr);
    } else {
      setValue('checkedLists', []);
    }
  };

  // 선택항목 삭제
  function handleClickDelete() {
    dispatch(
      openModal({
        Component: ConfirmModal,
        props: {
          message: '선택하신 항목을 삭제하시겠습니까?',
          handleModalSubmit: () => {
            const deleteIdxs = getValues('checkedLists');
            if (deleteIdxs.length !== 0) {
              deleteDefectPlace(watch().checkedLists)
                .then(response => {
                  setTableLists(sortingNum, activePage);
                  reset();
                  dispatch(
                    openModal({
                      Component: ToastMessage,
                      props: {
                        iconImg: 'complete',
                        message: '삭제되었습니다',
                      },
                    }),
                  );
                })
                .catch(error => {
                  console.log(error);
                  reset();
                  dispatch(
                    openModal({
                      Component: ToastMessage,
                      props: {
                        iconImg: 'fail',
                        message: '실패했습니다',
                      },
                    }),
                  );
                });
            }
          },
        },
      }),
    );
  }

  // 선택항목 정렬
  const handleSorting = e => {
    setSortingNum(e.value);
    setSortNumValue(e);
    reset(initialValues);
  };

  //페이지네이션 클릭 전환
  const handleClickPage = changePageNum => {
    setActivePage(changePageNum);
    reset(initialValues);
  };

  // 하자등록 항목 추가
  function handleClickAdd() {
    dispatch(
      openModal({
        Component: AddDefectModal,
        props: {
          placeIdx: currentIdx,
          buttonText: {
            submit: '추가',
          },
          handleModalSubmit: () => {
            setTableLists(sortingNum, activePage);
          },
        },
      }),
    );
  }

  // 하자등록 항목 추가
  function handleClickExcelPost() {
    dispatch(
      openModal({
        Component: AddDefectExcelModal,
        props: {
          placeIdx: currentIdx,
          buttonText: {
            submit: '등록',
          },
          handleModalSubmit: () => {
            setTableLists(sortingNum, activePage);
          },
        },
      }),
    );
  }

  // 하자등록 항목 수정
  function handleClickPatch(idx) {
    dispatch(
      openModal({
        Component: AddDefectModal,
        props: {
          defectIdx: idx,
          buttonText: {
            submit: '수정',
          },
          handleModalSubmit: () => {
            setTableLists(sortingNum, activePage);
          },
        },
      }),
    );
  }

  // 목록 불러오기
  useEffect(() => {
    //수정
    if (isUpdate) {
      setTableLists(sortingNum, activePage);
    }
    //추가
    else {
      reset(initialValues);
    }
  }, [sortingNum, activePage, isUpdate, setTableLists]);

  // 목록 총 개수 셋팅
  useEffect(() => {
    setTotalNum(tableLists.total);
  }, [tableLists.total]);

  // 셀렉트박스 리셋
  const handleSelectReset = e => {
    setSortNumValue(SortNumOptions[0]);
  };

  // 페이지전환 시 리셋
  useEffect(() => {
    reset(initialValues);
    setActivePage(1);
    setSortingNum(takeNum);
    handleSelectReset();
  }, [params]);

  return (
    <>
      {isUpdate ? (
        <Card.Layout>
          <Card.Header>
            <Card.HeaderTitle>하자등록 항목</Card.HeaderTitle>
            <Card.HeaderCount>
              총 <em>{tableLists.total ? tableLists.total : 0}</em>개
            </Card.HeaderCount>
            <></>
            <Card.HeaderSorting>
              <CustomSelect
                name="sortNum"
                styles={styleDefault}
                options={SortNumOptions}
                value={sortNumValue}
                isSearchable={false}
                onChange={e => {
                  handleSorting(e);
                }}
              />
            </Card.HeaderSorting>
          </Card.Header>
          <Card.Body>
            <Table.Options>
              <div>
                <a href={API_EXCEL_SAMPLE_DOWN} download>
                  <Button theme={'outline-default'} size={'md'}>
                    <img src={IconExcel} alt="" />
                    엑셀샘플 다운로드
                  </Button>
                </a>
                <Button
                  theme={'outline-default'}
                  size={'md'}
                  onClick={handleClickExcelPost}
                >
                  <img src={IconExcel} alt="" />
                  엑셀 등록하기
                </Button>
              </div>
              <div>
                <Button
                  theme={'point2'}
                  size={'md'}
                  status={watch().checkedLists.length > 0 ? '' : 'disabled'}
                  onClick={handleClickDelete}
                >
                  선택삭제
                </Button>
                <Button theme={'point2'} size={'md'} onClick={handleClickAdd}>
                  항목추가
                </Button>
              </div>
            </Table.Options>
            <Table.Container>
              <Table.Layout>
                <colgroup>
                  <col style={{ width: '80px' }} />
                  <col style={{ width: '80px' }} />
                  <col style={{ width: '130px' }} />
                  <col style={{ width: '135px' }} />
                  <col style={{ width: '135px' }} />
                  <col style={{ width: '*' }} />
                  <col style={{ width: '25px' }} />
                </colgroup>
                <thead>
                  <Table.Tr>
                    <Table.Th type={'checkbox'}>
                      <Input.Checkbox>
                        <input
                          type="checkbox"
                          onChange={e => handleAllCheckedList(e.target.checked)}
                          checked={
                            totalNum &&
                            sortingNum &&
                            tableLists.pageTotal &&
                            (watch().checkedLists.length === sortingNum ||
                              watch().checkedLists.length >= totalNum)
                              ? true
                              : false
                          }
                        />
                      </Input.Checkbox>
                    </Table.Th>
                    <Table.Th>번호</Table.Th>
                    <Table.Th>수정</Table.Th>
                    <Table.Th>동</Table.Th>
                    <Table.Th>호수</Table.Th>
                    <Table.Th hasScroll>위치</Table.Th>
                  </Table.Tr>
                </thead>
                <tbody>
                  {tableLists.total === 0 ? (
                    <Table.Tr noHover>
                      <Table.Td colSpan="6">
                        <NoResult />
                      </Table.Td>
                    </Table.Tr>
                  ) : (
                    tableLists.results && (
                      <Table.Tr>
                        <Table.Td hasTable colSpan="5">
                          <Table.Layout>
                            <colgroup>
                              <col style={{ width: '80px' }} />
                              <col style={{ width: '80px' }} />
                              <col style={{ width: '130px' }} />
                              <col style={{ width: '135px' }} />
                              <col style={{ width: '135px' }} />
                            </colgroup>
                            <tbody>
                              {tableLists.results.map((item, itemIdx) => (
                                <Table.Tr
                                  key={itemIdx}
                                  type={'link'}
                                  // onClick={(e, itemIdx) =>
                                  //   handleClickLink(e, itemIdx)
                                  // }
                                >
                                  <Table.Td type={'checkbox'}>
                                    <Input.Checkbox>
                                      <input
                                        type="checkbox"
                                        value={item.idx}
                                        {...register('checkedLists')}
                                      />
                                    </Input.Checkbox>
                                  </Table.Td>
                                  <Table.Td>
                                    {setListIndex(
                                      itemIdx,
                                      activePage,
                                      sortingNum,
                                      totalNum,
                                    )}
                                  </Table.Td>
                                  <Table.Td>
                                    <Button
                                      theme={'underline'}
                                      size={'sm'}
                                      onClick={() => {
                                        handleClickPatch(item.idx);
                                      }}
                                    >
                                      수정하기
                                    </Button>
                                  </Table.Td>
                                  <Table.Td>{item.sort1}</Table.Td>
                                  <Table.Td>{item.sort2}</Table.Td>
                                </Table.Tr>
                              ))}
                            </tbody>
                          </Table.Layout>
                        </Table.Td>
                        <Table.Td hasTable>
                          <Table.Layout>
                            <tbody>
                              {tableLists.results.map((item, itemIdx) => (
                                <Table.Tr noHover key={itemIdx}>
                                  <Table.Td nowrap>{item.sort3}</Table.Td>
                                </Table.Tr>
                              ))}
                            </tbody>
                          </Table.Layout>
                        </Table.Td>
                        <Table.Td type={'blank'}></Table.Td>
                      </Table.Tr>
                    )
                  )}
                </tbody>
              </Table.Layout>
            </Table.Container>
            <Table.Options>
              <div>
                <a href={API_EXCEL_SAMPLE_DOWN} download="record_sample">
                  <Button theme={'outline-default'} size={'md'}>
                    <img src={IconExcel} alt="" />
                    엑셀샘플 다운로드
                  </Button>
                </a>
                <Button
                  theme={'outline-default'}
                  size={'md'}
                  onClick={handleClickExcelPost}
                >
                  <img src={IconExcel} alt="" />
                  엑셀 등록하기
                </Button>
              </div>
              <div>
                <Button
                  theme={'point2'}
                  size={'md'}
                  status={watch().checkedLists.length > 0 ? '' : 'disabled'}
                  onClick={handleClickDelete}
                >
                  선택삭제
                </Button>
                <Button theme={'point2'} size={'md'} onClick={handleClickAdd}>
                  항목추가
                </Button>
              </div>
            </Table.Options>
            {totalNum ? (
              <Table.Pagination>
                <BasicPagination
                  total={totalNum}
                  limit={sortingNum}
                  activePage={activePage}
                  handleClick={handleClickPage}
                />
              </Table.Pagination>
            ) : null}
          </Card.Body>
        </Card.Layout>
      ) : null}
    </>
  );
}
