import styled from '@emotion/styled';

import moment from 'moment/moment';

import { theme } from 'shared/styles/theme';

import { Link } from 'react-router-dom';

import IconDelete from 'assets/imgs/icon_list_delete.svg';

export default function DefectListItem({ lists, handleDelete }) {
  return (
    <div>
      {lists.map(({ id, data, submit_status }) => (
        <ListItems key={id}>
          <ListCont to={`/defect/details/${id}`}>
            <ThumbImg img={data.base64}></ThumbImg>

            <InfoTextItem>
              <InfoTitle>{data.filename}</InfoTitle>
              {/* 1: 전송완료 */}
              {submit_status === 1 ? (
                <>
                  <InfoTime>
                    {moment(data.createdate).format('YYYY.MM.DD HH:mm:ss') ||
                      ''}
                  </InfoTime>
                </>
              ) : (
                // 0: 전송대기
                <InfoStatus>전송대기</InfoStatus>
              )}
            </InfoTextItem>
          </ListCont>
          <DeleteItem
            onClick={() => handleDelete(id, submit_status, data.localfilename)}
          >
            삭제하기
          </DeleteItem>
        </ListItems>
      ))}
    </div>
  );
}

const ListItems = styled.div`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;
const ListCont = styled(Link)`
  display: flex;
  align-items: center;
  flex: 1;
`;
const ThumbImg = styled.div`
  width: 50px;
  height: 50px;
  margin: 0 14px 0 0;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-position: center;
  background-size: cover;
  background-image: ${props => (props.img ? `url(${props.img})` : 'none')};
`;
const InfoTextItem = styled.div`
  flex: 1;
`;
const InfoTitle = styled.p`
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.38;
  word-break: break-all;
`;
const InfoTime = styled.p`
  font-size: 13px;
  line-height: 1.23;
  color: #94999a;
`;
const InfoStatus = styled.p`
  font-size: 13px;
  line-height: 1.23;
  color: ${theme.colors.point};
`;
const DeleteItem = styled.div`
  width: 44px;
  height: 44px;
  margin-right: -15px;
  margin-left: auto;
  background: url(${IconDelete}) no-repeat center/ contain;
  font-size: 0;
  color: transparent;
  cursor: pointer;
`;
