import styled from '@emotion/styled';
import { getFindId } from 'api/user';
import TextInput from 'components/Form/TextInput';
import ToastMessage from 'components/Modals/ToastMessage';
import PageLayout from 'components/PageLayout';
import Description from 'components/signup/Description';
import Paragraph from 'components/signup/Paragraph';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { openModal } from 'redux/common/slices/modals';

import CommonStyledComponents from 'shared/styles/CommonStyledComponents';
import { theme } from 'shared/styles/theme';
const { Area, Button, FormField, FieldContainer, FieldLabel } =
  CommonStyledComponents;

const initialValues = {
  name: '',
  email: '',
};

export default function FindAccount() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [idResult, setIdResult] = useState({
    id: '',
    date: '',
  });
  const idFound = Boolean(idResult.id);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    defaultValues: initialValues,
  });

  async function handleClickSubmit(data) {
    try {
      const res = await getFindId(data.name, data.email);
      if (res.id) {
        dispatch(
          openModal({
            Component: ToastMessage,
            props: {
              iconImg: 'complete',
              message: '일치하는 회원정보를 찾았습니다.',
            },
          }),
        );
        setIdResult({
          id: res.id,
          date: res.createdAt,
        });
      } else {
        dispatch(
          openModal({
            Component: ToastMessage,
            props: {
              iconImg: 'fail',
              message: '일치하는 회원정보가 없습니다.',
            },
          }),
        );
      }
    } catch (error) {}
  }

  return (
    <PageLayout title="아이디 찾기" backBtn>
      <FindAccountContainer>
        <Area>
          <Paragraph>아이디 찾기</Paragraph>
          <Description>
            {idFound
              ? '고객님의 정보와 일치하는 아이디 정보 입니다.'
              : '회원가입 시 등록한 이름과 이메일을 입력해주세요.'}
          </Description>

          {idFound ? (
            <ResultWrapper>
              <ResultIdText>{idResult.id}</ResultIdText>
              <ResultDate>가입일 : {idResult.date}</ResultDate>
            </ResultWrapper>
          ) : (
            <>
              <FormField align="vertical">
                <FieldLabel>이름</FieldLabel>
                <FieldContainer>
                  <TextInput
                    register={register('name', {
                      required: '이름을 입력해주세요.',
                    })}
                    errors={errors}
                    placeholder="이름"
                  />
                </FieldContainer>
              </FormField>

              <FormField align="vertical">
                <FieldLabel>이메일</FieldLabel>
                <FieldContainer>
                  <TextInput
                    register={register('email', {
                      required: '이메일을 입력해주세요.',
                      pattern: {
                        value:
                          /^([0-9a-zA-Z_.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/,
                        message: '올바른 이메일 주소만 입력 가능합니다',
                      },
                    })}
                    errors={errors}
                    placeholder="이메일"
                  />
                </FieldContainer>
              </FormField>
            </>
          )}

          <ButtonWrapper>
            {idFound ? (
              <Button
                type="button"
                size={'full-xl'}
                theme={'point'}
                status={!isValid && 'disabled'}
                onClick={() => navigate('/login')}
              >
                로그인
              </Button>
            ) : (
              <Button
                type="button"
                size={'full-xl'}
                theme={'point'}
                status={!isValid && 'disabled'}
                onClick={handleSubmit(d => handleClickSubmit(d))}
              >
                다음
              </Button>
            )}
          </ButtonWrapper>
        </Area>
      </FindAccountContainer>
    </PageLayout>
  );
}

const FindAccountContainer = styled.div`
  padding: calc(20px + ${theme.notch.top}) 0;
`;

const ButtonWrapper = styled.div`
  margin-top: 30px;
`;

const ResultWrapper = styled.div`
  text-align: center;
  margin-top: 30px;
`;
const ResultIdText = styled.p`
  color: var(--primary_01, #0089cf);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 32px */
`;

const ResultDate = styled.p`
  margin-top: 6px;
  color: var(--gray_200, #94999a);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
`;
