import axios from 'axios';
import { useState, useEffect, useRef } from 'react';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import { useDispatch, useSelector } from 'react-redux';

import ReactMoment from 'react-moment';

import styled from '@emotion/styled';

import ConfirmModal from 'root/components/Modals/ConfirmModal';
import ToastMessage from 'root/components/Modals/ToastMessage';
import { openModal } from 'redux/common/slices/modals';

import { deleteDefect } from 'api/defect';
import { getPlaceDetails } from 'redux/root/slices/place';
import { getDefectDetails } from 'redux/root/slices/defect';

// import { getPlaceList } from 'redux/root/slices/place';

import DefectsDetailsCanvas from './DefectsDetailsCanvas';

import {
  WorkStatusData,
  WorkMethodData,
  DefectTypeData,
} from 'shared/root/defectData';

import { API_FILE, API_URL } from 'shared/constants/constants';

import PageLayout from 'root/components/PageLayout';
import TitleContainer from 'root/components/TitleContainer';
import ContentsContainer from 'root/components/ContentsContainer';

import IconDownload from 'root/assets/imgs/icon-download.svg';
import Noimage from 'root/assets/imgs/common_noimg_file.svg';
import CommonStyledComponents from 'shared/root/styles/CommonStyledComponents';
import TextInput from 'root/components/Form/TextInput';
import { CustomSelect, styleBox } from 'shared/root/styles/common/Select';
import { dirtyValues, reorderObject } from 'utils';

const { Card, WriteForm, Button, ButtonsGrid, ErrorMessage } =
  CommonStyledComponents;

export default function DefectsDetails() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [filename, setFilename] = useState(); // 파일정보 > 파일명 설정

  const [infoDownlink, setInfoDownlink] = useState(); // 정보사진 - raw 파일명
  const [originDownlink, setOriginDownlink] = useState(); // 원본사진 - raw 파일명

  const [infoImgUrl, setInfoImgUrl] = useState(); // 정보사진 - 이미지 경로
  const [originImgUrl, setOriginImgUrl] = useState(); // 원본사진 - 이미지 경로

  const currentPlaceIdx = params.placeId; //현재 현장 index
  const currentDefectIdx = params.defectId; //현재 하자 index

  const currentPlace = useSelector(state => state.place.placeDetails.place); //현재 현장 정보
  const dftDetails = useSelector(state => state.rootDefect.defectDetails); // 현재 하자 정보

  useEffect(() => {
    // 정보사진
    axios.get(`${API_FILE}/dft_info_img/${currentDefectIdx}`).then(response => {
      const result = response.data.dft_info_img;
      const path_src = `${API_URL}${result.file_html_full_path}`;
      const raw_name = result.file_raw_name;
      const downlink = `${API_URL}/file/download/${raw_name}`;
      const file_origin_name = result.file_orig_name;

      setInfoImgUrl(path_src); // 사진 src 경로
      setInfoDownlink(downlink); // 정보사진 다운로드 링크 설정
      setFilename(file_origin_name); // 파일정보 > 파일명 설정
    });

    // 원본사진
    axios
      .get(`${API_FILE}/dft_origin_img/${currentDefectIdx}`)
      .then(response => {
        const result = response.data.dft_origin_img;
        const path_src = `${API_URL}${result.file_html_full_path}`;
        const raw_name = result.file_raw_name;
        const downlink = `${API_URL}/file/download/${raw_name}`;

        setOriginImgUrl(path_src); // 사진 src 경로
        setOriginDownlink(downlink);
      });
  }, [currentDefectIdx]);

  useEffect(() => {
    dispatch(getPlaceDetails(currentPlaceIdx));

    dispatch(getDefectDetails(currentDefectIdx))
      .then(res => {
        if (!res.payload) {
          navigate('/root/notfound', { replace: true });
        }
      })
      .catch(data => {});
  }, [currentDefectIdx, currentPlaceIdx, dispatch, params]);

  // 삭제
  function handleClickDelete() {
    dispatch(
      openModal({
        Component: ConfirmModal,
        props: {
          message: '하자정보를 삭제하시겠습니까?',
          handleModalSubmit: () => {
            deleteDefect([currentDefectIdx])
              .then(response => {
                dispatch(
                  openModal({
                    Component: ToastMessage,
                    props: {
                      iconImg: 'complete',
                      message: '삭제되었습니다',
                    },
                  }),
                  navigate(-1),
                );
              })
              .catch(error => {
                console.log(error);
                dispatch(
                  openModal({
                    Component: ToastMessage,
                    props: {
                      iconImg: 'fail',
                      message: '실패했습니다',
                    },
                  }),
                );
              });
          },
        },
      }),
    );
  }

  //수정하기=============================================================
  const {
    register,
    setValue,
    watch,
    handleSubmit,
    reset,
    control,
    formState: { errors, dirtyFields, isDirty },
  } = useForm({ mode: 'onChange' });

  useEffect(() => {
    //reset으로 defaultValues 지정
    reset({
      idx: currentDefectIdx,
      sort1: dftDetails.dft_sort1, //동
      sort2: dftDetails.dft_sort2, //호수
      sort3: dftDetails.dft_sort3, //위치
      status: dftDetails.dft_status, //작업상태
      type: dftDetails.type, //하자유형
      content: dftDetails.dft_content, //내용
      work_method: dftDetails.dft_work_method, //작업방법
      replacement_square_meter: dftDetails.dft_replacement_square_meter, //교체면적_m
      replacement_sheet: dftDetails.dft_replacement_sheet, //교체면적_장
      shooting_day: dftDetails.dft_shooting_day, //교체면적_장
    });
  }, [dftDetails]);

  // watch(console.log(errors));

  //현장항목, 상세내용=============================================================
  const placeDetails = useSelector(state => state.place.placeDetails);
  const dfpList = placeDetails.dfp;
  /**
   * 옵션1: 동
   * 옵션2: 호수
   * 옵션3: 위치
   */
  // dfpList오브젝트 key값을 옵션1 배열로 생성
  const sort1Arr =
    dfpList &&
    Object.keys(dfpList).map((val, idx) => {
      return (val = `${val}`);
    });

  //옵션2,옵션3 분리하여 'restSortArr'배열로 생성
  const restSortArr =
    dfpList &&
    watch('sort1') &&
    typeof watch('sort1') === 'object' &&
    dfpList[watch('sort1').value] !== null &&
    dfpList[watch('sort1').value] !== undefined &&
    Object.values(dfpList[watch('sort1').value]);

  //옵션2 sort2Arr 배열로 생성
  const sort2Arr =
    dfpList &&
    watch('sort1') &&
    Array.isArray(restSortArr) &&
    restSortArr.map((val, idx) => {
      return val.sort2;
    });

  //옵션2 sort3Arr 배열로 생성
  const sort3Arr =
    dfpList &&
    watch('sort1') &&
    watch('sort2') &&
    Array.isArray(restSortArr) &&
    restSortArr.find(elem => elem.sort2 === watch('sort2').value)?.sort3;

  // 상세페이지 form data 초기세팅
  useEffect(() => {
    if (dftDetails?.dft_sort1) {
      setValue('sort1', {
        value: dftDetails?.dft_sort1,
        label: dftDetails?.dft_sort1,
      });
    }
    if (dftDetails?.dft_sort2) {
      setValue('sort2', {
        value: dftDetails?.dft_sort2,
        label: dftDetails?.dft_sort2,
      });
    }
    if (dftDetails?.dft_sort3) {
      setValue('sort3', {
        value: dftDetails?.dft_sort3,
        label: dftDetails?.dft_sort3,
      });
    }
    setValue(
      'status',
      dftDetails.dft_status && WorkStatusData
        ? WorkStatusData[
            WorkStatusData.findIndex(obj => obj.value === dftDetails.dft_status)
          ]
        : null,
    );
    setValue(
      'type',
      dftDetails.dft_type && DefectTypeData
        ? DefectTypeData[
            DefectTypeData.findIndex(obj => obj.value === dftDetails.dft_type)
          ]
        : null,
    );
    setValue(
      'work_method',
      dftDetails.dft_work_method && WorkMethodData
        ? WorkMethodData[
            WorkMethodData.findIndex(
              obj => obj.value === dftDetails.dft_work_method,
            )
          ]
        : null,
    );
  }, [dftDetails]);

  const [changeStatus, setChangeStatus] = useState(false);
  const [changeCompletedWork, setChangeCompletedWork] = useState(false);

  // watch(
  //   console.log(
  //     dftDetails,
  //     changeCompletedWork,
  //     watch('status')?.value,
  //     watch('work_method')?.value,
  //   ),
  // );

  //작업상태,작업방법 변경 시 관련 데이터 초기화
  useEffect(() => {
    if (changeStatus) {
      setValue('work_method', '');
      setValue('replacement_square_meter', '');
      setValue('replacement_sheet', '');
    }
  }, [changeStatus]);
  useEffect(() => {
    if (changeCompletedWork) {
      setValue('replacement_square_meter', '');
      setValue('replacement_sheet', '');
    }
  }, [changeCompletedWork]);

  useEffect(() => {
    console.log('errors', errors);
  }, [errors]);

  //저장하기
  const [formData, setFormData] = useState({});
  const handleClickSubmit = data => {
    onSubmit(data);
  };
  const onSubmit = data => {
    dispatch(
      openModal({
        Component: ConfirmModal,
        props: {
          message: (
            <>
              <div>수정된 내용을 저장하시겠습니까?</div>
              <div>
                변경된 하자정보는 '파일명'과 '정보표시사진'에 적용됩니다.
              </div>
            </>
          ),
          handleModalSubmit: () => {
            const result = reorderObject(data);
            // content항목만 필수항목이 아니라 빈값을 입력할 수도 있음
            // 빈값 입력 시 수정사항이 반영안되는 이슈로 ' ' 공백문자로 변경되도록 함
            result.content = data.content ? data.content : ' ';
            setFormData(result);
          },
        },
      }),
    );
  };

  return (
    <>
      <PageLayout>
        <TitleContainer>
          <h2>
            <span>하자관리</span>
            <span>현장별 하자관리</span>
            <span>{currentPlace && currentPlace.name}</span>
            <b>상세</b>
          </h2>
          <ButtonsGrid>
            <Button
              type="button"
              theme={'outline-default'}
              size={'md'}
              onClick={handleClickDelete}
            >
              삭제
            </Button>
            <Button
              type="button"
              size={'md'}
              theme={'point'}
              status={isDirty ? '' : 'disabled'}
              onClick={handleSubmit(handleClickSubmit)}
            >
              저장
            </Button>
          </ButtonsGrid>
        </TitleContainer>

        <ContentsContainer>
          <Card.Layout>
            <Card.Header>
              <Card.HeaderTitle>하자정보</Card.HeaderTitle>
            </Card.Header>
            <Card.Body>
              {dftDetails && dftDetails.length !== 0 ? (
                <WriteForm.Layout>
                  <WriteForm.Fieldset>
                    <WriteForm.Label>현장명</WriteForm.Label>
                    <WriteForm.Text>
                      {currentPlace && currentPlace.name}
                    </WriteForm.Text>
                  </WriteForm.Fieldset>
                  <WriteForm.Fieldset>
                    <WriteForm.Label>동</WriteForm.Label>
                    <WriteForm.Inputs>
                      <Controller
                        control={control}
                        name="sort1"
                        rules={{ required: true }}
                        render={({ field }) => (
                          <CustomSelect
                            {...field}
                            inputId="sort1"
                            width={'400px'}
                            styles={styleBox}
                            isSearchable={false}
                            placeholder="선택해주세요."
                            options={
                              sort1Arr &&
                              sort1Arr.map((item, idx) => {
                                return { value: item, label: item };
                              })
                            }
                            onChange={e => {
                              setValue('sort1', e);
                              setValue('sort2', '');
                              setValue('sort3', '');
                            }}
                          />
                        )}
                      />
                    </WriteForm.Inputs>
                  </WriteForm.Fieldset>
                  <WriteForm.Fieldset>
                    <WriteForm.Label>호수</WriteForm.Label>
                    <WriteForm.Inputs>
                      <Controller
                        control={control}
                        name="sort2"
                        rules={{ required: true }}
                        render={({ field }) => (
                          <CustomSelect
                            {...field}
                            inputId="sort2"
                            width={'400px'}
                            styles={styleBox}
                            isSearchable={false}
                            placeholder="선택해주세요."
                            options={
                              sort2Arr &&
                              sort2Arr.map((item, idx) => {
                                return { value: item, label: item };
                              })
                            }
                            onChange={e => {
                              setValue('sort2', e);
                              setValue('sort3', '');
                            }}
                            isDisabled={watch('sort1') ? false : true}
                          />
                        )}
                      />
                    </WriteForm.Inputs>
                  </WriteForm.Fieldset>
                  <WriteForm.Fieldset>
                    <WriteForm.Label>위치</WriteForm.Label>
                    <WriteForm.Inputs>
                      <Controller
                        control={control}
                        name="sort3"
                        rules={{ required: true }}
                        render={({ field }) => (
                          <CustomSelect
                            {...field}
                            inputId="sort3"
                            width={'400px'}
                            styles={styleBox}
                            isSearchable={false}
                            placeholder="선택해주세요."
                            options={
                              sort3Arr &&
                              sort3Arr.split(',').map((item, idx) => {
                                return { value: item, label: item };
                              })
                            }
                            isDisabled={
                              watch('sort1') && watch('sort2') ? false : true
                            }
                          />
                        )}
                      />
                    </WriteForm.Inputs>
                  </WriteForm.Fieldset>
                  <WriteForm.Fieldset>
                    <WriteForm.Label>작업상태</WriteForm.Label>
                    <WriteForm.Inputs>
                      <Controller
                        control={control}
                        name="status"
                        rules={{ required: true }}
                        render={({ field }) => (
                          <CustomSelect
                            {...field}
                            inputId="status"
                            width={'400px'}
                            styles={styleBox}
                            isSearchable={false}
                            placeholder="선택해주세요."
                            options={WorkStatusData}
                            onChange={e => {
                              setValue('status', e);
                              setChangeStatus(true);
                            }}
                          />
                        )}
                      />
                    </WriteForm.Inputs>
                  </WriteForm.Fieldset>
                  <WriteForm.Fieldset>
                    <WriteForm.Label>유형</WriteForm.Label>
                    <WriteForm.Inputs>
                      <Controller
                        control={control}
                        name="type"
                        rules={{ required: true }}
                        render={({ field }) => (
                          <CustomSelect
                            {...field}
                            inputId="type"
                            width={'400px'}
                            styles={styleBox}
                            isSearchable={false}
                            placeholder="선택해주세요."
                            options={DefectTypeData}
                          />
                        )}
                      />
                    </WriteForm.Inputs>
                  </WriteForm.Fieldset>
                  <WriteForm.Fieldset>
                    <WriteForm.Label>내용</WriteForm.Label>
                    <WriteForm.Inputs>
                      <TextInput
                        maxWidth={'400px'}
                        register={register('content', {
                          maxLength: {
                            value: 20,
                            message: '20자 이하로만 작성이 가능합니다.',
                          },
                          onChange: e => {
                            const text = e.target.value;
                            if (e.target.value.length >= 20) {
                              e.target.value = text.substr(0, 21);
                            }
                          },
                        })}
                        defaultValue={dftDetails.dft_content}
                        placeholder="내용을 입력하세요"
                        errors={errors}
                      />
                    </WriteForm.Inputs>
                  </WriteForm.Fieldset>

                  {(!changeStatus &&
                    dftDetails.dft_status === 'completed_work') ||
                  (changeStatus &&
                    watch('status')?.value === 'completed_work') ? (
                    <WriteForm.Fieldset>
                      <WriteForm.Label>작업방법</WriteForm.Label>
                      <WriteForm.Inputs>
                        <Controller
                          name="work_method"
                          control={control}
                          rules={{
                            required: '작업방법을 선택해주세요.',
                          }}
                          render={({ field }) => (
                            <CustomSelect
                              {...field}
                              inputId="work_method"
                              width={'400px'}
                              options={WorkMethodData}
                              styles={styleBox}
                              isSearchable={false}
                              placeholder="선택해주세요."
                              onChange={e => {
                                setValue('work_method', e);
                                setChangeCompletedWork(true);
                              }}
                            />
                          )}
                        />
                        {/* <ErrorMessage>
                          {errors?.work_method.message}
                        </ErrorMessage> */}
                      </WriteForm.Inputs>
                    </WriteForm.Fieldset>
                  ) : null}

                  {(!changeStatus &
                    !changeCompletedWork &
                    (dftDetails.dft_status === 'completed_work') &&
                    dftDetails.dft_work_method === 'replacement') ||
                  (changeStatus &
                    changeCompletedWork &
                    (watch('status')?.value === 'completed_work') &&
                    watch('work_method')?.value === 'replacement') ||
                  (!changeStatus &
                    changeCompletedWork &
                    (watch('status')?.value === 'completed_work') &&
                    watch('work_method')?.value === 'replacement') ? (
                    <WriteForm.Fieldset>
                      <WriteForm.Label>교체면적</WriteForm.Label>
                      <WriteForm.Inputs>
                        <ReplacementWriteForm>
                          <TextInput
                            register={register('replacement_square_meter', {
                              required: true,
                              // pattern: /^[0-9]*$/,
                            })}
                            errors={errors}
                            suffix="m"
                            defaultValue={
                              dftDetails.dft_replacement_square_meter
                            }
                          />
                          <TextInput
                            register={register('replacement_sheet', {
                              required: true,
                              // pattern: /^[0-9]*$/,
                            })}
                            errors={errors}
                            suffix="장"
                            defaultValue={dftDetails.dft_replacement_sheet}
                          />
                        </ReplacementWriteForm>
                      </WriteForm.Inputs>
                    </WriteForm.Fieldset>
                  ) : null}

                  <WriteForm.Fieldset>
                    <WriteForm.Label>촬영일</WriteForm.Label>
                    <WriteForm.Text>
                      {dftDetails.dft_shooting_day ? (
                        <ReactMoment
                          date={dftDetails.dft_shooting_day}
                          format="YYYY-MM-DD&nbsp;&nbsp;HH:mm:ss"
                        />
                      ) : null}
                    </WriteForm.Text>
                  </WriteForm.Fieldset>
                  <WriteForm.Fieldset>
                    <WriteForm.Label>등록일</WriteForm.Label>
                    <WriteForm.Text>
                      <ReactMoment
                        date={dftDetails.dft_createdAt}
                        format="YYYY-MM-DD&nbsp;&nbsp;HH:mm:ss"
                      />
                    </WriteForm.Text>
                  </WriteForm.Fieldset>
                  <WriteForm.Fieldset>
                    <WriteForm.Label>작성자 아이디</WriteForm.Label>
                    <WriteForm.Text>
                      {dftDetails.user &&
                        `${dftDetails.user.user_id} (${dftDetails.user.user_name})`}
                    </WriteForm.Text>
                  </WriteForm.Fieldset>
                </WriteForm.Layout>
              ) : null}
            </Card.Body>
          </Card.Layout>

          <Card.Layout>
            <Card.Header>
              <Card.HeaderTitle>파일정보</Card.HeaderTitle>
            </Card.Header>
            <Card.Body>
              <WriteForm.Layout>
                <WriteForm.Fieldset>
                  <WriteForm.Label>파일명</WriteForm.Label>
                  <WriteForm.Text>{filename}</WriteForm.Text>
                </WriteForm.Fieldset>
                {
                  // PC등록 시 표시되지않음
                  dftDetails.dft_device_key && (
                    <WriteForm.Fieldset>
                      <WriteForm.Label>휴대폰 일련번호</WriteForm.Label>
                      <WriteForm.Text>
                        {dftDetails.dft_device_key || ''}
                      </WriteForm.Text>
                    </WriteForm.Fieldset>
                  )
                }
              </WriteForm.Layout>
            </Card.Body>
          </Card.Layout>

          <Card.Layout>
            <Card.Header>
              <Card.HeaderTitle>사진정보</Card.HeaderTitle>
            </Card.Header>
            <Card.Body>
              <WriteForm.Layout>
                <PhotoContainer>
                  <PhotoItem>
                    {infoImgUrl ? (
                      <PhotoImage src={`${infoImgUrl}`} alt="정보 표시 사진" />
                    ) : (
                      <PhotoImage src={Noimage} alt="" />
                    )}
                    <ButtonItem href={`${infoDownlink}`} download>
                      <Button theme={'outline-default'} size={'md'}>
                        <img src={IconDownload} alt="" />
                        정보 표시 사진 다운로드
                      </Button>
                    </ButtonItem>
                  </PhotoItem>
                  <PhotoItem>
                    {originImgUrl ? (
                      <PhotoImage src={`${originImgUrl}`} alt="원본 사진" />
                    ) : (
                      <PhotoImage src={Noimage} alt="" />
                    )}
                    <ButtonItem href={`${originDownlink}`} download>
                      <Button theme={'outline-default'} size={'md'}>
                        <img src={IconDownload} alt="" />
                        원본 사진 다운로드
                      </Button>
                    </ButtonItem>
                  </PhotoItem>
                </PhotoContainer>
              </WriteForm.Layout>
              {/*숨겨진 이미지 캔버스*/}
              <DefectsDetailsCanvas
                originImgUrl={originDownlink}
                placeData={currentPlace}
                dftData={dftDetails}
                formData={formData}
                setValue={setValue}
                // isDirty={isDirty}
                // watch={watch}
                // formRef={childFormRef}
              />
            </Card.Body>
          </Card.Layout>
        </ContentsContainer>
      </PageLayout>
    </>
  );
}

const PhotoContainer = styled.div`
  display: flex;
  gap: 25px;
`;
const PhotoItem = styled.div`
  display: flex;
  width: 50%;
  gap: 25px;
  word-break: break-all;
`;
const PhotoImage = styled.img`
  width: 100%;
  max-width: 342px;
`;
const ButtonItem = styled.a`
  button {
    pointer-events: none !important;
  }
`;

const ReplacementWriteForm = styled.div`
  display: flex;
  width: 400px;
  column-gap: 4px;
`;
