import styled from '@emotion/styled';

import IconNotice from 'root/assets/imgs/icon-notice.svg';

export default function NoResult() {
  return <NoResultContainer>데이터가 존재하지 않습니다.</NoResultContainer>;
}

const NoResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 610px;
  &::before {
    content: '';
    display: block;
    width: 22px;
    height: 22px;
    margin-bottom: 15px;
    background-image: url(${IconNotice});
  }
  p {
    font-size: 14px;
    line-height: 1.57;
    color: #6c7293;
  }
`;
