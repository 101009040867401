import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useForm } from 'react-hook-form';

import { getSettings } from 'redux/root/slices/settings';

import { postSettings } from 'api/settings';

import PageLayout from 'root/components/PageLayout';
import TitleContainer from 'root/components/TitleContainer';
import ContentsContainer from 'root/components/ContentsContainer';

import FormCkEditor from 'root/components/FormCkEditor';

import ToastMessage from 'root/components/Modals/ToastMessage';
import { openModal } from 'redux/common/slices/modals';

import CommonStyledComponents from 'shared/root/styles/CommonStyledComponents';
const { Card, WriteForm, Button, ButtonsGrid } = CommonStyledComponents;

export default function GuidePage() {
  const dispatch = useDispatch();

  const { guide } = useSelector(state => state.settings.settingInfo);

  const {
    handleSubmit,
    setValue,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      guide: '',
    },
  });

  useEffect(() => {
    dispatch(getSettings('guide')).then(res => {
      setValue('guide', res.payload.guide.value);
    });
  }, []);

  function onSubmit(data) {
    postSettings(data)
      .then(response => {
        dispatch(
          openModal({
            Component: ToastMessage,
            props: {
              iconImg: 'complete',
              message: '저장되었습니다.',
            },
          }),
        );
      })
      .catch(error => {
        dispatch(
          openModal({
            Component: ToastMessage,
            props: {
              iconImg: 'fail',
              message:
                error.request.status === 413
                  ? '용량이 초과되어 등록이 불가능합니다. (최대 500 MB)'
                  : error.message, //'실패했습니다',
            },
          }),
        );
      });
  }

  return (
    <PageLayout>
      <TitleContainer>
        <h2>
          <span>환경설정</span>
          <b>사용 설명서 설정</b>
        </h2>
        <ButtonsGrid>
          <Button
            type="submit"
            theme={'point'}
            size={'md'}
            onClick={handleSubmit(onSubmit)}
            status={isDirty ? '' : 'disabled'}
          >
            저장
          </Button>
        </ButtonsGrid>
      </TitleContainer>

      <ContentsContainer>
        <Card.Layout>
          <Card.Header>
            <Card.HeaderTitle>사용 설명서</Card.HeaderTitle>
          </Card.Header>
          <Card.Body>
            <WriteForm.Layout>
              <FormCkEditor
                data={guide && guide.value}
                onChange={data => {
                  setValue('guide', data, {
                    shouldDirty: true,
                  });
                }}
              />
            </WriteForm.Layout>
          </Card.Body>
        </Card.Layout>
      </ContentsContainer>
    </PageLayout>
  );
}
