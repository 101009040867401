import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Capacitor } from '@capacitor/core';
import { Media } from '@capacitor-community/media';

// components
import PageLayout from 'components/PageLayout';
import ToastMessage from 'components/Modals/ToastMessage';
import DefectWriteForm from './components/DefectWriteForm';
import InfoTable from './components/PhotoInfoTable';
import ConfirmModal from 'components/Modals/ConfirmModal';

// slices
import { openModal } from 'redux/common/slices/modals';
import { ChangeData } from 'redux/slices/selectionModal';
import { getPlaceDetails } from 'redux/root/slices/place';
// styles
import styled from '@emotion/styled';
import { theme } from 'shared/styles/theme';
import CommonStyledComponents from 'shared/styles/CommonStyledComponents';

// shared
import { WorkStatusData, DefectTypeData } from 'shared/root/defectData';
import checkMobileApp from 'shared/libraries/checkMobileApp';

import AlertModal from 'components/Modals/AlertModal';

import html2canvas from 'html2canvas';
import { urltoFile } from 'utils';
import loadImage from 'blueimp-load-image';

import moment from 'moment/moment';

import EXIF from 'exif-js';

import { Camera, CameraResultType } from '@capacitor/camera';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';

import { getOptionObj, getLocalStorage, setLocalStorage } from 'utils';
import { postDefect } from 'api/defect';
import { db } from 'db';
import { useNavigate } from 'react-router-dom';

import cameraIcon from 'assets/imgs/write-icon-camera.svg';

const { Area, Button, Baseline, ErrorMessage } = CommonStyledComponents;
const { defects } = db;

export default function DefectWrite() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const placeIdx = getLocalStorage('placeIdx');
  const isMobileApp = checkMobileApp();

  const { selectedValue } = useSelector(state => state.selectionModal);
  const userPlaceDetails = useSelector(state => state.place.placeDetails);

  const canvasSubmitRef = useRef();
  const infos = useRef();
  const infoFileRef = useRef();
  const originFileRef = useRef();

  const [canvasValue, setCanvasValue] = useState();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      place_idx: placeIdx, //현장idx
      sort1: getLocalStorage('prevInfo')?.sort1 || '', //동
      sort2: getLocalStorage('prevInfo')?.sort2 || '', //호수
      sort3: getLocalStorage('prevInfo')?.sort3 || '', //위치
      status: '', //작업상태
      type: getLocalStorage('prevInfo')?.type || '', //하자유형
      content: '', //내용
      work_method: '', //작업방법
      replacement_square_meter: 0, //교체면적_m
      replacement_sheet: 0, //교체면적_장
      shooting_day: '', //촬영일자
      dft_origin_img: null,
      dft_info_img: null,
      device_key: getLocalStorage('uuid') || '',
    },
  });

  const watchStatus = watch('status', false);
  const watchMethod = watch('work_method', false);

  // Dexie db에 저장하는 함수
  const addDexieData = async (data, submit_status) => {
    try {
      await defects.add({
        data,
        submit_status,
      });
    } catch (error) {
      console.log('dexie data add error', error);
    }
  };

  // 하자 날짜 형식 만들기 위한 함수
  function parseDate(s) {
    var b = s.split(/\D/);
    return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
  }

  // 사진 촬영, 갤러리 열기를 통해 원본사진 값 저장하기
  const [originBase64, setOriginBase64] = useState(null);
  const handleImageOrigin = async () => {
    try {
      // 카메라 또는 앨범 가져오기
      const image = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Base64,
        saveToGallery: true,
        promptLabelHeader: '사진등록',
        promptLabelPicture: '사진 촬영하기',
        promptLabelPhoto: '앨범에서 가져오기',
        promptLabelCancel: '취소',
      });

      // 원본사진의 base64를 저장
      const base64PhotoImage = `data:image/jpeg;base64,${image.base64String}`;
      setOriginBase64(base64PhotoImage);

      if (base64PhotoImage) {
        // base64값을 File형태로 변환하여 fileimg에 저장
        const fileimg = urltoFile(base64PhotoImage, 'temp.jpg');
        getImageData(fileimg);
      }
    } catch (error) {
      console.log('handleImageOrigin', error);
    }
  };

  // 저장될 사진이 생기면, 캔버스 태그를 가져온다.
  const [canvasElem, setCanvasElem] = useState(null);

  useEffect(() => {
    if (!originBase64) return;

    setCanvasElem(document.getElementById('canvas'));
  }, [originBase64]);

  // 캔버스DOM이 있으면, <img>객체를 만들고, img src에 원본 주소를 넣는다.
  const [imgObj, setImgObj] = useState(null);
  const [drawingContext, setDrawingContext] = useState();

  useEffect(() => {
    if (!canvasElem) return;

    const image = new Image();
    image.onload = () => {
      setImgObj(image);
    };
    image.src = originBase64;

    const getContextResult = canvasElem.getContext('2d', {
      willReadFrequently: true,
    });
    setDrawingContext(getContextResult);
  }, [canvasElem]);

  // 이미지객체가 생기면, 이미지의 가로세로를 측정해서, 가로세로중 긴쪽이 1200사이즈인 캔버스를 만든다.
  const [isVertical, setIsVertical] = useState();
  useEffect(() => {
    if (!imgObj) return;

    const isVertical = imgObj.width < imgObj.height; // 세로 이미지 여부 체크
    setIsVertical(isVertical);

    // 생성된 이미지객체를 기준으로 캔버스(canvasElem)의 사이즈를 정한다
    if (isVertical) {
      canvasElem.width = (imgObj.width * 1200) / imgObj.height;
      canvasElem.height = 1200;
    } else {
      canvasElem.width = 1200;
      canvasElem.height = (imgObj.height * 1200) / imgObj.width;
    }

    if (canvasElem.width === 0 || canvasElem.height === 0) {
      alert('이미지를 다시 등록해주세요');
    } else {
      // 캔버스위에 그린다.
      drawInfoOnPhoto();
    }
  }, [imgObj]);

  /**
   * 저장할 앨범 정보를 가져오는 함수
   * @returns {string} // 첫번째 앨범의 identifier
   */
  const ensureDemoAlbum = async () => {
    const { albums } = await Media.getAlbums();

    // 특정 앨범이 아닌 기본 갤러리에 저장
    if (Capacitor.getPlatform() === 'android') {
      return albums[0].identifier;
    }

    const albumName = 'sosohaja';
    const sosohajaAlbum = albums.find(album => album.name === albumName);

    if (sosohajaAlbum) {
      return sosohajaAlbum.identifier;
    }

    // sosohaja 앨범이 존재하지 않으면 새로 생성
    try {
      await Media.createAlbum({ name: albumName });
      const { albums } = await Media.getAlbums();
      const sosohajaAlbum = albums.find(album => album.name === albumName);

      return sosohajaAlbum.identifier;
    } catch (error) {
      console.error('앨범 생성 중 오류 발생:', error);
      throw error;
    }
  };

  /**
   * 사진을 갤러리에 저장하는 함수
   * @param {string} canvasValue // 캔버스에 그려진 이미지의 base64
   * @param {string} fileName // 저장될 파일명
   */
  const savePhotoDataURI = async (canvasValue, fileName) => {
    let opts = {
      path: canvasValue,
      albumIdentifier: await ensureDemoAlbum(),
      fileName,
    };

    await Media.savePhoto(opts);
  };

  // 캔버스위에 이미지를 그리고, 정보표시테이블을 그린다.
  const drawInfoOnPhoto = () => {
    const filetitle = `${watch('sort1')}_${watch('sort2')}_${watch(
      'sort3',
    )}_${getOptionObj(WorkStatusData, watch('status'), true)}.jpg`;

    // 정보표시테이블 DOM html을 canvas형태로 생성
    html2canvas(infos.current, {
      scale: 3,
      backgroundColor: 'rgba(0, 0, 0, 0)',
      removeContainer: true,
      allowTaint: true,
    }).then(function (canvasText) {
      const custom_width = 280; // 이 값으로 그려지는 테이블이 너비가 결정된다.

      // 이미지를 캔버스에 그린다
      drawingContext.drawImage(
        imgObj,
        0,
        0,
        canvasElem.width,
        canvasElem.height,
      );

      // 정보표시 테이블을 캔버스에 그린다.
      drawingContext.drawImage(
        canvasText,
        0,
        canvasElem.height - (custom_width - 1),
        (custom_width * infos.current.clientWidth) / infos.current.clientHeight,
        custom_width,
      );

      setCanvasValue(canvasElem.toDataURL('image/jpg', 1));

      const info_file = canvasValue && urltoFile(canvasValue, filetitle);

      setValue('dft_info_img', info_file);
    });

    const { device_key, dft_info_img, dft_origin_img, ...prevDatas } = watch();
    setLocalStorage('prevDefectData', prevDatas);
  };

  // 사진의 촬영일자를 가져오기위한 함수
  const getImageData = imgfile => {
    EXIF.getData(imgfile, function () {
      const file = this;

      var allMetaData = EXIF.getAllTags(file);

      if (allMetaData) {
        const date = EXIF.getTag(file, 'DateTime');

        if (date) {
          const shootingDay = parseDate(date).toString();
          setValue(
            'shooting_day',
            moment(shootingDay).format('YYYY.MM.DD HH:mm:ss'),
          );
        } else {
          setValue(
            'shooting_day',
            moment(new Date(imgfile.lastModified)).format(
              'YYYY.MM.DD HH:mm:ss',
            ),
          );
        }
      } else {
        handleDelete();
        dispatch(
          openModal({
            Component: ConfirmModal,
            props: {
              message: '정보를 확인할 수 없는 사진입니다.\n다시 촬영해주세요.',
              buttonText: {
                cancel: '확인',
              },
            },
          }),
        );
      }
    });
  };

  /**
   * 등록된 정보 초기화
   * - 삭제 버튼 클릭 시 실행
   * @returns {void}
   */
  const handleDelete = () => {
    setOriginBase64(null);
    setCanvasValue();
    setCanvasElem();
    setImgObj();
    setDrawingContext();
    setIsVertical();
    setValue('dft_info_img', null);
    setValue('dft_origin_img', null);
    setLocalStorage('prevDefectData', '');
  };

  /**
   * 입력한 정보 remote DB에 저장 및 indexedDB에 저장
   * @param {object} data
   * @param {string} data.place_idx // 현장 idx
   * @param {string} data.sort1 // 동
   * @param {string} data.sort2 // 호수
   * @param {string} data.sort3 // 위치
   * @param {string} data.status // 작업상태
   * @param {string} data.type // 하자유형
   * @param {string} data.content // 내용
   * @param {string} data.work_method // 작업방법
   * @param {string} data.replacement_square_meter // 교체면적(m)
   * @param {string} data.replacement_sheet // 교체면적(장)
   * @param {string} data.shooting_day // 사진촬영일 (YYYY.MM.DD HH:MM:ss)
   * @param {string} data.device_key // 단말기 고유 번호
   * @param {File} data.dft_origin_img // 원본사진
   * @param {string} data.dft_info_img // 정보표시된 사진
   */

  const onSubmit = async data => {
    const last_data = { ...data }; // 최종데이터

    // 파일명 짓기 (동_호수_위치_유형_작업상태)
    const t1 = data.sort1; //동
    const t2 = data.sort2; //호수
    const t3 = data.sort3; //위치
    const t4 = getOptionObj(DefectTypeData, data.type, true);
    const t5 = getOptionObj(WorkStatusData, data.status, true); //상태
    const title = `${t1}_${t2}_${t3}_${t4}_${t5}`.replace(' ', '');

    const create_date = moment().format(); // 파일 경로에 '.' 혹은 띄어쓰기 금지

    const { device_key, dft_info_img, dft_origin_img, ...prevDatas } = watch();

    if (
      Object.entries(prevDatas).toString() ===
      Object.entries(getLocalStorage('prevDefectData')).toString()
    ) {
      const current_time = moment().format('YYYYMMDD_HHmmss');

      const send_filename_origin = `${title}_원본.jpg`;
      const send_filename_info = `${title}.jpg`;

      // 카메라로 찍은 base64주소를 input file객체로 바꿔서 저장
      data.dft_origin_img = urltoFile(originBase64, send_filename_origin);
      data.dft_info_img = urltoFile(canvasValue, send_filename_info);

      // 사진 로컬저장
      const result = await Filesystem.writeFile({
        path: current_time, // <== 원하는 파일 경로로 변경,
        data: originBase64,
        directory: Directory.Documents,
        encoding: Encoding.UTF8,
        recursive: true,
      });

      last_data.photourl = result.uri; // 로컬기기에 사진저장 경로
      last_data.filename = title; // 리스트에서 제목으로 사용
      last_data.send_filename_origin = send_filename_origin;
      last_data.send_filename_info = send_filename_info;
      last_data.localfilename = current_time; //
      last_data.base64 = originBase64; // 리스트에서 썸네일 보여줄 때 사용
      last_data.base64info = canvasValue; // 정보표시된 이미지 가지고있기 (실패항목전송시필요)
      last_data.createdate = create_date; // 리스트에서 '업로드 순 정렬'에서 사용
      last_data.isVertical = isVertical;

      // 모바일 앱일 경우만 사진 저장
      if (isMobileApp && Media) {
        console.log('갤러리에 사진 저장');
        savePhotoDataURI(canvasValue, title);
      }

      addDexieData(last_data, 0); // 전송 대기리스트에 저장

      //계속작성 시 활용 될 정보
      setLocalStorage('prevInfo', {
        sort1: last_data.sort1,
        sort2: last_data.sort2,
        sort3: last_data.sort3,
        type: '',
      });
      handleDelete();
      navigate('/');
    } else {
      dispatch(
        openModal({
          Component: AlertModal,
          props: {
            message: '적용하기 버튼을 클릭하여,\n수정된 내용을 반영해주세요.',
          },
        }),
      );
    }
  };

  useEffect(() => {
    dispatch(getPlaceDetails(placeIdx));
  }, [dispatch, placeIdx]);

  useEffect(() => {
    if (selectedValue.value) {
      setValue(selectedValue.name, selectedValue.value);
      dispatch(
        ChangeData({
          selectedValue: {
            name: '',
            value: '',
          },
        }),
      );
    }

    clearErrors(selectedValue.name);
  }, [selectedValue]);

  useEffect(() => {
    if (watchStatus !== 'completed_work') {
      setValue('work_method', '');
      setValue('replacement_square_meter', '');
      setValue('replacement_sheet', '');
    }
  }, [watchStatus]);

  useEffect(() => {
    if (watchMethod === 'care') {
      setValue('replacement_square_meter', '');
      setValue('replacement_sheet', '');
    }
  }, [watchMethod]);

  return (
    <PageLayout title="작성하기" closeBtn showFloorBtn>
      <Area>
        <ConstructionSite>
          {userPlaceDetails.place && userPlaceDetails.place.name}
        </ConstructionSite>
      </Area>
      <form
        method="post"
        onSubmit={handleSubmit(onSubmit)}
        encType="multipart/form-data"
      >
        <DefectWriteForm
          getValues={getValues}
          setValue={setValue}
          watch={watch()}
          register={register}
          errors={errors}
        />

        <Baseline />

        <Area>
          <PhotoContainer>
            <PhotoText>사진</PhotoText>

            <>
              {errors.dft_origin_img &&
                errors.dft_origin_img.type === 'required' && (
                  <ErrorMessage>사진을 등록해주세요.</ErrorMessage>
                )}

              {/* 사진 첨부 전 */}
              {originBase64 === null && (
                <>
                  <PhotoAppCamera type="button" onClick={handleImageOrigin}>
                    <img src={cameraIcon} alt="카메라 또는 앨범열기" />
                    <div>카메라</div>
                  </PhotoAppCamera>
                  <PhotoLabel>
                    <input
                      type="file"
                      capture="camera"
                      ref={infoFileRef}
                      {...register('dft_info_img', {
                        required: true,
                      })}
                      accept="image/*"
                    />
                    <input
                      type="file"
                      capture="camera"
                      ref={originFileRef}
                      {...register('dft_origin_img', {
                        required: true,
                      })}
                      accept="image/*"
                    />
                  </PhotoLabel>
                </>
              )}

              {/* 사진 첨부 후 */}
              {originBase64 !== null && (
                <ThumbContainer>
                  <PhotoDelete onClick={handleDelete}>삭제</PhotoDelete>
                  <canvas id="canvas" ref={canvasSubmitRef}></canvas>
                  <InfoTable
                    ref={infos}
                    placeName={
                      userPlaceDetails.place && userPlaceDetails.place.name
                    }
                    watchData={watch()}
                  />
                </ThumbContainer>
              )}
            </>

            <SubmitButton>
              <Button
                type="button"
                size={'full-xl'}
                theme={'point3-blue'}
                onClick={drawInfoOnPhoto}
                status={!(originBase64 && canvasElem) && 'disabled'}
              >
                적용하기
              </Button>
              <Button
                type="submit"
                size={'full-xl'}
                theme={'point'}
                // status={'disabled'}
              >
                저장
              </Button>
            </SubmitButton>
          </PhotoContainer>
        </Area>
      </form>
    </PageLayout>
  );
}

const ConstructionSite = styled.h1`
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.25;
`;
const PhotoContainer = styled.div`
  padding-top: 20px;
  padding-bottom: ${theme.notch.bottom};
`;
const PhotoText = styled.p`
  margin-bottom: 8px;
  font-size: 15px;
  color: #444;
`;
const SubmitButton = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr;
  margin-top: 20px;
  width: 100%;
  height: 68px;
  background-color: #fff;
  column-gap: 9px;
`;
const ThumbContainer = styled.div`
  position: relative;
  min-height: 220px;
  img {
    display: block;
  }
  table {
    z-index: -1;
  }
`;
const PhotoLabel = styled.div`
  display: none;
  position: relative;
  font-size: 13px;
  overflow: hidden;
  input[type='file'] {
    /* opacity: 0; */
  }
`;
const PhotoAppCamera = styled.button`
  position: relative;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23ABABABFF' stroke-width='1' stroke-dasharray='1%25%2c 1%25' stroke-dashoffset='16' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 5px;
  padding: 60px 70px;
  width: 100%;
  font-size: 13px;
  color: #94999a;
  text-align: center;
  overflow: hidden;
  input[type='file'] {
    position: absolute;
    border: 1px solid #ace;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
`;
const PhotoDelete = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 30px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  cursor: pointer;
`;
const ButtonOnInfo = styled.div`
  flex: 1;
`;
const ButtonSubmit = styled.div`
  flex: 2;
`;
