import Modal from 'react-modal';

import GuideImg from 'assets/imgs/img_popup_guide@3x.png';

import styled from '@emotion/styled';
import CommonStyledComponents from 'shared/styles/CommonStyledComponents';
import { theme } from 'shared/styles/theme';
const { Button } = CommonStyledComponents;

export default function GuideModal({ modalClose, modalSubmit }) {
  return (
    <Modal
      isOpen
      style={customStyles}
      onRequestClose={modalClose}
      shouldCloseOnOverlayClick={false}
    >
      <StyledGuideModal>
        <img src={GuideImg} alt="간편한 하자점검 방법" />
        <Buttons>
          <Button
            type="submit"
            size={'full-lg'}
            theme={'point3-blue'}
            style={{
              borderRadius: '0px',
            }}
            onClick={modalClose}
          >
            닫기
          </Button>
          <Button
            type="submit"
            size={'full-lg'}
            theme={'point'}
            style={{
              borderRadius: '0px',
            }}
            onClick={modalSubmit}
          >
            자세히보기
          </Button>
        </Buttons>
      </StyledGuideModal>
    </Modal>
  );
}

const customStyles = {
  overlay: {
    position: 'fixed',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    zIndex: '10000',
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: 'calc(100% - 30px)',
    maxWidth: '290px',
    padding: '0',
    borderRadius: '4px',
    transform: 'translate(-50%, -50%)',
    border: 'none',
  },
};

const StyledGuideModal = styled.div`
  background: #2f4270;
  img {
    display: block;
  }
  h2 {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 24px;
    text-align: center;
  }
  dl {
    display: flex;
    font-size: 14px;
    line-height: 1.57;
    & + dl {
      margin-top: 10px;
    }
    dt {
      flex: none;
      font-weight: 500;
      margin-right: 6px;
      span {
        color: ${theme.colors.sub};
        margin-left: 2px;
      }
    }
  }
  p {
    font-size: 12px;
    line-height: 1.5;
    margin-top: 20px;
    padding-top: 25px;
    border-top: 1px solid ${theme.colors.divider};
    color: ${theme.colors.sub};
  }
`;

const Buttons = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;
