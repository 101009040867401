// select box - option 객체 생성
export function setOptions(array) {
  if (array) {
    const newarray = array.map((item, idx) => {
      return { value: item.idx, label: item.name };
    });
    return newarray;
  }
}

// select box - value값으로 option객체 또는 lable 가져오기
export function getOptionObj(array, val, label) {
  if (array.length !== 0) {
    const idx = array.findIndex(obj => {
      return obj.value === val;
    });

    if (val && label) {
      if (!array[idx].label) {
        return '';
      } else {
        return array[idx].label;
      }
    } else {
      return array[idx];
    }
  }
}

/*=================================================
      form
=================================================*/
/* @ useForm, 값이 변경된 항목(dirtyFields)만 배열로 분리
  - submitData: submit data
  - dirtyFields: 값의 변경이 있었는지를 나타내는 상태 값(formState dirtyFields 사용 필수)
*/
export const dirtyValues = (dirtyFields, allValues) => {
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }
  return Object.fromEntries(
    Object.keys(dirtyFields).map(key => [
      key,
      dirtyValues(dirtyFields[key], allValues[key]),
    ]),
  );
};

/* @ 오브젝트 재 정렬
 */
export function reorderObject(obj) {
  // 새로운 객체 생성
  console.log('reorderObject', obj);
  const newObj = {};

  // obj의 key를 하나씩 순회하며 새로운 객체에 삽입
  for (let key in obj) {
    if (obj[key] !== null && obj[key] !== undefined) {
      if (
        typeof obj[key] === 'object' &&
        'value' in obj[key] &&
        'label' in obj[key]
      ) {
        // value값이 객체인 경우, value값만 새로운 객체에 추가
        newObj[key] = obj[key]['value'];
      } else if (
        typeof obj[key] === 'object' &&
        Object.keys(obj[key]).length > 0
      ) {
        // value값이 객체이면서 비어있지 않은 경우, 재귀적으로 해당 객체 내부의 value값 재정렬 후 새로운 객체에 추가
        newObj[key] = reorderObject(obj[key]);
      } else if (typeof obj[key] !== 'object') {
        // value값이 객체가 아닌 경우, 해당 값을 그대로 새로운 객체에 추가
        newObj[key] = obj[key];
      }
    }
  }

  return newObj;
}

/*=================================================
      board
=================================================*/
// setListIndex - 항목 index 셋팅
export function setListIndex(
  idx,
  activePage = 1,
  sortingNum = 0,
  totalNum = 0,
) {
  const offset = (activePage - 1) * sortingNum;
  const indexNum = totalNum ? totalNum - offset - idx : 0;
  return Number(indexNum) > 0 ? indexNum : ''; //음수로 출력되는것을 방지
}

/*=================================================
      localstorage
=================================================*/
// localstorage 추가
export function setLocalStorage(key, value) {
  if (value) {
    localStorage.setItem(key, JSON.stringify(value));
  }
}

// localstorage 불러옴
export function getLocalStorage(key) {
  if (key) {
    return JSON.parse(localStorage.getItem(key));
  }
}

// localstorage 삭제
export function removeLocalStorage(key) {
  if (key) {
    localStorage.removeItem(key);
  }
}

/*=================================================
      modal
=================================================*/
/* @ windowModalOpen
  - submitData: submit data
  - dirtyFields: 값의 변경이 있었는지를 나타내는 상태 값(formState dirtyFields 사용 필수)
*/
export function windowModalOpen(url, name, width = '400px', height = '300px') {
  window.open(
    url,
    name,
    `width=${width}, height=${height}, location=no, status=no, scrollbars=yes`,
  );
}

/*=================================================
      base64 to File Object
=================================================*/
export const urltoFile = (dataurl, fileName) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
};

/*=================================================
      파일명 (동_호수_위치_상태 조합) 생성
=================================================*/
// 하자 작업상태
export const WorkStatusData = [
  { value: 'before_work', label: '작업전' },
  { value: 'working', label: '작업중' },
  { value: 'completed_work', label: '작업완료' },
];

export const makeFilename = (sort1, sort2, sort3, status) => {
  const t1 = sort1;
  const t2 = sort2;
  const t3 = sort3;
  const t4 = getOptionObj(WorkStatusData, status, true);
  const title = `${t1}_${t2}_${t3}_${t4}`.replace(' ', '');

  return title;
};

/*=================================================
      etc
=================================================*/
// 외부 브라우저로 새 창 열기
export function windowOpen(link) {
  if (link) {
    window.open(link, '_blank', 'noopener,noreferrer');
  }
}

/*=================================================
      하자사진 날짜 형식 변경위한 함수
=================================================*/
export function parseDate(s) {
  var b = s.split(/\D/);
  return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
}
