import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_BOARD_ADMIN } from 'shared/constants/constants';

// 관리자 게시글 리스트 API
export const getBoardLists = createAsyncThunk(
  'get/boardList',
  async (data, thunkAPI) => {
    const { bdIdx, take, page } = data;

    try {
      const response = await axios.get(
        `${API_BOARD_ADMIN}/${bdIdx}?category=0&take=${take}&page=${page}`);

      return response.data;
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue();
    }
  },
);

// 관리자 게시글 상세 API
export const getBoardDetails = createAsyncThunk(
  'get/BoardDetails',
  async (bcData, thunkAPI) => {
    const { bdIdx, bcIdx } = bcData;
    if (bdIdx !== null) {
      const response = await axios.get(`${API_BOARD_ADMIN}/${bdIdx}/${bcIdx}`);
      return response.data;
    }
  },
);

// 관리자 게시글 수정 API
export const patchBoardDetails = createAsyncThunk(
  'patch/BoardDetails',
  async (bcData, thunkAPI) => {
    const { bdIdx, bcIdx } = bcData;
    if (bdIdx !== null) {
      const response = await axios.get(`${API_BOARD_ADMIN}/${bdIdx}/${bcIdx}`);
      return response.data;
    }
  },
);

const rootBoardSlice = createSlice({
  name: 'board',
  initialState: {
    boardLists: [],
    boardDetails: [],
  },
  reducers: {
    //
  },
  extraReducers: {
    [getBoardLists.fulfilled]: (state, action) => {
      state.boardLists = action.payload;
    },
    [getBoardDetails.fulfilled]: (state, action) => {
      state.boardDetails = action.payload;
    },
  },
});

export default rootBoardSlice.reducer;
