const fonts = {
  pretendard: `'Pretendard', sans-serif`,
  dotum: '"Dotum", sans-serif',
};

const colors = {
  font: '#000',
  point: '#0089cf',
  point2: '#00b3f0',
  point3: '#d1eaf7',
  sub: '#94999a',
  bg1: '#0d1035',
  bg2: '#f6f8f9',
  divider: '#dee0e5',
  valid: '#e53935',
  placeholder: '#a7abc3',
};

const size = {
  sm: '544px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
};

const mq = {
  sm: `@media (min-width: ${size.sm})`,
  md: `@media (min-width: ${size.md})`,
  lg: `@media (min-width: ${size.lg})`,
  xl: `@media (min-width: ${size.xl})`,
};

const gutter = {
  mobile: '16px',
  pc: '60px',
};

const notch = {
  top: `env(safe-area-inset-top)`,
  bottom: `env(safe-area-inset-bottom)`,
  // top: `100px`,
  // bottom: `100px`,
};

export const theme = {
  fonts,
  colors,
  mq,
  gutter,
  notch,
};
