import axios from 'axios';
import { dirtyValues } from 'utils';

import { API_PLACE, TOKEN } from 'shared/constants/constants';

/*
|--------------------------------------------------------------------------
| 현장 API
|--------------------------------------------------------------------------
*/
// 관리자_현장등록 API
export async function setPlace(data) {
  const response = await axios.post(`${API_PLACE}`, data);

  return response.data;
}
// 관리자_현장상태 일괄 변경 API
export async function updatePlaceStatus(data) {
  const newData = {
    status: data.status,
    idxs: data.checkedLists,
  };
  const response = await axios.patch(`${API_PLACE}`, newData);

  return response.data;
}
// 현장삭제 API
export async function deletePlace(idxs) {
  const response = await axios.delete(`${API_PLACE}`, {
    data: { idxs },
  });

  return response.data;
}
// 관리자_현장수정 API
export async function updatePlace(data, idx, dirtyFields) {
  const dirtyFieldsData = dirtyValues(dirtyFields, data);
  const newData = {
    status: data.status,
    ...dirtyFieldsData,
  };
  const response = await axios.patch(`${API_PLACE}/${idx}`, newData);

  return response.newData;
}
