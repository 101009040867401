import { useState, useEffect } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

export default function Spinners({ loading, color }) {
  const [spinnersShow, setSpinnersShow] = useState(false);
  const delay = 100;

  //0.1초 딜레이 (깜빡이는 형상 방지)
  useEffect(() => {
    setTimeout(() => {
      setSpinnersShow(true);
    }, delay);
  }, [loading]);

  return (
    spinnersShow && (
      <BeatLoader
        cssOverride={override}
        size={10}
        color={color ? color : '#0089cf'}
        loading={loading}
        speedMultiplier={0.8}
      />
    )
  );
}

const override = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '85px',
};
