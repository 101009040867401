import { useState, Suspense, lazy } from 'react';

import { Link } from 'react-router-dom';

import PageLayout from 'components/PageLayout';
import { getPlaceDetails } from 'redux/root/slices/place';

import logoIcon from 'assets/imgs/logo_main.svg';
import IconSetting from 'assets/imgs/icon_settings.svg';
import GuideIcon from 'assets/imgs/appbar-icon-question.svg';
import TooltipCloseIcon from 'assets/imgs/tooltip_close_icon.svg';

import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { css } from '@emotion/react';

import { setLocalStorage } from 'utils';
import { postDefect } from 'api/defect';

import { CustomSelect, styleDefault } from 'shared/styles/common/Select';
import { UserDefectSortOptions } from 'shared/root/optionData';

import { theme } from 'shared/styles/theme';

import CommonStyledComponents from 'shared/styles/CommonStyledComponents';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import DefectNoList from './components/DefectNoList';
import DefectListItem from './components/DefectListItem';
import { openModal } from 'redux/common/slices/modals';
import ConfirmModal from 'components/Modals/ConfirmModal';
import AlertModal from 'components/Modals/AlertModal';
import FloorButton from 'components/FloorButton';
import { GuideToolTipConfirm } from 'redux/slices/permission';

import ToastMessage from 'components/Modals/ToastMessage';
import { urltoFile } from 'utils';

import { Filesystem, Directory } from '@capacitor/filesystem';

import { db } from 'db';
import { useLiveQuery } from 'dexie-react-hooks';

import moment from 'moment/moment';

const { Area, Button } = CommonStyledComponents;
const { defects } = db;

export default function DefectList() {
  const dispatch = useDispatch();

  const { guideToolTipVisible } = useSelector(state => state.permission);

  const { user } = useSelector(state => state.user);
  const userPlaceDetails = useSelector(state => state.place.placeDetails.place);

  // signup_type : 0 (관리자부여) | 1 (일반회원가입)
  const isApproved = user?.signup_type === 0;

  const [tooltip, setTooltip] = useState(false);
  const [lists, setLists] = useState();

  // dexie - 전체 하자 리스트를 가져온다.
  const all_dx_dfts = useLiveQuery(() => defects.toArray(), []);

  // 현재 현장의 하자 리스트 만들기 (재가공)
  const place_dx_list =
    user &&
    all_dx_dfts &&
    all_dx_dfts.filter(val => val.data.place_idx === user.place_idx);

  // 하자등록일순 정렬로 리스트 재가공
  const place_create_order =
    place_dx_list &&
    place_dx_list.sort(function compare(a, b) {
      const x = moment(a.data.createdate, 'YYYYMMDDHHmmss').toDate();
      const y = moment(b.data.createdate, 'YYYYMMDDHHmmss').toDate();

      return y - x;
    });

  // 전송완료 리스트
  const submitCompleteLists = useLiveQuery(
    () => defects.where('submit_status').equals(1).toArray(),
    [],
  );
  //
  const completeIdxs = submitCompleteLists?.map(val => {
    return val.id;
  });

  // 전송대가 리스트
  const submitFailLists = useLiveQuery(
    () => defects.where('submit_status').equals(0).toArray(),
    [],
  );
  // 전송완료 건수
  const dft_success_num =
    place_dx_list &&
    place_dx_list.filter(val => val.submit_status === 1).length;

  // 전송대기 건수
  const dft_fail_num =
    place_dx_list &&
    place_dx_list.filter(val => val.submit_status === 0).length;

  useEffect(() => {
    setLists(place_create_order); // 최초에는 등록일순으로 가공한 하자리스트를 셋팅한다
  }, [dispatch, user, all_dx_dfts]);

  useEffect(() => {
    if (user) {
      setLocalStorage('placeIdx', user.place_idx);
      dispatch(getPlaceDetails(user.place_idx));
      // defects.update(278, { submit_status: 0 }); // 전송대기 테스트위한 코드
    }
  }, [dispatch, user]);

  // 앱 - file stystem에서 사진 삭제
  const deleteImageFile = async url => {
    try {
      await Filesystem.deleteFile({
        path: url,
        directory: Directory.Documents,
      });
    } catch (error) {
      console.log('deleteImageFile', error);
    }
  };

  // 하자 개별적으로 '삭제'버튼 클릭시 함수
  const deleteDefectItem = async (id, submit_status, uri) => {
    if (submit_status) {
      // 전송완료 항목인 경우
      defects.delete(id); // Dexie db에서 지우기
      deleteImageFile(uri); // 앱 로컬에서 지우기
    } else {
      // 전송실패 항목인 경우
      dispatch(
        openModal({
          Component: ConfirmModal,
          props: {
            message: '전송되지 않은 내용입니다.\n정말로 삭제하시겠습니까?',
            buttonText: {
              cancel: '취소',
              submit: '삭제',
            },
            handleModalSubmit: () => {
              defects.delete(id);
            },
          },
        }),
      );
    }
  };

  // 전송완료항목 전체삭제 기능
  const deleteSuccessList = async () => {
    const newarr = submitCompleteLists.map((item, idx) => {
      return item.data.localfilename;
    });

    newarr &&
      newarr.forEach(function (element) {
        deleteImageFile(element);
      });

    defects.bulkDelete(completeIdxs); // dexie db에서 한번에 여러개 지우기
  };

  // 대기항목들 전체재전송 기능
  //로딩스피너
  const { Spinners } = CommonStyledComponents;
  const [submitLoading, setSubmitLoading] = useState(false);
  const submitStandbyList = async () => {
    setSubmitLoading(true);
    submitFailLists.forEach(function (elem) {
      const {
        photourl,
        filename,
        localfilename,
        send_filename_origin,
        send_filename_info,
        base64,
        base64info,
        isVertical,
        ...redatas
      } = elem.data;

      redatas.dft_origin_img = urltoFile(base64, send_filename_origin);
      redatas.dft_info_img = urltoFile(base64info, send_filename_info);

      postDefect(redatas)
        .then(res => {
          defects.update(elem.id, { submit_status: 1 });
          dispatch(
            openModal({
              Component: ToastMessage,
              props: {
                iconImg: 'complete',
                message: '재전송이 완료되었습니다.',
              },
            }),
          );
          setSubmitLoading(false);
        })
        .catch(error => {
          dispatch(
            openModal({
              Component: ToastMessage,
              props: {
                iconImg: 'fail',
                message: '재전송에 실패했습니다.',
              },
            }),
          );
          setSubmitLoading(false);
        });
    });
  };

  const onClickStandbySubmit = () => {
    dispatch(
      openModal({
        Component: ConfirmModal,
        props: {
          message:
            '대기 중인 하자 항목을 일괄 전송합니다.\n계속 진행하시겠습니까?',
          buttonText: {
            cancel: '취소',
            submit: '확인',
          },
          handleModalSubmit: () => {
            submitStandbyList();
          },
        },
      }),
    );
  };

  // 리스트 sorting 정렬
  function changeSorting(e) {
    if (e.value === 'filename') {
      // 파일명순 정렬
      const place_filename_order =
        place_dx_list &&
        place_dx_list.sort(function (a, b) {
          return a.data.filename < b.data.filename
            ? -1
            : a.data.filename > b.data.filename
            ? 1
            : 0;
        });
      setLists(place_filename_order);
    } else if (e.value === 'create_day') {
      // 등록일순 정렬
      setLists(place_create_order);
    }
  }

  // 하자목록이 100개 이상인 경우, Alert모달로 경고 처리
  // (클라이언트 요구사항은 아니었지만, 로컬에 너무 과용량 저장이 부하클것으로 예상하여 임의로 처리했음)
  const handleOverList = () => {
    dispatch(
      openModal({
        Component: AlertModal,
        props: {
          message:
            '등록된 하자 정보가 많습니다.\n 완료 항목을 삭제 후, 계속 작성해주세요.',
        },
      }),
    );
  };

  // 리스트가 있을때, 툴팁 보여주기
  useEffect(() => {
    if (lists?.length === 0) {
      setTooltip(true);
    }
  }, [lists]);

  // 툴팁 닫기
  const closeTooltip = () => {
    setTooltip(false);
    dispatch(GuideToolTipConfirm());
  };

  return (
    <>
      <PageLayout>
        <SiteContainer>
          <SiteHeader>
            <img src={logoIcon} alt="" />
            <RightItem>
              {
                //guideModal 닫은 후 노출되도록 함
                guideToolTipVisible !== false && (
                  <ToolTip status={tooltip}>
                    소소하자 사용 설명서
                    <CloseIcon onClick={closeTooltip}></CloseIcon>
                  </ToolTip>
                )
              }
              <GuideItem to="/guide">
                <img src={GuideIcon} alt="" />
              </GuideItem>
              <LinkSettings to="/settings"></LinkSettings>
            </RightItem>
          </SiteHeader>
          <SiteContents>
            <Area>
              {isApproved ? (
                <SiteTitle>
                  {userPlaceDetails && userPlaceDetails.name}
                </SiteTitle>
              ) : (
                <SiteTitle>나만의 간편 하자 점검</SiteTitle>
              )}
              <SiteDesc>
                전송 완료 {dft_success_num}건 / 대기 {dft_fail_num}건
              </SiteDesc>
              {isApproved && (
                <FloorButtonWrap>
                  <FloorButton />
                </FloorButtonWrap>
              )}
            </Area>
          </SiteContents>
          <SortingContainer>
            {/* 촬영일최신순, 파일명순 정렬 */}
            {lists && lists.length !== 0 ? (
              <CustomSelect
                width="120px"
                onChange={changeSorting}
                styles={styleDefault}
                isSearchable={false}
                options={UserDefectSortOptions}
                defaultValue={UserDefectSortOptions[0]}
              />
            ) : null}
          </SortingContainer>
        </SiteContainer>

        <Area>
          <ListContainer>
            {lists ? (
              lists.length === 0 ? (
                <DefectNoList />
              ) : (
                <DefectListItem lists={lists} handleDelete={deleteDefectItem} />
              )
            ) : null}
          </ListContainer>
        </Area>

        {lists && lists.length !== 0 ? (
          <ButtonContainer>
            <Area>
              <ButtonItems>
                <div>
                  <Button
                    size={'full-md'}
                    theme={'point'}
                    onClick={onClickStandbySubmit}
                    status={!submitLoading && dft_fail_num ? '' : 'disabled'}
                  >
                    전체항목전송
                  </Button>
                </div>
                <div>
                  <Button
                    size={'full-md'}
                    theme={'point'}
                    onClick={deleteSuccessList}
                    status={dft_success_num ? '' : 'disabled'}
                  >
                    완료 항목 삭제
                  </Button>
                </div>
                <div>
                  {lists.length >= 100 ? (
                    <Button size={'full-md'} theme={'point'}>
                      <p onClick={handleOverList}>계속 작성</p>
                    </Button>
                  ) : (
                    <Link to="/defectwrite">
                      <Button size={'full-md'} theme={'point'}>
                        계속 작성
                      </Button>
                    </Link>
                  )}
                </div>
              </ButtonItems>
            </Area>
          </ButtonContainer>
        ) : null}
      </PageLayout>
      {submitLoading && (
        <SpinnerContainer>
          <Spinners color={'#fff'} loading></Spinners>
          <h2>전송 중입니다</h2>
          <p>앱을 종료하거나 이동하지 마세요</p>
        </SpinnerContainer>
      )}
    </>
  );
}

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;
const SiteContainer = styled.div`
  position: sticky;
  top: 0;
  padding-top: ${theme.notch.top};
  background-color: #f6f8f9;
`;
const SiteHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px 0 16px;
  width: 100%;
  height: 44px;
`;
const SiteContents = styled.div`
  padding: 12px 0 30px;
`;
const RightItem = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;
const ToolTip = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 6px;
  padding: 4px 4px 4px 8px;
  border-radius: 4px;
  font-size: 13px;
  background-color: #0d1035;
  color: #fff;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.15);
  /* animation: ${fadeOut} 0.3s 2s forwards; */
  ${props =>
    props.status === false &&
    css`
      opacity: 0 !important;
      visibility: hidden !important;
    `}
  &:after {
    content: '';
    position: absolute;
    bottom: 50%;
    right: -10px;
    transform: translatey(50%);
    border: 5px solid;
    border-color: transparent transparent transparent #000;
  }
`;
const CloseIcon = styled.div`
  margin-left: 3px;
  width: 20px;
  height: 20px;
  background: url(${TooltipCloseIcon}) no-repeat center/contain;
`;
const GuideItem = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 100%;
`;
const LinkSettings = styled(Link)`
  display: block;
  width: 44px;
  height: 100%;
  background: url(${IconSetting}) no-repeat center / contain;
`;
const SiteTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
`;
const SiteDesc = styled.p`
  margin-top: 5px;
  font-size: 14px;
`;
const ListContainer = styled.div`
  padding-bottom: calc(${theme.notch.bottom} + 88px);
`;
const SortingContainer = styled.div`
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 ${theme.gutter.mobile};
  margin-bottom: 5px;
  height: 44px;
  background-color: #fff;
  [class*='-ValueContainer'] {
    justify-content: flex-end;
  }
`;

// 하단 버튼
const ButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  padding-bottom: ${theme.notch.bottom};
  left: 50%;
  width: 100%;
  max-width: 414px;
  height: calc(68px + ${theme.notch.bottom});
  display: flex;
  align-items: center;
  background: #fff;
  transform: translateX(-50%);
  overflow: hidden;
`;
const ButtonItems = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  margin: -4px;
  /* gap: 9px; */
  & > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding: 4px;
  }
`;

//로딩스피너 컨테이너
const SpinnerContainer = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.8);
  h2 {
    font-size: 16px;
    font-weight: 500;
    color: #f5f5f5;
  }
  p {
    margin-top: 10px;
    font-size: 14px;
    color: #b1b1b1;
  }
`;

const FloorButtonWrap = styled.div`
  margin-top: 20px;
`;
