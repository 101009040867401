import styled from '@emotion/styled';
import { theme } from 'shared/root/styles/theme';

import HeaderContainer from './HeaderContainer';
export default function PageLayout({ children }) {
  return (
    <div>
      <HeaderContainer />

      <PageContainer>{children}</PageContainer>
    </div>
  );
}

const PageContainer = styled.div`
  position: relative;
  padding-right: 0;
  padding-left: 0;
  background-color: #f6f7f9;
  ${theme.mq.xxl} {
    width: 100%;
    padding-left: 272px;
    /* transition: padding 0.4s; */
  }
`;
