import { Capacitor } from '@capacitor/core';

/**
 * TODO: 유틸함수로 이동 예정
 * 모바일앱 판별 (캐피시터 getPlatform 메서드로 판단함)
 * getPlatform(): 'web' | 'ios' | 'android'
 * @returns {boolean}
 */
export default function checkMobileApp() {
  const isMobileApp = Capacitor?.getPlatform() !== 'web';

  return isMobileApp;
}
