import { Editor } from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import styled from '@emotion/styled';
import axios from 'axios';

import { API_URL } from 'shared/constants/constants';

export default function FormCkEditor({
  data,
  onReady,
  onChange,
  onBlur,
  onFocus,
}) {
  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      return {
        upload() {
          return new Promise((resolve, reject) => {
            const data = new FormData();
            loader.file.then(file => {
              data.append('ckeditor', file);

              axios
                .post(`${API_URL}/file/ckeditor/upload`, data)
                .then(res => {
                  resolve({
                    default: `${API_URL}/file/img/${res.data.file_raw_name}`,
                  });
                })
                .catch(err => reject(err));
            });
          });
        },
      };
    };
  }

  return (
    <StyledFormCkEditor>
      <CKEditor
        editor={Editor}
        config={{
          extraPlugins: [uploadPlugin],
        }}
        data={data}
        onReady={editor => {
          // console.log('에디터가 사용할 준비가 되었습니다!', editor);
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          // console.log({ event, editor, data });
          onChange(data);
        }}
        onBlur={(event, editor) => {
          // console.log('onBlur.', editor);
        }}
        onFocus={(event, editor) => {
          // console.log('onFocus.', editor);
        }}
      />
    </StyledFormCkEditor>
  );
}

const StyledFormCkEditor = styled.div`
  position: relative;
  z-index: 0;
  .ck-content {
    height: 500px;
  }
`;
