import axios from 'axios';

import { API_SETTINGS, TOKEN } from 'shared/constants/constants';

// 관리자_환경설정 등록, 수정
export async function postSettings(data) {
  const sendData = {
    settings: [],
  };

  for (let key in data) {
    const obj = {};

    obj[key] = data[key];
    sendData.settings.push(obj);
  }

  const response = await axios.post(`${API_SETTINGS}`, sendData);

  return response.data;
}
