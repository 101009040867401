import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, useNavigate } from 'react-router-dom';

import { ThemeProvider } from '@emotion/react';
import GlobalStyle from 'shared/root/styles/GlobalStyle';
import { theme } from 'shared/root/styles/theme';

import { getAdminProfile } from 'api/user';
import { SET_TOKEN, DELETE_TOKEN } from 'redux/root/slices/token';
import { SET_ADMIN } from 'redux/root/slices/user';

// components
import Login from 'root/pages/Login';
import Dashboard from 'root/pages/Dashboard';
import MemberList from 'root/pages/Member/MemberList';
import AdminMemberList from 'root/pages/Member/AdminMemberList';
import MemberUpdate from 'root/pages/Member/MemberUpdate';
import MemberWrite from 'root/pages/Member/MemberWrite';
import PlaceList from 'root/pages/Place/PlaceList';
import PlaceWrite from 'root/pages/Place/PlaceWrite';
import DefectsByPlaceList from 'root/pages/DefectsByPlace/DefectsByPlaceList';
import DefectsList from 'root/pages/DefectsByPlace/DefectsList';
import DefectsDetails from 'root/pages/DefectsByPlace/DefectsDetails';
import BoardList from 'root/pages/Board/BoardList';
import BoardWrite from 'root/pages/Board/BoardWrite';
import Info from 'root/pages/Settings/Info';
import Terms from 'root/pages/Settings/Terms';
import GuidePage from 'root/pages/Settings/GuidePage';
import NotFound from 'root/pages/NotFound';

import ProtectedRoutes from 'components/Route/Admin/ProtectedRoutes';
import PublicRoutes from 'components/Route/Admin/PublicRoutes';
import ModalsProvider from 'components/Modals/ModalsProvider';

export default function Admin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = localStorage.admin_access_token || false;
  const { authenticated } = useSelector(state => state.rootToken);

  useEffect(() => {
    //로그인 인증되지는 않은상태
    if (authenticated === false) {
      //기존 토큰이 존재하는 경우
      if (token) {
        dispatch(SET_TOKEN(token));
        //사용자 프로필정보 불러오기
        getAdminProfile()
          .then(response => {
            dispatch(SET_ADMIN(response));
          })
          .catch(e => {
            console.log(2);
            // 프로필 정보를 불러올 수 없는 경우
            dispatch(DELETE_TOKEN()); //로그아웃처리
          });
      } else {
        // 기존 토큰이 존재하지 않는 경우
        dispatch(DELETE_TOKEN()); //로그아웃처리
      }
    } else {
      if (!token) {
        // 로그인 인증되어 있지만 토큰이 존재하지 않는 경우
        dispatch(DELETE_TOKEN()); //로그아웃처리
      }
    }
  }, [token]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Routes>
        <Route path="/" element={<ProtectedRoutes token={token} />}>
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/member">
            <Route path="basic">
              <Route path="lists" element={<MemberList />} />
              <Route path="details/:memId" element={<MemberUpdate />} />
              <Route path="write" element={<MemberWrite />} />
            </Route>
            <Route path="admin">
              <Route path="lists" element={<AdminMemberList />} />
              <Route
                path="details/:memType/:memId"
                element={<MemberUpdate />}
              />
            </Route>
            <Route path="write" element={<MemberWrite />} />
          </Route>
          <Route path="/place">
            <Route path="lists" element={<PlaceList />} />
            <Route path="details/:idx" element={<PlaceWrite />} />
            <Route path="write" element={<PlaceWrite />} />
          </Route>
          <Route path="/defectsbyplace">
            <Route index path="lists" element={<DefectsByPlaceList />} />
            <Route path=":placeId/lists" element={<DefectsList />} />
            <Route
              path=":placeId/details/:defectId"
              element={<DefectsDetails />}
            />
          </Route>
          <Route path="/board">
            <Route path="lists/:bdIdx" element={<BoardList />} />
            <Route path="details/:bdIdx/:bcIdx" element={<BoardWrite />} />
            <Route path="write/:bdIdx" element={<BoardWrite />} />
          </Route>
          <Route path="/settings">
            <Route path="info" element={<Info />} />
            <Route path="terms" element={<Terms />} />
            <Route path="guide" element={<GuidePage />} />
          </Route>
        </Route>

        <Route path="login" element={<PublicRoutes token={token} />}>
          <Route path="/login" element={<Login />} />
        </Route>

        <Route path="/notfound" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Routes>

      <ModalsProvider />
    </ThemeProvider>
  );
}
